import { ApiMethods } from './api-methods';

export class MiddlewareApiMethods {
	private static controller: string = 'middleware';

	private static wrapped(action: string): string {
		return ApiMethods.prefixed(this.controller, action);
	}

	public static validateAddress(): string {
		return this.wrapped('address/validate');
	}

	public static checkServiceAvailability(): string {
		return this.wrapped('enrollment/checkAvailability');
	}

	public static checkEligibility(): string {
		return this.wrapped('nlad/checkEligibility');
	}

	public static eligibilityStatusCheck(): string {
		return this.wrapped('nlad/eligibilityStatusCheck');
	}

	public static nladVerify(): string {
		return this.wrapped('nlad/verify');
	}

	public static transferAcp(): string {
		return this.wrapped('nlad/transfer-acp');
	}

	public static getPlansList(): string {
		return this.wrapped('plans/list');
	}

	public static getPaymentDetails(): string {
		return this.wrapped('payment/details');
	}

	public static getOrderStatus(): string {
		return this.wrapped('order-status');
	}

	public static makePayment(): string {
		return this.wrapped('payment/make');
	}

	public static createLifelineCustomer(): string {
		return this.wrapped('customer/createLifelineCustomer');
	}

	public static activateHandover(): string {
		return this.wrapped('handover-activation');
	}

	public static checkOrderExists(): string {
		return this.wrapped('enrollment-exists');
	}

	public static uploadProof(): string {
		return this.wrapped('proof');
	}
}
