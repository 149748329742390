import { HttpErrorResponse } from '@angular/common/http';
import { Errors } from '@core/interfaces/errors/middleware';
import { Store } from '@ngxs/store';
import { DialogActions } from './dialog/dialog.actions';
import {
	httpErrorResponseIncludesError,
	parseErrorToString
} from '@core/utils/http-error-response';
import { ErrorResponse } from '@core/interfaces/error-response';

import ShowErrorDialog = DialogActions.ShowErrorDialog;
import ShowWarningDialog = DialogActions.ShowWarningDialog;
import {
	isArrayNullOrEmpty,
	isStringNotNullOrEmpty,
	isStringNullOrEmpty,
	newlineSymbol,
	stringEmpty
} from '@core/utils/utils';
import Middleware = Errors.Middleware;
import { ApiResponse } from '@core/interfaces/api-response.type';

export class BaseState {
	constructor(protected store: Store) {}

	protected handleApiErrorResponseError(errorResponse: ApiResponse<any>) {
		let key = errorResponse.errorCodes?.length
			? Middleware.getErrorMessageKey(errorResponse.errorCodes[0])
			: undefined;

		const unknownErrorKey = Middleware.getErrorMessageKey(
			Middleware.unknown
		);

		if (isStringNullOrEmpty(key) || key === unknownErrorKey) {
			const errorString = this.getErrorMessage(errorResponse);

			if (isStringNotNullOrEmpty(errorString)) {
				this.store.dispatch(new ShowErrorDialog(errorString));
				return;
			}

			key = unknownErrorKey;
		}

		this.store.dispatch(new ShowErrorDialog(key as string));
	}

	protected handleMiddlewareError(errorResponse: HttpErrorResponse) {
		let key = Errors.Middleware.getErrorKey(errorResponse);
		const unknownErrorKey = Middleware.getErrorMessageKey(
			Middleware.unknown
		);

		//TODO: TRY TO SHOW ERRORS INSTEAD OF UNKNOWN
		if (isStringNullOrEmpty(key) || key === unknownErrorKey) {
			const errorString = parseErrorToString(errorResponse);
			if (isStringNotNullOrEmpty(errorString)) {
				this.store.dispatch(new ShowErrorDialog(errorString));
				return;
			}

			key = unknownErrorKey;
		}

		if (
			httpErrorResponseIncludesError(
				errorResponse,
				Errors.Middleware.unavailableDevice
			)
		) {
			this.store.dispatch(new ShowWarningDialog(key as string));
			return;
		}

		this.store.dispatch(new ShowErrorDialog(key as string));
	}

	protected getExceptionMessage(response: HttpErrorResponse): string {
		const errorResponse = response.error as ErrorResponse;
		if (!errorResponse) {
			return '';
		}
		const { responseException } = errorResponse;
		if (!responseException?.exceptionMessage) {
			return '';
		}
		const { message } = responseException.exceptionMessage;
		return message;
	}

	protected getExceptionMessageByCode(errorCode: string): string {
		let message = Errors.Middleware.getErrorMessageKey(errorCode);
		if (!message) {
			message = Middleware.getErrorMessageKey(Middleware.unknown);
		}
		return message!;
	}

	protected isMailAddressError(response: HttpErrorResponse): boolean {
		return Middleware.isMailingAddressError(response);
	}

	private getErrorMessage(errorResponse: ApiResponse<any>) {
		const { errors, failures } = errorResponse ?? {};

		if (isArrayNullOrEmpty(errors) && isArrayNullOrEmpty(failures)) {
			return stringEmpty;
		}

		const errorsString =
			!isArrayNullOrEmpty(errors) && Array.isArray(errors)
				? errors.join(newlineSymbol)
				: stringEmpty;

		const failuresString =
			!isArrayNullOrEmpty(failures) && Array.isArray(failures)
				? failures.join(newlineSymbol)
				: stringEmpty;

		return `${errorsString}\n${failuresString}`;
	}
}
