export enum OrderDeliveryMethod {
	None = 0,
	Handover = 1,
	Shipment = 2
}

export function orderDeliveryMethodToArray(
	orderDeliveryMethods: OrderDeliveryMethod
): OrderDeliveryMethod[] {
	const result: OrderDeliveryMethod[] = [];

	for (const option in OrderDeliveryMethod) {
		if (isNaN(Number(option))) {
			const optionValue = OrderDeliveryMethod[
				option
			] as unknown as OrderDeliveryMethod;
			if ((orderDeliveryMethods & optionValue) === optionValue) {
				result.push(optionValue);
			}
		}
	}

	return result;
}

export function orderDeliveryMethodsArrayToFlags(
	options: OrderDeliveryMethod[]
): OrderDeliveryMethod {
	const deliveryMethods = options.reduce((acc, option) => acc | option, 0);
	return deliveryMethods as OrderDeliveryMethod;
}
