import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackbarActions } from './snackbar.actions';
import { TranslateService } from '@ngx-translate/core';
import ShowMessage = SnackbarActions.ShowMessage;
import ShowErrorMessage = SnackbarActions.ShowErrorMessage;
import ShowSuccessMessage = SnackbarActions.ShowSuccessMessage;
import { SnackBarPosition } from '@shared/constants/snack-bar-position.enum';
import { isNotNullish } from '@core/utils/utils';

export interface SnackbarModel {
	messages: string[];
}

@State<SnackbarModel>({
	name: 'snackbar',
	defaults: {
		messages: []
	}
})
@Injectable()
export class SnackbarState {
	private okLabel: string;

	constructor(
		private snackbarService: MatSnackBar,
		private translateService: TranslateService
	) {
		this.translateService.get('Ok').subscribe(value => {
			this.okLabel = value;
		});
	}

	@Action(ShowMessage)
	showMessage(ctx: StateContext<SnackbarModel>, { payload }: SnackbarActions.ShowMessage) {
		const state = ctx.getState();

		ctx.patchState({
			messages: [...state.messages, payload]
		});

		return this.snackbarService.open(payload, this.okLabel, {
			horizontalPosition: SnackBarPosition.Right
		});
	}

	@Action(ShowErrorMessage)
	showErrorMessage(
		ctx: StateContext<SnackbarModel>,
		{ payload, durationSec }: SnackbarActions.ShowErrorMessage
	) {
		const state = ctx.getState();

		ctx.patchState({
			messages: [...state.messages, payload]
		});

		let options: MatSnackBarConfig = {
			horizontalPosition: SnackBarPosition.Right,
			panelClass: ['error-snackbar']
		};

		if (isNotNullish(durationSec)) {
			options.duration = durationSec * 1000;
		}

		return this.snackbarService.open(payload, this.okLabel, options);
	}

	@Action(ShowSuccessMessage)
	showSuccessMessage(
		ctx: StateContext<SnackbarModel>,
		{ payload, durationSec }: SnackbarActions.ShowSuccessMessage
	) {
		const state = ctx.getState();

		ctx.patchState({
			messages: [...state.messages, payload]
		});

		let options: MatSnackBarConfig = {
			horizontalPosition: SnackBarPosition.Right,
			panelClass: ['success-snackbar']
		};

		if (isNotNullish(durationSec)) {
			options.duration = durationSec * 1000;
		}

		return this.snackbarService.open(payload, this.okLabel, options);
	}
}
