<h2
	class="f-w-500 m-b-24"
	mat-dialog-title
>
	<strong>Create Invite</strong>
</h2>
<mat-dialog-content style="max-width: 800px">
	<form [formGroup]="selfSignupInviteForm">
		<div class="row">
			<div class="col-lg-6">
				<mat-label class="mat-subtitle-2 f-w-400 m-b-8 d-block">Name</mat-label>
				<mat-form-field
					appearance="outline"
					class="w-100"
				>
					<input
						type="text"
						matInput
						required
						id="name"
						name="name"
						formControlName="name"
						placeholder=""
					/>
					<mat-error *ngIf="selfSignupInviteForm.controls.name.hasError('required')">Name is required</mat-error>
				</mat-form-field>
			</div>
			<div class="col-lg-6">
				<mat-label class="mat-subtitle-2 f-w-400 m-b-8 d-block">Choose an expiration date</mat-label>
				<mat-form-field
					appearance="outline"
					class="w-100"
				>
					<!--          <mat-label>Choose an expiration date</mat-label>-->
					<input
						matInput
						appMaskDate
						formControlName="expirationDate"
						[matDatepicker]="picker"
					/>
					<mat-hint>MM/DD/YYYY</mat-hint>
					<mat-datepicker-toggle
						matIconSuffix
						[for]="picker"
					></mat-datepicker-toggle>
					<mat-datepicker
						#picker
						startView="multi-year"
						[touchUi]="true"
					></mat-datepicker>
					<!--        </mat-form-field>-->
					<!--        <mat-form-field appearance="outline" class="w-100">-->
					<!--          <input-->
					<!--                  type="text"-->
					<!--                  matInput-->
					<!--                  required-->
					<!--                  id="expired"-->
					<!--                  name="expired"-->
					<!--                  formControlName="expired"-->
					<!--                  placeholder=""-->
					<!--          />-->
					<mat-error *ngIf="selfSignupInviteForm.controls.expirationDate.hasError('required')">Expiration date is required</mat-error>
				</mat-form-field>
			</div>
			<div class="col-lg-6">
				<mat-label class="mat-subtitle-2 f-w-400 m-b-8 d-block">Associated CLEC ID</mat-label>
				<mat-form-field
					appearance="outline"
					class="w-100"
				>
					<mat-select
						formControlName="associatedClecId"
						placeholder="Select CLEC ID"
					>
						<mat-option
							*ngFor="let item of carrierCodes$ | async"
							[value]="item.id"
							>{{ item.description }}</mat-option
						>
					</mat-select>
					<mat-error *ngIf="selfSignupInviteForm.controls.associatedClecId.hasError('min')">CLEC ID is required</mat-error>
				</mat-form-field>
			</div>
			<div class="col-lg-6">
				<mat-label class="mat-subtitle-2 f-w-400 m-b-8 d-block">Description</mat-label>
				<mat-form-field
					appearance="outline"
					class="w-100"
					color="primary"
				>
					<textarea
						matInput
						rows="3"
						formControlName="description"
					>
					</textarea>
					<!--					<mat-icon matPrefix>-->
					<!--						<i-tabler-->
					<!--							name="link"-->
					<!--							class="icon-20 d-flex"-->
					<!--						></i-tabler>-->
					<!--					</mat-icon>-->
				</mat-form-field>
			</div>
		</div>
		<div class="m-t-16">
			<button
				mat-flat-button
				(click)="doAction()"
				color="primary"
				[disabled]="!selfSignupInviteForm.valid"
			>
				Submit
				<mat-icon *ngIf="actionIsExecuting$ | async">
					<mat-spinner
						color="accent"
						diameter="20"
					>
					</mat-spinner>
				</mat-icon>
			</button>
			<button
				mat-stroked-button
				color="warn"
				class="m-l-8"
				(click)="closeDialog()"
			>
				Cancel
			</button>
		</div>
	</form>
</mat-dialog-content>
