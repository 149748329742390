export namespace DialogActions {
	export class ShowErrorDialog {
		static readonly type = '[Dialog] Show Error Dialog';
		constructor(public text: string) {}
	}

	export class ShowWarningDialog {
		static readonly type = '[Dialog] Show Warning Dialog';
		constructor(public text: string) {}
	}

	export class ShowInfo {
		static readonly type = '[Dialog] Show Info';
		constructor(public text: string) {}
	}
}
