<h1
	mat-dialog-title
	class="text-center"
>
	<ng-container [ngSwitch]="data.dialogButton">
		<ng-container *ngSwitchCase="DialogButton.Ok">{{ data.title | translate }}</ng-container>
		<ng-container *ngSwitchCase="DialogButton.ConfirmCancel">{{ data.title | translate }}</ng-container>
		<ng-container *ngSwitchDefault> Confirm Action </ng-container>
	</ng-container>
</h1>
<div mat-dialog-content>
	<p class="text-center whitespace-break-spaces">{{ data.confirmationText | translate }}</p>
</div>
<div
	class="justify-content-around"
	mat-dialog-actions
>
	<ng-container [ngSwitch]="data.dialogButton">
		<ng-container *ngSwitchCase="DialogButton.Ok">
			<button
				mat-button
				(click)="closeDialog()"
			>
				Ok
			</button>
		</ng-container>
		<ng-container *ngSwitchCase="DialogButton.ConfirmCancel">
			<button
				mat-stroked-button
				color="warn"
				(click)="closeDialog()"
			>
				{{ 'Cancel' | translate }}
			</button>
			<button
				mat-stroked-button
				color="primary"
				[mat-dialog-close]="true"
			>
				{{ data.confirmButtonText ?? 'Confirm' | translate }}
			</button>
		</ng-container>
		<ng-container *ngSwitchDefault>
			<button
				mat-button
				(click)="closeDialog()"
			>
				No
			</button>
			<button
				mat-button
				[mat-dialog-close]="true"
			>
				Yes
			</button>
		</ng-container>
	</ng-container>
</div>
