import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { RelativeRoutes } from '../../shared/constants/routes.enum';
import { MsalService } from '@azure/msal-angular';
import { NgxPermissionsService } from 'ngx-permissions';
import { AuditLogsService } from '@modules/audit-logs/services/audit-logs.service';
import { take } from 'rxjs';

export const AccessDeniedGuard: CanActivateFn = () => {
	const router = inject(Router);
	const authService = inject(MsalService);
	const permissionService = inject(NgxPermissionsService);
	const auditLogsService = inject(AuditLogsService);

	let activeAccount = authService.instance.getActiveAccount();

	if (!activeAccount && authService.instance.getAllAccounts().length > 0) {
		activeAccount = authService.instance.getAllAccounts()[0];
	}

	if (activeAccount) {
		auditLogsService
			.loginEvent({ data: activeAccount.localAccountId })
			.pipe(take(1))
			.subscribe();
	}

	const permissions = Object.keys(permissionService.getPermissions());
	if (activeAccount && permissions.length > 0) {
		router.navigate([RelativeRoutes.Dashboard]);
	}

	return true;
};
