import { OrderErrorCode } from '@core/models/order-error-code';

export namespace OrderErrorsActions {
	export class Get {
		static readonly type = '[OrderErrors] Get';
		constructor(
			readonly orderId: string,
			readonly codes?: OrderErrorCode[]
		) {}
	}
}
