import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { StepperOrientation } from '@angular/cdk/stepper';

import { DeviceViewMode } from '@store/common/common.types';
import { CommonActions } from '@store/common/common.actions';

import UpdateDeviceViewMode = CommonActions.UpdateDeviceViewMode;

export interface CommonStateModel {
	deviceViewMode: DeviceViewMode;
}

@State<CommonStateModel>({
	name: 'common',
	defaults: {
		deviceViewMode: DeviceViewMode.Tablet
	}
})
@Injectable()
export class CommonState {
	@Selector()
	static stepperOrientation(state: CommonStateModel): StepperOrientation {
		return state.deviceViewMode === DeviceViewMode.Mobile
			? 'vertical'
			: 'horizontal';
	}

	@Action(UpdateDeviceViewMode)
	updateDeviceViewMode(
		ctx: StateContext<CommonStateModel>,
		{ deviceViewMode }: UpdateDeviceViewMode
	) {
		return ctx.patchState({
			deviceViewMode
		});
	}
}
