import { ApiMethods } from './api-methods';

export class AuditLogApiMethods {
	private static controller: string = 'auditlogs';

	private static wrapped(action: string): string {
		return ApiMethods.prefixed(this.controller, action);
	}

	public static list(): string {
		return this.wrapped('list');
	}

	static loginEvent() {
		return this.wrapped('event/login');
	}

	static logoutEvent() {
		return this.wrapped('event/logout');
	}
}
