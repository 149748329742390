import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs';

import { InvitationsActions } from './invitations.actions';
import { InvitationsService } from '../../services/invitations.service';

import SelfSignup = InvitationsActions.SelfSignup;
import GetInvitations = InvitationsActions.GetInvitations;
import { InvitationViewModel } from '../../models/invitations';
import ChangeAvailability = InvitationsActions.ChangeAvailability;
import { produce } from 'immer';
import { SnackbarActions, SupportMessages } from '@store/snackbar/snackbar.actions';
import SendInvitationEmail = InvitationsActions.SendInvitationEmail;
import ShowMessage = SnackbarActions.ShowMessage;
import ShowErrorMessage = SnackbarActions.ShowErrorMessage;

export interface InvitationsStateModel {
	invitations: InvitationViewModel[];
}

@State<InvitationsStateModel>({
	name: 'invitations',
	defaults: {
		invitations: []
	}
})
@Injectable()
export class InvitationsState {
	@Selector()
	static invitations(state: InvitationsStateModel): InvitationViewModel[] {
		return state.invitations.map(invite => {
			return {
				...invite,
				expired: new Date(invite.expirationDate) < new Date()
			};
		});
	}

	constructor(private invitationsService: InvitationsService) {}

	@Action(SelfSignup)
	createSelfSignup(ctx: StateContext<InvitationsStateModel>, { payload }: SelfSignup) {
		const state = ctx.getState();
		return this.invitationsService.selfSignup(payload).pipe(
			tap(response => {
				if (response.succeeded) {
					ctx.patchState({
						invitations: [...state.invitations, response.data]
					});
				}
			})
		);
	}

	@Action(GetInvitations)
	getInvitations(ctx: StateContext<InvitationsStateModel>) {
		return this.invitationsService.getInvitations().pipe(
			tap(response => {
				if (response.succeeded) {
					ctx.patchState({
						invitations: response.data
					});
				}
			})
		);
	}

	@Action(ChangeAvailability)
	changeAvailability(ctx: StateContext<InvitationsStateModel>, { payload }: ChangeAvailability) {
		const state = ctx.getState();
		const idx = state.invitations.findIndex(x => x.id === payload.id);

		return this.invitationsService.changeAvailability(payload).pipe(
			tap(({ succeeded, data }) => {
				if (succeeded) {
					ctx.patchState(
						produce(state, draft => {
							draft.invitations[idx] = {
								...draft.invitations[idx],
								enabled: payload.enabled
							};
						})
					);
					ctx.dispatch(
						new SnackbarActions.ShowMessage(
							`${data.name} is ${payload.enabled ? 'Activated' : 'Deactivated'}`
						)
					);
					return;
				}
				ctx.dispatch(new SnackbarActions.ShowMessage(SupportMessages.UnhandledError));
			})
		);
	}

	@Action(SendInvitationEmail)
	sendInvitationEmail(
		ctx: StateContext<InvitationsStateModel>,
		{ payload }: SendInvitationEmail
	) {
		return this.invitationsService.sendInvitationEmail(payload).pipe(
			tap(({ succeeded }) => {
				if (succeeded) {
					ctx.dispatch(new ShowMessage('Email sent'));
					return;
				}
				ctx.dispatch(new ShowErrorMessage('Email cant be send. Please contact support.'));
			})
		);
	}
}
