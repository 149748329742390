export enum OnboardingStep {
	Clec = 0,
	ZipBenefit = 1,
	PersonalInfo = 2,
	Contact = 3,
	Address = 4,
	Program = 5,
	Review = 6,
	DeviceAndPlan = 7,
	Payment = 8,
	AssignDevice = 9,
	Completed = 10
}
