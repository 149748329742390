import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { protectedResources } from '@core/msal/msal.config';
import { TribalApiMethods } from '@core/utils/api/tribal-api-methods';
import { ApiResponse } from '@core/interfaces/api-response.type';
import { LatLng } from '@core/interfaces/address-lookup-result.type';

@Injectable({ providedIn: 'root' })
export class TribalService {
	url = protectedResources.api.endpoint;

	constructor(private httpClient: HttpClient) {}

	public validateTribal({ latitude, longitude }: LatLng): Observable<ApiResponse<boolean>> {
		return this.httpClient.post<ApiResponse<boolean>>(TribalApiMethods.validateTribal(), {
			x: longitude,
			y: latitude
		});
	}
}
