import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, take } from 'rxjs';
import { ApiResponse, PaginatedApiResponse } from '@core/interfaces/api-response.type';
import {
	AuditLogViewModel,
	GetAuditLogsQuery,
	LoginEventCommand,
	LogoutEventCommand
} from '@core/api-client/data-contracts';
import { AuditLogApiMethods } from '@core/utils/api/audit-logs-api-methods';

@Injectable({
	providedIn: 'root'
})
export class AuditLogsService {
	constructor(private http: HttpClient) {}

	list(payload: GetAuditLogsQuery): Observable<PaginatedApiResponse<AuditLogViewModel[]>> {
		return this.http.post<PaginatedApiResponse<AuditLogViewModel[]>>(
			AuditLogApiMethods.list(),
			payload
		);
	}

	loginEvent(payload: LoginEventCommand): Observable<ApiResponse<boolean>> {
		return this.http.post<ApiResponse<boolean>>(AuditLogApiMethods.loginEvent(), payload);
	}

	logoutEvent(payload: LogoutEventCommand): Observable<ApiResponse<boolean>> {
		return this.http.post<ApiResponse<boolean>>(AuditLogApiMethods.logoutEvent(), payload);
	}
}
