import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserUtils } from '@azure/msal-browser';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';

import { RoleGuard } from '@core/guards/role.guard';
import { AccessDeniedGuard } from '@core/guards/access-denied-guard';

import { Role } from '@modules/employees/pages/employees/employee.type';
import { AppSandboxComponent } from '@modules/sandbox/sandbox.component';
import { InvitationsComponent } from '@modules/invitations/invitations.component';
import { EmployeeSignupRequestsComponent } from '@modules/signup-requests/employee-signup-requests.component';

import { MainComponent } from '@shared/components/main/main.component';
import { AbsoluteRoutes, RelativeRoutes } from '@shared/constants/routes.enum';
import { ModuleName } from '@shared/constants/module-name';
import { HealthCheckComponent } from '@modules/health-check/health-check.component';

const routes: Routes = [
	{
		path: RelativeRoutes.Default,
		component: MainComponent,
		children: [
			{
				path: RelativeRoutes.Default,
				redirectTo: AbsoluteRoutes.Dashboard,
				pathMatch: 'full'
			},
			{
				path: RelativeRoutes.Dashboard,
				loadChildren: () =>
					import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
			},
			{
				path: RelativeRoutes.Employees,
				loadChildren: () =>
					import('./modules/employees/employees.module').then(m => m.EmployeesModule),
				canActivate: [RoleGuard],
				data: {
					expectedRoles: [Role.SuperAdmin, Role.Admin]
				}
			},
			{
				path: RelativeRoutes.AffiliateLinks,
				loadChildren: () =>
					import('@modules/web-links/web-links.module').then(m => m.WebLinksModule)
			},
			{
				path: RelativeRoutes.Devices,
				loadChildren: () =>
					import('@modules/data-import/devices.module').then(m => m.DevicesModule),
				canActivate: [RoleGuard],
				data: {
					expectedRoles: [Role.SuperAdmin, Role.Admin]
				}
			},
			{
				path: RelativeRoutes.Masterzip,
				loadChildren: () =>
					import('@modules/masterzip/master-zip.module').then(m => m.MasterZipModule),
				canActivate: [RoleGuard],
				data: {
					expectedRoles: [Role.SuperAdmin, Role.Admin]
				}
			},
			{
				path: RelativeRoutes.Invitations,
				component: InvitationsComponent,
				canActivate: [RoleGuard],
				data: {
					expectedRoles: [Role.SuperAdmin, Role.Admin, Role.ClecManager]
				}
			},
			{
				path: RelativeRoutes.SignupRequests,
				component: EmployeeSignupRequestsComponent,
				canActivate: [RoleGuard],
				data: {
					expectedRoles: [Role.SuperAdmin, Role.Admin, Role.ClecManager]
				}
			},
			{
				path: RelativeRoutes.Customers,
				loadChildren: () =>
					import('./modules/customer/customer.module').then(m => m.CustomerModule),
				canActivate: [RoleGuard],
				data: {
					expectedRoles: [
						Role.SuperAdmin,
						Role.Admin,
						Role.ClecManager,
						Role.ExternalSalesAgent,
						Role.InternalSalesAgent,
						Role.CallCenterOperator
					]
				}
			},
			{
				path: RelativeRoutes.Retail,
				loadChildren: () =>
					import('@modules/retail/retail.module').then(m => m.RetailModule)
			},
			{
				path: RelativeRoutes.Optimum,
				loadChildren: () =>
					import('@modules/optimum/optimum.routes').then(m => m.optimumRoutes)
			},
			{
				path: RelativeRoutes.HealthCheck,
				component: HealthCheckComponent,
				canActivate: [RoleGuard],
				data: {
					expectedRoles: [Role.SuperAdmin, Role.Admin]
				}
			},
			{
				path: RelativeRoutes.AuditLogs,
				loadChildren: () =>
					import('@modules/audit-logs/audit-logs.routes').then(m => m.auditLogsRoutes)
			}
		],
		data: {
			//TODO: ALL VALID ROLES
			expectedRoles: [
				Role.SuperAdmin,
				Role.InternalSalesAgent,
				Role.Admin,
				Role.SalesAgentSupport,
				Role.ExternalSalesAgent,
				Role.ClecManager,
				Role.CallCenterOperator
			],
			moduleName: ModuleName.Sap
		},
		canActivate: [RoleGuard, MsalGuard]
	},
	{
		path: RelativeRoutes.OptimumAffiliateEnrollment,
		loadChildren: () =>
			import('@modules/optimum-web/optimum-affiliate.routes').then(
				m => m.optimumAffiliateRoutes
			)
	},
	{
		path: RelativeRoutes.WebEnrollmentLink,
		loadChildren: () =>
			import('@modules/web-enrollments/web-enrollments.module').then(
				m => m.WebEnrollmentsModule
			)
	},
	{
		path: RelativeRoutes.LifelineEnrollment,
		loadChildren: () =>
			import('@modules/lifeline-enrollment/lifeline-enrollment.module').then(
				m => m.LifelineEnrollmentModule
			)
	},
	{
		path: RelativeRoutes.Sandbox,
		component: AppSandboxComponent,
		canActivate: [MsalGuard, AccessDeniedGuard]
	},
	{
		// Needed for handling redirect after login
		path: RelativeRoutes.MsalRedirect,
		component: MsalRedirectComponent
	},
	{
		path: RelativeRoutes.NotFound,
		loadComponent: () =>
			import('@shared/components/not-found-page/not-found-page.component').then(
				m => m.NotFoundPageComponent
			)
	},
	{
		path: '**',
		redirectTo: AbsoluteRoutes.Default
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			// Don't perform initial navigation in iframes or popups
			initialNavigation:
				!BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
					? 'enabledNonBlocking'
					: 'disabled' // Set to enabledBlocking to use Angular Universal
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
	constructor() {}
}
