import { ApiMethods } from './api-methods';

export class EmployeeApiMethods {
	private static controller: string = 'employees';

	private static wrapped(action: string): string {
		return ApiMethods.prefixed(this.controller, action);
	}

	public static getCarrierCodes(): string {
		return this.wrapped('getCarrierCodes');
	}

	public static getMvnoMetadata(): string {
		return this.wrapped('getMvnoMetadata');
	}

	public static list(): string {
		return this.wrapped('list');
	}

	public static add(): string {
		return this.wrapped('add');
	}

	public static update(): string {
		return this.wrapped('update');
	}

	public static invite(): string {
		return this.wrapped('invite');
	}

	public static getSignupRequests(): string {
		return this.wrapped('getEmployeesRequests');
	}

	public static approveSignupRequest = () => this.wrapped('approveSignupRequest');
	public static declineSignupRequest = () => this.wrapped('declineSignupRequest');

	public static agentsList = () => {
		return this.wrapped('agents');
	};

	public static importDataFile = () => {
		return this.wrapped('import');
	};

	public static importDataFilePasswords = () => {
		return this.wrapped('import/passwords');
	};

	public static agentsListByClecId = () => {
		return this.wrapped('agentsByClecId');
	};
}
