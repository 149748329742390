export const dialogMinWidth = '350px';
export const dialogMinViewWidth = '40wv';

export const dialogFullWidth = {
	maxWidth: '98vw'
};

export const dialogFullScreen = {
	maxWidth: '98vw',
	height: '98vh'
};

export enum DialogHeader {
	Info = 'Info',
	Error = 'Error',
	Warning = 'Warning'
}
