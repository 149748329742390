import { APP_INITIALIZER, ErrorHandler, FactoryProvider, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TablerIconsModule } from 'angular-tabler-icons';
import * as TablerIcons from 'angular-tabler-icons/icons';

import { NgScrollbarModule } from 'ngx-scrollbar';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import {
	HTTP_INTERCEPTORS,
	HttpClient,
	HttpClientModule,
	provideHttpClient,
	withJsonpSupport
} from '@angular/common/http';
import {
	TranslateLoader,
	TranslateModule,
	TranslateService as NgxTranslateService
} from '@ngx-translate/core';
import { HttpLoaderFactory } from '@core/utils/http-loader-factory';

import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import {
	MsalInterceptor,
	MsalModule,
	MsalRedirectComponent,
	MsalService
} from '@azure/msal-angular';

import { RoleGuard } from '@core/guards/role.guard';
import { environment } from '@environment/environment';
import { NgxsModule, getActionTypeFromInstance } from '@ngxs/store';
import { NgxLoadingButtonsModule } from 'ngx-loading-buttons';
import { EmployeesState } from '@core/store/employees/employees.state';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsActionsExecutingModule } from '@ngxs-labs/actions-executing';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { AccountState } from '@core/store/account/account.state';
import { AccountService } from '@core/services/account.service';
import { EMPTY, tap } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';
import { CustomerState } from '@core/store/customer/customer.state';
import { InvitationsState } from '@core/store/invitations/invitations.state';
import { SnackbarState } from '@core/store/snackbar/snackbar.state';
import { DialogState } from '@store/dialog/dialog.state';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MiddlewareState } from '@core/store/middleware/middleware.state';
import { TranslateService } from '@core/services/translate.service';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import * as Sentry from '@sentry/angular-ivy';
import { CommonState } from '@store/common/common.state';
import { PhoneVerificationState } from '@store/phone-verification/phone-verification.state';
import { OrderErrorsState } from '@store/customer/errors/order-errors.state';
import { createErrorMaxsipErrorHandler } from '@core/interceptors/error-handler';
import { Router } from '@angular/router';
import { protectedResourceMap } from './app.config';
import { LiveChatWidgetModule } from '@livechat/widget-angular';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import {
	MAT_MOMENT_DATE_ADAPTER_OPTIONS,
	MomentDateAdapter
} from '@angular/material-moment-adapter';
import { SideBarDataService } from '@shared/components/sidebar/sidebar-data.service';
import { PaymentMethodsState } from '@store/customer/payment-methods/payment-methods.state';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { DocumentTypesState } from '@store/document-types/document-types.state';
import { provideFeatureFlag } from '@core/services/feature-flags/feature-flag.provider';
import { AuditLogsService } from '@modules/audit-logs/services/audit-logs.service';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { PhoneVerificationActions } from '@store/phone-verification/phone-verification.actions';

function loadPermissionsFactory(
	msalService: MsalService,
	accountService: AccountService,
	ngxPermissionsService: NgxPermissionsService
) {
	return () =>
		msalService.handleRedirectObservable().pipe(
			concatMap(response => {
				if (!response && msalService.instance.getAllAccounts().length === 0) {
					return EMPTY;
				}

				return accountService.getPermissions().pipe(
					tap(response => {
						if (response.succeeded) {
							ngxPermissionsService.loadPermissions(response.data);
						}
						return EMPTY;
					}),
					catchError(async err => {
						await msalService.logoutRedirect();
						throw err;
					})
				);
			})
		);
}

export const loadPermissionsProvider: FactoryProvider = {
	provide: APP_INITIALIZER,
	useFactory: loadPermissionsFactory,
	deps: [MsalService, AccountService, NgxPermissionsService],
	multi: true
};

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialModule,
		TablerIconsModule.pick(TablerIcons),
		NgScrollbarModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		MsalModule.forRoot(
			new PublicClientApplication({
				auth: {
					clientId: environment.clientId,
					authority: environment.authority,
					redirectUri: environment.redirectUri,
					knownAuthorities: [environment.authorityDomain]
				},
				cache: {
					cacheLocation: 'localStorage',
					storeAuthStateInCookie: false
				}
			}),
			{
				interactionType: InteractionType.Redirect,
				authRequest: {
					scopes: ['openid']
				}
			},
			{
				interactionType: InteractionType.Redirect,
				protectedResourceMap
			}
		),
		NgxsModule.forRoot(
			[
				AccountState,
				EmployeesState,
				CustomerState,
				OrderErrorsState,
				InvitationsState,
				SnackbarState,
				MiddlewareState,
				CommonState,
				DialogState,
				PaymentMethodsState,
				DocumentTypesState
			],
			{
				developmentMode: !environment.production
			}
		),
		NgxsResetPluginModule.forRoot(),
		NgxsRouterPluginModule.forRoot(),
		NgxsActionsExecutingModule.forRoot(),
		environment.production
			? []
			: NgxsLoggerPluginModule.forRoot({
					filter: action =>
						getActionTypeFromInstance(action) !==
						PhoneVerificationActions.UpdateTimer.type
				}),
		NgxsReduxDevtoolsPluginModule.forRoot(),
		NgxPermissionsModule.forRoot(),
		NgxLoadingButtonsModule,
		LiveChatWidgetModule
	],
	exports: [TablerIconsModule],
	providers: [
		RoleGuard,
		MsalService,
		AuditLogsService,
		AccountService,
		SideBarDataService,
		NgxPermissionsService,
		{ provide: NgxTranslateService, useClass: TranslateService },
		loadPermissionsProvider,
		provideHttpClient(withJsonpSupport()),
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MsalInterceptor,
			multi: true
		},
		{
			provide: ErrorHandler,
			useValue: createErrorMaxsipErrorHandler()
		},
		{
			provide: Sentry.TraceService,
			deps: [Router]
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true
		},
		{
			provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
			useValue: { duration: 2500 }
		},
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
		},
		provideFeatureFlag()
	],
	bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {}
