export enum SupportMessages {
	UnhandledError = 'Something went wrong. Please contact support.'
}

export namespace SnackbarActions {
	export class ShowMessage {
		static readonly type = '[Snackbar] Show Message';
		constructor(public payload: string) {}
	}

	export class ShowSuccessMessage {
		static readonly type = '[Snackbar] Show Success Message';
		constructor(
			public payload: string,
			public durationSec?: number
		) {}
	}

	export class ShowErrorMessage {
		static readonly type = '[Snackbar] Show Error Message';
		constructor(
			public payload: string,
			public durationSec?: number
		) {}
	}
}
