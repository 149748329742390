import { ApiMethods } from '@core/utils/api/api-methods';

export class ZipApiMethods {
	private static controller: string = 'zipProgramStatuses';

	private static wrapped(action: string): string {
		return ApiMethods.prefixed(this.controller, action);
	}

	public static getZipProgramStatus(zip: string) {
		return this.wrapped(zip);
	}

	public static getZips(): string {
		return this.wrapped('list');
	}

	private static update(): string {
		return this.wrapped('update');
	}

	public static deleteZip(zip: string): string {
		return this.wrapped(zip);
	}

	public static importDataFile = () => {
		return this.wrapped('import');
	};
}
