import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MaxSipCustomerExistsRequest } from '@core/interfaces/maxsip-customer-request.type';
import { ResumeOnboardingDto } from '@core/interfaces/resume-onboarding-dto.type';
import { ErrorDto } from '@core/models/error-dto';
import { OrderErrorCode } from '@core/models/order-error-code';
import { Observable } from 'rxjs';
import { ApiResponse, PaginatedApiResponse } from 'src/app/core/interfaces/api-response.type';
import {
	CancelOnboardingCommand,
	Customer,
	CustomerUpdateDto,
	ExitOnboardingCommand,
	GetCustomersRequest
} from 'src/app/core/models/customer.type';
import { ApiMethods } from 'src/app/core/utils/api/api-methods';
import { PaymentMethod } from '../components/payment-form/payment-form.types';
import { UpdateDeviceCommand } from '@core/models/UpdateDeviceCommand';
import { PaymentDeviceDto } from '@core/interfaces/middleware/PaymentDeviceDto';
import { CustomerListViewModel, ReprocessCommand } from '@core/api-client/data-contracts';

@Injectable({
	providedIn: 'root'
})
export class CustomerService {
	constructor(private http: HttpClient) {}

	public getCustomerById(customerId: string): Observable<ApiResponse<Customer>> {
		return this.http.get<ApiResponse<Customer>>(ApiMethods.getCustomer(customerId));
	}

	public addCustomer(customer: CustomerUpdateDto): Observable<ApiResponse<Customer>> {
		return this.http.post<ApiResponse<Customer>>(ApiMethods.addCustomer(), customer);
	}

	public updateCustomer(customer: CustomerUpdateDto): Observable<ApiResponse<Customer>> {
		return this.http.post<ApiResponse<Customer>>(ApiMethods.updateCustomer(), customer);
	}

	public updateDevicePlan(body: UpdateDeviceCommand): Observable<ApiResponse<boolean>> {
		return this.http.post<ApiResponse<boolean>>(ApiMethods.updateDevicePlan(), body);
	}

	public resumeOnboarding(customerId: string): Observable<ApiResponse<ResumeOnboardingDto>> {
		return this.http.post<ApiResponse<ResumeOnboardingDto>>(ApiMethods.resumeOnboarding(), {
			customerId
		});
	}

	public uploadCustomerDocument(customerId: string, file: File): Observable<ApiResponse<string>> {
		const formData = new FormData();
		formData.append('file', file);
		formData.append('customerId', customerId);

		return this.http.post<ApiResponse<string>>(ApiMethods.uploadCustomerDocument(), formData);
	}

	public cancelOnboarding(command: CancelOnboardingCommand): Observable<ApiResponse<Customer>> {
		return this.http.put<ApiResponse<Customer>>(ApiMethods.cancelOnboarding(), command);
	}

	public exitOnboarding(command: ExitOnboardingCommand): Observable<ApiResponse<Customer>> {
		return this.http.put<ApiResponse<Customer>>(ApiMethods.exitOnboarding(), command);
	}

	public reprocessOnboarding(command: ReprocessCommand): Observable<ApiResponse<Customer>> {
		return this.http.post<ApiResponse<Customer>>(ApiMethods.reprocess(), command);
	}

	public getDeviceTypes(customerId: string): Observable<ApiResponse<PaymentDeviceDto[]>> {
		return this.http.get<ApiResponse<PaymentDeviceDto[]>>(
			ApiMethods.getDeviceTypes(customerId)
		);
	}

	public getCustomersList(
		request: GetCustomersRequest
	): Observable<PaginatedApiResponse<CustomerListViewModel[]>> {
		return this.http.post<PaginatedApiResponse<CustomerListViewModel[]>>(
			ApiMethods.getCustomers(),
			request
		);
	}

	public getErrors(orderId: string, codes: OrderErrorCode[]) {
		return this.http.get<ApiResponse<ErrorDto[]>>(ApiMethods.getErrors(orderId, codes));
	}

	public maxsipCustomerExists(
		request: MaxSipCustomerExistsRequest
	): Observable<ApiResponse<boolean>> {
		return this.http.post<ApiResponse<boolean>>(ApiMethods.maxsipCustomerExists(), request);
	}

	public getPaymentMethods(id: string): Observable<ApiResponse<PaymentMethod[]>> {
		return this.http.get<ApiResponse<PaymentMethod[]>>(ApiMethods.getPaymentMethods(id));
	}
}
