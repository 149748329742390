import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	inject,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
	TemplateRef
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatTableDataSource } from '@angular/material/table';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { TablerIconsModule } from 'angular-tabler-icons';
import { TranslateModule } from '@ngx-translate/core';
import { PageEvent } from '@angular/material/paginator';
import {
	GridColumn,
	PageChangedEvent,
	SortChangedEvent
} from '@shared/components/grid/grid.component.type';
import { Sort } from '@angular/material/sort';
import { getValueByPath, stringCompare } from '@core/utils/utils';

@Component({
	selector: 'app-grid',
	templateUrl: './grid.component.html',
	styleUrls: ['./grid.component.scss'],
	imports: [
		CommonModule,
		MaterialModule,
		ReactiveFormsModule,
		FormsModule,
		NgxMatSelectSearchModule,
		NgxPermissionsModule,
		TablerIconsModule,
		TranslateModule
	],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridComponent<T> implements OnChanges {
	@Input() public isLoading: boolean | null | undefined = true;
	@Input() public actionInProgress: boolean | null | undefined = false;
	@Input() public columns: GridColumn<T>[] = [];
	@Input() public displayIndexColumn = true;
	@Input()
	public length: number | null = 0;
	@Input()
	public translate: boolean | null | undefined = true;
	@Input()
	public actionsTemplate: TemplateRef<any> | null = null;
	@Input()
	public pageSizes: number[] = [15, 30, 50];
	@Input()
	public pageIndex = 0;
	@Input()
	public pageSize = this.pageSizes[0];
	@Input()
	public sorting: Sort | null = null;

	@Input()
	set data(value: T[] | null | undefined) {
		this.dataSource.data = value || [];
	}

	@Output() public pageChanged = new EventEmitter<PageChangedEvent>();
	@Output() public sortingChanged = new EventEmitter<SortChangedEvent | null>();

	private readonly ngxPermissionsService = inject(NgxPermissionsService);
	public dataSource = new MatTableDataSource();

	public displayedColumns: string[] = [];

	public ngOnChanges(changes: SimpleChanges) {
		if (changes['columns']) {
			const columns = this.columns
				.filter(column => this.checkPermissions(column))
				.map(column => column.field as string);
			if (this.displayIndexColumn) {
				columns.unshift('#');
			}

			if (this.actionsTemplate) {
				columns.push('actions');
			}

			this.displayedColumns = columns;
		}
	}

	public handlePageEvent(pageEvent: PageEvent) {
		this.pageChanged.emit(pageEvent);
		this.pageIndex = pageEvent.pageIndex;
		this.pageSize = pageEvent.pageSize;
	}

	public getCellValue(element: T, column: GridColumn<T>): any {
		if (column.formatter) {
			return column.formatter(element);
		}

		if (column.field.includes('.')) {
			return getValueByPath(element, column.field);
		}

		return element[column.field as never];
	}

	public getClasses(element: T, column: GridColumn<T>): string {
		if (!column.className) {
			return '';
		}
		if (typeof column.className === 'string') {
			return column.className;
		}
		if (typeof column.className === 'function') {
			return column.className(element);
		}
		return '';
	}

	public sortChanged(event: Sort) {
		if (!event.active || !event.direction) {
			this.sortingChanged.emit(null);
			return;
		}

		this.sortingChanged.emit({
			column: event.active,
			ascending: event.direction === 'asc'
		});
	}

	private checkPermissions(column: GridColumn<T>): boolean {
		if (column.roles?.length) {
			const permissionName = Object.values(this.ngxPermissionsService.getPermissions())[0]
				?.name;
			return column.roles.some(role => stringCompare(role, permissionName));
		}
		return true;
	}
}
