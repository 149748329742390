import { Inject } from '@angular/core';
import { applyIf, isNullOrUndefined } from '@core/utils/utils';
import { ErrorHandlerOptions, SentryErrorHandler } from '@sentry/angular-ivy';

class MaxsipErrorHandler extends SentryErrorHandler {
	readonly chunkFailedMessage = /Loading chunk [\d]+ failed/;
	constructor(@Inject('errorConfig') config: ErrorHandlerOptions) {
		super(config);
	}

	override handleError(error: any) {
		const message = error?.message;
		if (isNullOrUndefined(message)) {
			super.handleError(error);
			return;
		}

		console.error(error);

		// TODO: NEED TO RE-CHECK WITH B2C CONFIGURATION
		// applyIf(this.chunkFailedMessage.test(message), () =>
		// 	window.location.reload()
		// );

		super.handleError(error);
	}
}

/**
 * Factory function that creates an instance of a preconfigured ErrorHandler provider.
 */
function createErrorMaxsipErrorHandler(): SentryErrorHandler {
	return new MaxsipErrorHandler({ showDialog: false });
}

export { createErrorMaxsipErrorHandler, MaxsipErrorHandler };
