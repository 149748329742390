import { environment } from '@environment/environment';
import { isStringNullOrEmpty } from '@core/utils/utils';
import { SessionRecordingMode } from '@shared/constants/session-recording-mode';

import { match } from 'ts-pattern';

import * as LogRocket from 'logrocket';
import * as Sentry from '@sentry/angular-ivy';
import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';

export class MonitoringUtils {
	public static initDataDog(moduleName: string) {
		const configuration = environment.datadog as RumInitConfiguration;
		configuration.sessionReplaySampleRate =
			moduleName === SessionRecordingMode.WebModule ? 100 : 10;
		datadogRum.init(environment.datadog as RumInitConfiguration);
	}

	public static initMonitoringTools(moduleName: string): void {
		console.log('REPLAY MODE', moduleName); // will remove later
		match(environment.sessionRecordingMode)
			.with(SessionRecordingMode.Application, () => {
				this.initLogRocket();
			})
			.with(moduleName, () => {
				this.initLogRocket();
			})
			.otherwise(() => {
				console.log('LOG ROCKET: UNAVAILABLE');
			});
		this.initDataDog(moduleName);
	}

	private static initLogRocket(): void {
		const { logRocketAppId, stage } = environment;
		if (isStringNullOrEmpty(logRocketAppId) || stage === 'dev') {
			console.log('LOG ROCKET: UNAVAILABLE');
			return;
		}

		LogRocket.init(logRocketAppId);
		LogRocket.getSessionURL(sessionURL => {
			Sentry.configureScope(scope => {
				scope.setExtra('sessionURL', sessionURL);
			});
		});
		console.log('LOG ROCKET: ACTIVATED');
	}
}
