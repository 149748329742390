import { TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';
import { Role } from '@modules/employees/pages/employees/employee.type';

export type Paths<T> = T extends object
	? {
			[K in keyof T]: `${Exclude<K, symbol>}${'' | `.${Paths<T[K]>}`}`;
		}[keyof T]
	: never;

export type Leaves<T> = T extends object
	? {
			[K in keyof T]: `${Exclude<K, symbol>}${Leaves<T[K]> extends never
				? ''
				: `.${Leaves<T[K]>}`}`;
		}[keyof T]
	: never;

export interface GridColumn<T, TR = any> {
	field: Paths<T> | Leaves<T>;
	header: string;
	className?: string | ((element: T) => string);
	sortable?: boolean;
	groupId?: string;

	isDate?: boolean;
	dateFormat?: string;
	formatter?: (element: T) => any;
	payload?: TR;
	roles?: Role[];
}

export enum FilterType {
	Text,
	Dropdown,
	DropdownAsync,
	DatePicker,
	Custom
}

export interface BaseFilter<T, TR = any> {
	key: Paths<T> | Leaves<T>;
	placeholder?: string;
	label: string;
	clearable?: boolean;
	isAlwaysVisible?: boolean;
	payload?: any;
	roles?: Role[];
}

export interface DropdownOption<T = any> {
	id: T;
	label: string;
}

export type TextFilter<T> = BaseFilter<T> & {
	type: FilterType.Text;
};

export type DropdownFilter<T> = BaseFilter<T> & {
	type: FilterType.Dropdown;
	multiple?: boolean;
	emptyOption?: string;
	searchable?: boolean;
	options: DropdownOption[];
};

export type AsyncDropdownFilter<T> = BaseFilter<T> & {
	type: FilterType.DropdownAsync;
	multiple?: boolean;
	emptyOption?: string;
	search: (key: string) => Observable<DropdownOption[]>;
};

export type DateFilter<T> = BaseFilter<T> & {
	type: FilterType.DatePicker;
	minDate?: Date;
	maxDate?: Date;
	placeholder?: string;
	hint?: string;
};

export type CustomFilter<T> = {
	type: FilterType.Custom;
	isAlwaysVisible?: boolean;
	key: Paths<T> | Leaves<T>;
	content: TemplateRef<any>;
	roles?: Role[];
};

export type GridFilter<T> =
	| TextFilter<T>
	| DropdownFilter<T>
	| CustomFilter<T>
	| AsyncDropdownFilter<T>
	| DateFilter<T>;

export interface PageChangedEvent {
	pageIndex: number;
	pageSize: number;
}

export interface SortChangedEvent {
	column: string;
	ascending: boolean;
}
