import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { MiddlewareActions } from './middleware.actions';
import { MiddlewareService } from '../../services/middleware.service';
import { catchError, EMPTY, tap } from 'rxjs';
import { isNotNullish } from '@core/utils/utils';
import { AvailabilityInfo } from '@core/interfaces/middleware/check-availability-result.type';
import { CheckEligibilityResult } from '@core/interfaces/middleware/check-eligibility-result.type';
import { PlanViewModel } from '@core/interfaces/middleware/plan-view-model.type';
import { PaymentDetailsViewModel } from '@core/interfaces/middleware/payment-details-view-model';
import { MakePaymentResult } from '@core/interfaces/middleware/make-payment-result.type';
import { SnackbarActions } from '@store/snackbar/snackbar.actions';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { RequestStatus } from '@core/interfaces/request-status';
import { BaseState } from '@store/base.state';
import { CustomerActions } from '@store/customer/customer.actions';
import { CustomerOnboardingStatus } from '@core/models/onboarding-status';
import { Errors } from '@core/interfaces/errors/middleware';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@shared/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogInfo } from '@shared/components/dialogs/confirmation-dialog/confirmationDialogInfo.type';
import { DialogHeader, dialogMinViewWidth } from '@shared/components/dialogs/dialog-settings.types';
import { DialogButton } from '@shared/constants/dialog-button.enum';
import { LifelineEnrollmentType } from '@modules/customer/constants/lifeline-enrollment-type.enum';
import { OrderStatusViewModel } from '@core/interfaces/middleware/order-status-view-model.type';
import { CreateLifelineStatus } from '@core/interfaces/create-lifeline-status';
import CheckServiceAvailability = MiddlewareActions.CheckServiceAvailability;
import CheckEligibility = MiddlewareActions.CheckEligibility;
import EligibilityStatusCheck = MiddlewareActions.EligibilityStatusCheck;
import NladVerify = MiddlewareActions.NladVerify;
import GetPaymentDetails = MiddlewareActions.GetPaymentDetails;
import MakePayment = MiddlewareActions.MakePayment;
import CreateLifelineCustomer = MiddlewareActions.CreateLifelineCustomer;
import GetOrderStatusDetails = MiddlewareActions.GetOrderStatusDetails;
import UpdateCreateLifelineStatus = MiddlewareActions.UpdateCreateLifelineStatus;
import ShowErrorMessage = SnackbarActions.ShowErrorMessage;
import ShowSuccessMessage = SnackbarActions.ShowSuccessMessage;
import { CreateLifelineCustomerViewModel } from '@core/api-client/data-contracts';
import { OrderErrors } from '@core/interfaces/errors/order';
import { DialogActions } from '@store/dialog/dialog.actions';

export interface MiddlewareStateModel {
	availabilityInfo: AvailabilityInfo | null;
	eligibilityCheckResult: CheckEligibilityResult | null;
	paymentDetails: PaymentDetailsViewModel | null;
	makePaymentResult: MakePaymentResult | null;
	createLifelineResult: CreateLifelineCustomerViewModel | null;
	createLifelineStatus: CreateLifelineStatus | null;
	makePaymentStatus: RequestStatus;
	paymentDetailsStatus: RequestStatus;
	orderStatusDetails: OrderStatusViewModel | null;
	customErrors: {
		acpApplicationExistsError: boolean;
	};

	planMakePaymentResult: MakePaymentResult | null;
	planPaymentDetails: PaymentDetailsViewModel | null;
	planPaymentDetailsStatus: RequestStatus;
	planMakePaymentStatus: RequestStatus;
	proofsUploaded: boolean | null;
}

const defaults: MiddlewareStateModel = {
	availabilityInfo: null,
	eligibilityCheckResult: null,
	makePaymentResult: null,
	paymentDetails: null,
	createLifelineResult: null,
	createLifelineStatus: null,
	makePaymentStatus: RequestStatus.None,
	paymentDetailsStatus: RequestStatus.None,
	orderStatusDetails: null,
	customErrors: {
		acpApplicationExistsError: false
	},

	planMakePaymentResult: null,
	planPaymentDetails: null,
	planPaymentDetailsStatus: RequestStatus.None,
	planMakePaymentStatus: RequestStatus.None,
	proofsUploaded: null
};

@State<MiddlewareStateModel>({
	name: 'middleware',
	defaults: defaults
})
@Injectable()
export class MiddlewareState extends BaseState {
	constructor(
		store: Store,
		private readonly dialog: MatDialog,
		private middlewareService: MiddlewareService,
		private translateService: TranslateService
	) {
		super(store);
	}

	@Selector()
	static createLifelineStatus(state: MiddlewareStateModel): CreateLifelineStatus | null {
		return state.createLifelineStatus;
	}

	@Selector()
	static paymentDetailsStatus(state: MiddlewareStateModel): RequestStatus {
		return state.paymentDetailsStatus;
	}

	@Selector()
	static paymentStatus(state: MiddlewareStateModel): RequestStatus {
		return state.makePaymentStatus;
	}

	@Selector()
	static availabilityInfo(state: MiddlewareStateModel): AvailabilityInfo | null {
		return state.availabilityInfo;
	}

	@Selector()
	static enrollmentId(state: MiddlewareStateModel): string | undefined {
		return state.availabilityInfo?.enrollmentId;
	}

	@Selector()
	static eligibilityCheckResult(state: MiddlewareStateModel): CheckEligibilityResult | null {
		return state.eligibilityCheckResult;
	}

	@Selector()
	static paymentDetails(state: MiddlewareStateModel): PaymentDetailsViewModel | null {
		return state.paymentDetails;
	}

	@Selector()
	static makePaymentResult(state: MiddlewareStateModel): MakePaymentResult | null {
		return state.makePaymentResult;
	}

	@Selector()
	static planMakePaymentResult(state: MiddlewareStateModel): MakePaymentResult | null {
		return state.planMakePaymentResult;
	}

	@Selector()
	static planInvoiceNumber(state: MiddlewareStateModel): string | null {
		return state.planMakePaymentResult?.invoiceNumber ?? null;
	}

	@Selector()
	static planPaymentDetails(state: MiddlewareStateModel): PaymentDetailsViewModel | null {
		return state.planPaymentDetails;
	}

	@Selector()
	static planPaymentDetailsStatus(state: MiddlewareStateModel): RequestStatus {
		return state.planPaymentDetailsStatus;
	}

	@Selector()
	static planPaymentStatus(state: MiddlewareStateModel): RequestStatus {
		return state.planMakePaymentStatus;
	}

	@Selector()
	static invoiceNumber(state: MiddlewareStateModel): string | null {
		return state.makePaymentResult?.invoiceNumber ?? null;
	}

	@Selector()
	static acpApplicationExistsError(state: MiddlewareStateModel): boolean {
		return state.customErrors.acpApplicationExistsError;
	}

	@Selector()
	static createLifelineResult(
		state: MiddlewareStateModel
	): CreateLifelineCustomerViewModel | null {
		return state.createLifelineResult;
	}

	@Selector()
	static orderStatusDetails(state: MiddlewareStateModel): OrderStatusViewModel | null {
		return state.orderStatusDetails;
	}

	@Selector()
	static proofsUploaded(state: MiddlewareStateModel): boolean {
		return state.proofsUploaded ?? false;
	}

	@Action(CheckServiceAvailability)
	checkServiceAvailability(
		ctx: StateContext<MiddlewareStateModel>,
		{ zip }: CheckServiceAvailability
	) {
		return this.middlewareService.checkServiceAvailability(zip).pipe(
			tap(response => {
				const { succeeded, data, errors, errorCodes } = response;
				if (!succeeded) {
					ctx.patchState({
						availabilityInfo: {
							errorMessage: errors?.[0],
							errorCode: errorCodes?.[0],
							serviceAvailable: false
						}
					});

					return;
				}

				ctx.patchState({
					availabilityInfo: {
						...data,
						serviceAvailable: true
					}
				});
			}),
			catchError((errorResponse: HttpErrorResponse) => {
				this.handleMiddlewareError(errorResponse);

				return EMPTY;
			})
		);
	}

	@Action(CheckEligibility)
	checkEligibility(ctx: StateContext<MiddlewareStateModel>, { customerId }: CheckEligibility) {
		let state = ctx.getState();
		return this.middlewareService.checkEligibility(customerId).pipe(
			tap(response => {
				const { succeeded, data } = response;
				if (succeeded) {
					ctx.dispatch(
						new CustomerActions.UpdateOnboardingStatus(data.customerOnboardingStatus)
					);

					ctx.patchState({
						eligibilityCheckResult: data,
						customErrors: {
							...state.customErrors,
							acpApplicationExistsError: false
						}
					});
				}
			}),
			catchError((errorResponse: HttpErrorResponse) => {
				const errorCode = Errors.Middleware.getErrorCode(errorResponse);
				if (errorCode === Errors.Middleware.acpApplicationExists) {
					ctx.patchState({
						customErrors: {
							...state.customErrors,
							acpApplicationExistsError: true
						}
					});
				}
				this.handleMiddlewareError(errorResponse);

				return EMPTY;
			})
		);
	}

	@Action(EligibilityStatusCheck)
	eligibilityStatusCheck(
		ctx: StateContext<MiddlewareStateModel>,
		{ customerId }: EligibilityStatusCheck
	) {
		return this.middlewareService.eligibilityStatusCheck(customerId).pipe(
			tap(response => {
				const { succeeded, data } = response;
				if (succeeded) {
					ctx.patchState({
						eligibilityCheckResult: data
					});
				}
			}),
			catchError((errorResponse: HttpErrorResponse) => {
				this.handleMiddlewareError(errorResponse);
				return EMPTY;
			})
		);
	}

	@Action(NladVerify)
	nladVerify(ctx: StateContext<MiddlewareStateModel>, { customerId }: EligibilityStatusCheck) {
		return this.middlewareService.nladVerify(customerId).pipe(
			tap(response => {
				this.store.dispatch(
					new CustomerActions.UpdateOnboardingStatus(
						CustomerOnboardingStatus.NladVerified
					)
				);

				if (!response.succeeded) {
					this.handleApiErrorResponseError(response);
				}
			}),
			catchError((errorResponse: HttpErrorResponse) => {
				this.handleMiddlewareError(errorResponse);

				return EMPTY;
			})
		);
	}

	@Action(MiddlewareActions.TransferAcp)
	transferAcp(
		_: StateContext<MiddlewareStateModel>,
		{ customerId, transferExceptionCode, enrollmentType }: MiddlewareActions.TransferAcp
	) {
		return this.middlewareService.transferAcp({ id: customerId, transferExceptionCode }).pipe(
			tap(response => {
				if (!response.succeeded) {
					this.handleApiErrorResponseError(response);
					return;
				}

				this.store.dispatch(
					new CustomerActions.UpdateOnboardingStatus(response.data.status)
				);

				if (response.data?.failed) {
					this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogInfo>(
						ConfirmationDialogComponent,
						{
							width: dialogMinViewWidth,
							data: {
								confirmationText: this.translateService.instant(
									enrollmentType === LifelineEnrollmentType.Acp
										? 'TransferAcpFailedUpdateAndTryAgain'
										: 'TransferAcpFailedUpdateAndTryAgainCombo'
								),
								title: DialogHeader.Error,
								dialogButton: DialogButton.Ok
							}
						}
					);

					return;
				}

				this.store.dispatch(
					new CustomerActions.UpdateIneligibleForTransfer(
						response.data.ineligibleForTransfer,
						response.data.errorMessage
					)
				);
			}),
			catchError((errorResponse: HttpErrorResponse) => {
				this.handleMiddlewareError(errorResponse);

				return EMPTY;
			})
		);
	}

	@Action(MakePayment)
	makePayment(ctx: StateContext<MiddlewareStateModel>, { makePaymentRequest }: MakePayment) {
		return this.middlewareService.makePayment(makePaymentRequest).pipe(
			tap(response => {
				if (response.succeeded) {
					ctx.patchState({
						makePaymentResult: {
							success: true,
							invoiceNumber: response.data
						},
						makePaymentStatus: RequestStatus.Success
					});
					ctx.dispatch([
						new CustomerActions.UpdateOnboardingStatus(CustomerOnboardingStatus.Paid)
					]);
				} else {
					ctx.patchState({
						makePaymentResult: {
							success: false
						},
						makePaymentStatus: RequestStatus.Error
					});

					if (response.errors)
						ctx.dispatch(new ShowErrorMessage(response.errors.join(' ')));
				}
			}),
			catchError((errorResponse: HttpErrorResponse) => {
				this.handleMiddlewareError(errorResponse);
				ctx.patchState({
					makePaymentStatus: RequestStatus.Error
				});

				return EMPTY;
			})
		);
	}

	@Action(CreateLifelineCustomer)
	createLifelineCustomer(
		ctx: StateContext<MiddlewareStateModel>,
		{ customerId }: CreateLifelineCustomer
	) {
		return this.middlewareService.createLifelineCustomer(customerId).pipe(
			tap(({ data }) => {
				ctx.patchState({
					createLifelineStatus: data.createLifelineStatus,
					createLifelineResult: data
				});

				ctx.dispatch([
					new CustomerActions.UpdateOnboardingStatus(data.customerOnboardingStatus),
					new CustomerActions.UpdateOrderStatus(data.status)
				]);

				if (data.createLifelineStatus === CreateLifelineStatus.Rejected) {
					if (isNotNullish(data.messages)) {
						ctx.dispatch(new ShowErrorMessage(data.messages.join(' ')));
					}
					return;
				}
			}),
			catchError((errorResponse: HttpErrorResponse) => {
				ctx.patchState({
					createLifelineStatus: null,
					createLifelineResult: null
				});

				this.handleMiddlewareError(errorResponse);

				return EMPTY;
			})
		);
	}

	@Action(GetOrderStatusDetails)
	getOrderStatus(ctx: StateContext<MiddlewareStateModel>, { customerId }: GetOrderStatusDetails) {
		return this.middlewareService.getOrderStatus(customerId).pipe(
			tap(response => {
				const { succeeded, data } = response;
				if (succeeded) {
					ctx.patchState({
						orderStatusDetails: data
					});

					ctx.dispatch(new CustomerActions.UpdateOrderStatus(data.status));
				} else {
					if (response.errors)
						ctx.dispatch(new ShowErrorMessage(response.errors.join(' ')));
				}
			}),
			catchError((errorResponse: HttpErrorResponse) => {
				this.handleMiddlewareError(errorResponse);

				return EMPTY;
			})
		);
	}

	@Action(GetPaymentDetails)
	getPaymentDetails(
		ctx: StateContext<MiddlewareStateModel>,
		{ paymentMethod, customerId }: GetPaymentDetails
	) {
		return this.middlewareService.getPaymentDetails(paymentMethod, customerId).pipe(
			tap(response => {
				const { succeeded, data } = response;
				if (succeeded) {
					ctx.patchState({
						paymentDetails: data,
						paymentDetailsStatus: RequestStatus.Success
					});
				} else {
					ctx.patchState({
						paymentDetailsStatus: RequestStatus.Error
					});
				}
			}),
			catchError((errorResponse: HttpErrorResponse) => {
				this.handleMiddlewareError(errorResponse);
				ctx.patchState({
					paymentDetailsStatus: RequestStatus.Error
				});
				return EMPTY;
			})
		);
	}

	@Action(MiddlewareActions.UploadProof)
	uploadProof(
		ctx: StateContext<MiddlewareStateModel>,
		{ uploadProofRequest }: MiddlewareActions.UploadProof
	) {
		return this.middlewareService.uploadProof(uploadProofRequest).pipe(
			tap(response => {
				const { succeeded, errorCodes } = response;
				if (succeeded) {
					ctx.patchState({
						proofsUploaded: true
					});
				}

				if (
					isNotNullish(errorCodes) &&
					errorCodes.length > 0 &&
					errorCodes[0] === OrderErrors.proofsNotValid
				) {
					this.store.dispatch(
						new DialogActions.ShowErrorDialog(
							this.translateService.instant(
								OrderErrors.getErrorMessageKey(errorCodes[0])
							)
						)
					);
					return;
				}
			}),
			catchError((errorResponse: HttpErrorResponse) => {
				this.handleMiddlewareError(errorResponse);
				return EMPTY;
			})
		);
	}

	@Action(UpdateCreateLifelineStatus)
	updateCreateLifelineStatus(
		ctx: StateContext<MiddlewareStateModel>,
		{ status }: UpdateCreateLifelineStatus
	) {
		return ctx.patchState({
			createLifelineStatus: status
		});
	}

	@Action(MiddlewareActions.SetProofsUploaded)
	setProofsUploaded(
		ctx: StateContext<MiddlewareStateModel>,
		{ uploaded }: MiddlewareActions.SetProofsUploaded
	) {
		return ctx.patchState({
			proofsUploaded: uploaded
		});
	}
}
