<h2
	class="f-w-500 m-b-24"
	mat-dialog-title
>
	<strong>{{ action }}</strong>
</h2>
<mat-dialog-content
	class="employee-content"
	*ngIf="action !== EmployeeDialogContentAction.Delete; else elseTemplate"
>
	<form [formGroup]="employeeForm">
		<div class="row">
			<div class="col-lg-6">
				<mat-label class="mat-subtitle-2 f-w-400 m-b-8 d-block">First Name</mat-label>
				<mat-form-field
					appearance="outline"
					class="w-100"
				>
					<input
						type="text"
						matInput
						required
						id="firstName"
						name="firstName"
						formControlName="firstName"
						placeholder="Bruce"
					/>
					<mat-error *ngIf="employeeForm.controls.firstName.hasError('required')">First Name is required</mat-error>
				</mat-form-field>
			</div>
			<div class="col-lg-6">
				<mat-label class="mat-subtitle-2 f-w-400 m-b-8 d-block">Last Name</mat-label>
				<mat-form-field
					appearance="outline"
					class="w-100"
				>
					<input
						type="text"
						matInput
						required
						id="lastName"
						name="lastName"
						formControlName="lastName"
						placeholder="Wayne"
					/>
					<mat-error *ngIf="employeeForm.controls.lastName.hasError('required')">Last Name is required</mat-error>
				</mat-form-field>
			</div>
			<div class="col-lg-6">
				<mat-label class="mat-subtitle-2 f-w-400 m-b-8 d-block">Role</mat-label>
				<mat-form-field
					appearance="outline"
					class="w-100"
				>
					<mat-select formControlName="role">
						<mat-option
							*ngFor="let role of mapRoleToLabel"
							[value]="role.id"
							>{{ role.label }}</mat-option
						>
					</mat-select>
					<mat-error *ngIf="employeeForm.controls.role.hasError('required')">Role is required</mat-error>
				</mat-form-field>
			</div>
			<div
				class="col-lg-6"
				*ngIf="isClecIdFieldAvailable"
			>
				<mat-label class="mat-subtitle-2 f-w-400 m-b-8 d-block">CLEC ID</mat-label>
				<mat-form-field
					appearance="outline"
					class="w-100"
				>
					<mat-select
						formControlName="clecIdCode"
						*ngIf="!isClecIdMultipleSelectionAvailable"
						placeholder="Select CLEC ID"
					>
						<mat-option
							*ngFor="let item of carrierCodes$ | async"
							[value]="item.id"
							>{{ item.description }}</mat-option
						>
					</mat-select>
					<mat-select
						formControlName="clecIdCodes"
						placeholder="Select CLEC ID"
						*ngIf="isClecIdMultipleSelectionAvailable"
						[multiple]="true"
					>
						<mat-option
							*ngFor="let item of carrierCodes$ | async"
							[value]="item.id"
							>{{ item.description }}</mat-option
						>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-lg-6">
				<mat-label class="mat-subtitle-2 f-w-400 m-b-8 d-block">Email</mat-label>
				<mat-form-field
					appearance="outline"
					class="w-100 m-b-8"
				>
					<input
						type="email"
						matInput
						required
						id="email"
						name="email"
						formControlName="email"
						placeholder="bwayne@gmail.com"
						[readonly]="EmployeeDialogContentAction.Update === action"
					/>
					<mat-error *ngIf="employeeForm.controls.email.hasError('required')">Email is required</mat-error>
					<mat-error *ngIf="employeeForm.controls.email.hasError('email')">Wrong email</mat-error>
				</mat-form-field>
			</div>
			<div class="col-lg-6">
				<mat-label class="mat-subtitle-2 f-w-400 m-b-8 d-block">Phone</mat-label>
				<mat-form-field
					appearance="outline"
					class="w-100"
				>
					<input
						type="text"
						matInput
						id="phone"
						name="phone"
						formControlName="phone"
						prefix="+1"
						mask="(000) 000 00 00"
						placeholder="+1(232) 344 31 41"
						inputmode="numeric"
					/>
				</mat-form-field>
			</div>

			<div class="col-lg-6">
				<mat-label class="mat-subtitle-2 f-w-400 m-b-8 d-block">Activation Status</mat-label>
				<mat-form-field
					appearance="outline"
					class="w-100"
				>
					<mat-select formControlName="activationStatus">
						<mat-option
							*ngFor="let accountStatusKey of getAccountStatusKeys()"
							[value]="accountStatusKey"
							>{{ getAccountStatus(accountStatusKey) }}</mat-option
						>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-lg-6">
				<mat-label class="mat-subtitle-2 f-w-400 m-b-8 d-block">Delivery Methods</mat-label>
				<mat-form-field
					appearance="outline"
					class="w-100"
				>
					<mat-select
						formControlName="orderDeliveryMethods"
						multiple
					>
						<mat-option
							*ngFor="let option of deliveryMethods"
							[value]="option.value"
						>
							{{ option.label }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-lg-6">
				<mat-label class="mat-subtitle-2 f-w-400 m-b-8 d-block">RAD ID</mat-label>
				<mat-form-field
					appearance="outline"
					class="w-100"
				>
					<input
						type="text"
						matInput
						id="radId"
						name="radId"
						formControlName="radId"
					/>
				</mat-form-field>
			</div>
			<div class="col-lg-6">
				<mat-label class="mat-subtitle-2 f-w-400 m-b-8 d-block">MVNO</mat-label>
				<mat-form-field
					appearance="outline"
					class="w-100"
				>
					<mat-select
						formControlName="mvnoList"
						[multiple]="true"
					>
						<mat-option
							*ngFor="let mvno of mvnoMetadata$ | async"
							[value]="mvno.mvno"
						>
							{{ mvno.description }}
						</mat-option>
					</mat-select>
					<mat-error *ngIf="employeeForm.controls.mvnoList.hasError('required')">At least one MVNO is required</mat-error>
				</mat-form-field>
			</div>
			<div
				*ngIf="isMvnoRequired(Mvno.Maxsip)"
				class="col-lg-6"
			>
				<mat-label class="mat-subtitle-2 f-w-400 m-b-8 d-block">Maxsip Agent ID</mat-label>
				<mat-form-field
					appearance="outline"
					class="w-100"
				>
					<input
						type="text"
						matInput
						required
						id="maxsipAgentId"
						name="maxsipAgentId"
						formControlName="maxsipAgentId"
					/>
					<mat-error *ngIf="employeeForm.controls.maxsipAgentId.hasError('required')">Maxsip Agent ID is required</mat-error>
				</mat-form-field>
			</div>
			<div
				*ngIf="isMvnoRequired(Mvno.Terracom)"
				class="col-lg-6"
			>
				<mat-label class="mat-subtitle-2 f-w-400 m-b-8 d-block">Terracom Agent ID</mat-label>
				<mat-form-field
					appearance="outline"
					class="w-100"
				>
					<input
						type="text"
						matInput
						required
						id="terracomAgentId"
						name="terracomAgentId"
						formControlName="terracomAgentId"
					/>
					<mat-error *ngIf="employeeForm.controls.terracomAgentId.hasError('required')">Terracom Agent ID Id is required</mat-error>
				</mat-form-field>
			</div>
		</div>
		<button
			mat-flat-button
			(click)="doAction()"
			color="primary"
			[disabled]="!employeeForm.valid"
		>
			{{ action }}
			<mat-icon *ngIf="actionIsExecuting$ | async">
				<mat-spinner
					color="accent"
					diameter="20"
				>
				</mat-spinner>
			</mat-icon>
		</button>
		<button
			mat-stroked-button
			color="warn"
			class="m-l-8"
			(click)="closeDialog()"
		>
			Cancel
		</button>
	</form>
</mat-dialog-content>
<ng-template #elseTemplate>
	<div class="p-x-24">
		<p>Sure to delete <span class="f-w-600"></span> ?</p>
	</div>
	<div
		mat-dialog-actions
		class="p-x-24 p-b-24"
	>
		<button
			mat-button
			(click)="doAction()"
			mat-flat-button
			color="warn"
		>
			{{ action }}
		</button>
		<button
			mat-button
			(click)="closeDialog()"
			class="m-l-8"
		>
			Cancel
		</button>
	</div>
</ng-template>
