<div class="rounded p-y-30 p-x-24 m-b-30 overflow-hidden">
	<div class="row">
		<div class="col-sm-8">
			<div class="row">
				<i-tabler
					name="link"
					class="d-flex m-r-8 fill-icon"
				></i-tabler>
				<h4 class="page-title m-0 f-s-20 f-w-600 m-t-2">Invitations</h4>
			</div>
		</div>
	</div>
</div>

<div class="row justify-content-between">
	<div class="col-lg-4">
		<!--        <mat-form-field appearance="outline" class="w-100 hide-hint">-->
		<!--          <input-->
		<!--                  matInput-->
		<!--                  placeholder="Search"-->
		<!--          />-->
		<!--          <mat-icon matSuffix>-->
		<!--            <i-tabler name="search" class="icon-20"></i-tabler>-->
		<!--          </mat-icon>-->
		<!--        </mat-form-field>-->
	</div>
	<div class="col-lg-4 d-flex align-items-center justify-content-end">
		<button
			mat-flat-button
			class="m-l-4"
			color="primary"
			(click)="openDialog(EmployeeDialogContentAction.SelfSignup, {})"
		>
			Create Invite
		</button>
	</div>
</div>
<!--<mat-card class="cardWithShadow users-card m-t-12">-->
<!--	<mat-card-content class="p-24">-->
<!--		<div class="mat-body-1 p-16 rounded m-t-12">-->

<!--		</div>-->
<!--	</mat-card-content>-->
<!--</mat-card>-->

<div
	class="row m-t-16"
	*ngIf="!((invitations$ | async)?.length === 0); else loadingOrEmpty"
>
	<div
		class="col-sm-4 col-lg-4 p-8"
		*ngFor="let invitation of invitations$ | async"
	>
		<mat-card class="cardWithShadow overflow-hidden">
			<mat-card-header class="invitation-header {{ getHeaderClass(invitation) }} d-flex justify-content-between">
				<mat-card-title class="m-b-0 f-s-16">
					<span
						class="rounded-pill f-s-12 f-w-500"
						matTooltip="Associated CLEC"
					>
						{{ getCarrierDescription(invitation.associatedClecId) }}
					</span>
				</mat-card-title>
				<span class="d-flex align-items-center gap-4"
					><i-tabler
						name="clock"
						matTooltip="Expiration Date"
						class="icon-16 d-flex"
					></i-tabler
					>{{ invitation.expirationDate | date }}</span
				>
			</mat-card-header>
			<mat-card-content class="text-center p-y-24">
				<h3 class="m-b-4 mat-subtitle-2 f-s-16 f-w-600">
					{{ invitation.name }}
				</h3>
				<!--				<div class="m-b-4 f-s-14 f-w-500 text-accent">{{ getCarrierDescription(invitation.associatedClecId) }}</div>-->
				<span class="f-s-12">{{ invitation.description }}</span>
			</mat-card-content>
			<mat-divider></mat-divider>
			<mat-card-actions class="invitation-actions text-center p-y-8">
				<mat-slide-toggle
					color="primary"
					matTooltip="Activation/Deactivation"
					[disabled]="invitation.expired"
					[checked]="invitation.enabled"
					(change)="toggleActivation(invitation)"
				>
				</mat-slide-toggle>
				<button
					mat-icon-button
					aria-label="Copy"
					matTooltip="Copy"
					[disabled]="invitation.expired"
					(click)="copyInvitationLink(invitation)"
				>
					<i-tabler
						name="copy"
						class="d-flex m-r-8 fill-icon"
					></i-tabler>
				</button>
				<a
					[disabled]="invitation.expired"
					(click)="openMailClient(invitation)"
					mat-icon-button
					aria-label="Mail"
					matTooltip="Sent in email"
				>
					<i-tabler
						name="mail-forward"
						class="d-flex m-r-8 fill-icon"
					></i-tabler>
				</a>
			</mat-card-actions>
		</mat-card>
	</div>
</div>
<ng-template #loadingOrEmpty>
	<mat-progress-spinner
		diameter="80"
		color="primary"
		mode="indeterminate"
		style="margin: 0 auto"
	>
	</mat-progress-spinner>
</ng-template>
