<div
	*ngIf="!isLoading; else loading"
	class="table-responsive"
>
	<mat-spinner
		*ngIf="actionInProgress"
		[diameter]="50"
		class="m-l-12 spinner-centered"
	></mat-spinner>
	<ng-container *ngIf="dataSource.data.length; else emptyResults">
		<table
			[dataSource]="dataSource"
			class="no-wrap m-t-0 v-middle w-100"
			mat-table
			matSort
			[matSortActive]="sorting?.active ?? ''"
			[matSortDirection]="sorting?.direction ?? 'asc'"
			(matSortChange)="sortChanged($event)"
		>
			<ng-container
				*ngIf="displayIndexColumn"
				matColumnDef="#"
			>
				<th
					*matHeaderCellDef
					mat-header-cell
				>
					#
				</th>
				<td
					*matCellDef="let element; let i = index"
					class="text-sm"
					mat-cell
				>
					{{ i + 1 }}
				</td>
			</ng-container>

			<ng-container
				*ngFor="let col of columns"
				[matColumnDef]="col.field"
			>
				<ng-container *ngIf="col.sortable">
					<th
						*matHeaderCellDef
						class="!font-semibold text-sm text-nowrap whitespace-nowrap cursor-pointer"
						mat-header-cell
						[mat-sort-header]="col.field.toString()"
					>
						{{ translate ? (col.header | translate) : col.header }}
					</th>
				</ng-container>
				<ng-container *ngIf="!col.sortable">
					<th
						*matHeaderCellDef
						class="!font-semibold text-sm text-nowrap whitespace-nowrap cursor-default"
						mat-header-cell
					>
						{{ translate ? (col.header | translate) : col.header }}
					</th>
				</ng-container>
				<ng-container></ng-container>
				<td
					*matCellDef="let element"
					class="text-sm"
					mat-cell
				>
					<span
						class="w-auto"
						[ngClass]="getClasses(element, col)"
						*ngIf="element[col.field] || getCellValue(element, col)"
					>
						{{ col.isDate ? (element[col.field] | date: col.dateFormat || 'MM/dd/yyyy' : 'EST') : '' }}
						{{ !col.isDate && !translate ? getCellValue(element, col) : '' }}
						{{ !col.isDate && translate ? (getCellValue(element, col) | translate) : '' }}
					</span>
				</td>
			</ng-container>

			<ng-container
				matColumnDef="actions"
				*ngIf="actionsTemplate"
			>
				<th
					*matHeaderCellDef
					class="!font-semibold text-sm text-nowrap whitespace-nowrap cursor-pointer"
					mat-header-cell
				>
					Action
				</th>
				<td
					*matCellDef="let element"
					class="text-sm action-link"
					mat-cell
				>
					<div *ngTemplateOutlet="actionsTemplate; context: { $implicit: element }"></div>
				</td>
			</ng-container>

			<tr
				*matHeaderRowDef="displayedColumns"
				mat-header-row
			></tr>
			<tr
				*matRowDef="let row; columns: displayedColumns"
				mat-row
			></tr>
		</table>
	</ng-container>
	<ng-template #emptyResults>
		<div class="flex items-center justify-center h-[950px]">
			<p>{{ 'NoData' | translate }}</p>
		</div>
	</ng-template>
	<mat-paginator
		class="left-0 bottom-0 sticky py-3"
		(page)="handlePageEvent($event)"
		[length]="length || 0"
		[pageIndex]="pageIndex"
		[pageSizeOptions]="pageSizes"
		[pageSize]="pageSize"
		[showFirstLastButtons]="true"
	></mat-paginator>
</div>
<ng-template #loading>
	<div class="h-[950px] flex justify-center items-center">
		<mat-progress-spinner
			color="primary"
			diameter="80"
			mode="indeterminate"
			style="margin: 0 auto"
		>
		</mat-progress-spinner>
	</div>
</ng-template>
