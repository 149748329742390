import { ApiMethods } from './api-methods';

export class AccountApiMethods {
	private static controller: string = 'account';

	private static wrapped(action: string): string {
		return ApiMethods.prefixed(this.controller, action);
	}
	public static getInfo(): string {
		return this.wrapped('info');
	}
	public static permissions(): string {
		return this.wrapped('permissions');
	}

	public static validateHash(hash: string): string {
		return this.wrapped(`validateHash?hash=${hash}`);
	}
}
