import { Component } from '@angular/core';
import { environment } from '@environment/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { MatCardModule } from '@angular/material/card';
import { TablerIconsModule } from 'angular-tabler-icons';

@Component({
	selector: 'health-check',
	standalone: true,
	imports: [MatCardModule, TablerIconsModule],
	templateUrl: './health-check.component.html'
})
export class HealthCheckComponent {
	public readonly baseUrl;
	constructor(private readonly sanitizer: DomSanitizer) {
		this.baseUrl = sanitizer.bypassSecurityTrustResourceUrl(environment.endpointHealthCheck);
	}
}
