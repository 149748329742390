import { EmailVerificationStatus } from '@core/api-client/data-contracts';
import { EnrollmentType } from '@core/models/enrollment-type';
import { EmailVerificationStateModel } from '@store/email-verification/email-verification.state';

export namespace EmailVerificationActions {
	export class SendVerificationEmail {
		static readonly type = '[EmailVerification] Send verification email';

		constructor(
			readonly email: string,
			readonly enrollmentType: EnrollmentType,
			readonly id?: string
		) {}
	}

	export class VerifyEmail {
		static readonly type = '[EmailVerification] Verify email';

		constructor(
			readonly code: string,
			readonly id?: string,
			readonly email?: string,
			readonly enrollmentType?: EnrollmentType
		) {}
	}

	export class SetVerificationStatus {
		static readonly type = '[EmailVerification] Set verification status';

		constructor(readonly status: EmailVerificationStatus) {}
	}

	export class SetNextVerificationEmailCanBeSendAt {
		static readonly type = '[EmailVerification] Set next verification email can be send at';

		constructor(
			readonly nextVerificationEmailCanBeSendAt: Date,
			readonly verifiedEmail: string
		) {}
	}

	export class ResetEmailVerificationState {
		static readonly type = '[EmailVerification] Reset';

		constructor(readonly state?: Partial<EmailVerificationStateModel>) {}
	}
}
