import { Role } from '@modules/employees/pages/employees/employee.type';

export class PermissionUtils {
	private static rolesWithEmployeesManagementAccess: string[] = [Role.Admin, Role.SuperAdmin];

	private static rolesWithClecAccess: string[] = [
		Role.SuperAdmin,
		Role.Admin,
		Role.ClecManager,
		Role.CallCenterOperator
	];

	private static rolesWithClecSelectAccess: string[] = [
		Role.ClecManager,
		Role.ExternalSalesAgent,
		Role.InternalSalesAgent
	];
	public static canAccessManagement(permissions: string[]): boolean {
		return this.isMatchedPermissions(permissions, this.rolesWithEmployeesManagementAccess);
	}

	public static canAccessClecCodes(permissions: string[]): boolean {
		return this.isMatchedPermissions(permissions, this.rolesWithClecAccess);
	}

	public static canAccessClecControl(permissions: string[]): boolean {
		return this.isMatchedPermissions(permissions, this.rolesWithClecSelectAccess);
	}

	public static isMatchedPermissions(permissions: string[], rolesToMatch: string[]): boolean {
		return permissions.some(permission => rolesToMatch.includes(permission as Role));
	}
}
