import { Component, DestroyRef, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TablerIconsModule } from 'angular-tabler-icons';
import { Select, Store } from '@ngxs/store';

import { Observable, tap } from 'rxjs';

import { hasActionsExecuting } from '@ngxs-labs/actions-executing';

import { MatDialog } from '@angular/material/dialog';

import { MaterialModule } from '../../material.module';

import { CarrierCode } from '@core/models/carrier-code';
import { EmployeeSignupRequest } from '@core/models/employee';
import { EmployeesActions } from '@store/employees/employees.actions';
import { EmployeesState } from '@store/employees/employees.state';
import { AppEmployeeRequestActionDialogComponent } from './employee-signup-request-action/employee-request-action.component';
import { Role, SignupRequestStatus } from '@modules/employees/pages/employees/employee.type';
import { NgxPermissionsService } from 'ngx-permissions';
import { dialogMinWidth } from '@shared/components/dialogs/dialog-settings.types';
import { GridComponent } from '@shared/components/grid/grid.component';
import { BasicGridPageComponent } from '@shared/components/grid/basic-grid-page';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EmployeeSignupRequestsColumns } from '@modules/signup-requests/employee-signup-requests.type';

export enum EmployeeRequestAction {
	Approve = 'Approve',
	Decline = 'Decline'
}

@Component({
	selector: 'app-employee-signup-requests',
	standalone: true,
	imports: [MaterialModule, CommonModule, TablerIconsModule, GridComponent],
	templateUrl: './employee-signup-requests.component.html'
})
export class EmployeeSignupRequestsComponent
	extends BasicGridPageComponent<EmployeeSignupRequest, void>
	implements OnInit
{
	@Select(EmployeesState.signUpRequests)
	employeeSignupRequests$: Observable<EmployeeSignupRequest[]>;

	@Select(EmployeesState.totalRequests)
	totalEmployeesCount$: Observable<number>;

	@Select(EmployeesState.carrierCodes)
	carrierCodes$: Observable<CarrierCode[]>;

	@Select(hasActionsExecuting([EmployeesActions.GetEmployeesRequests]))
	isGetListExecuting$: Observable<boolean>;

	carrierCodes: CarrierCode[];

	constructor(
		private store: Store,
		private dialog: MatDialog,
		private readonly destroyRef: DestroyRef,
		private ngxPermissionsService: NgxPermissionsService
	) {
		super();
	}

	public override ngOnInit() {
		super.ngOnInit();
		this.columns = EmployeeSignupRequestsColumns;

		this.carrierCodes$
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				tap(carrierCodes => (this.carrierCodes = carrierCodes)),
				tap(() => {
					const col = this.columns.find(e => e.field === 'clecId');
					if (col) {
						col.formatter = (row: EmployeeSignupRequest) =>
							this.getCarrierDescription(row.clecId);
					}
				})
			)
			.subscribe();

		this.filterValues
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				tap(([_, pagination]) =>
					this.store.dispatch(
						new EmployeesActions.GetEmployeesRequests({
							pageNumber: pagination.pageIndex + 1,
							pageSize: pagination.pageSize
						})
					)
				)
			)
			.subscribe();
	}

	public getCarrierDescription(carrierCodeId: string): string {
		const carrierCode = this.carrierCodes.find(
			carrierCode => carrierCode.id === +carrierCodeId
		);

		return carrierCode?.description ?? '';
	}

	public openActionDialog(action: string, id: string): void {
		this.dialog.open(AppEmployeeRequestActionDialogComponent, {
			minWidth: dialogMinWidth,
			width: '400px',
			data: {
				id,
				action
			}
		});
	}

	public checkIfActionsPermitted(request: EmployeeSignupRequest): boolean {
		const { clecManagerId, maxsipManagerId, status } = request;
		const permissions = this.ngxPermissionsService.getPermissions();

		if (status === SignupRequestStatus.Pending) {
			if (permissions[Role.ClecManager]?.name === Role.ClecManager) {
				return !clecManagerId;
			} else {
				return !!clecManagerId && !maxsipManagerId;
			}
		}

		return !(
			status === SignupRequestStatus.Approved || status === SignupRequestStatus.Declined
		);
	}

	protected readonly EmployeeRequestAction = EmployeeRequestAction;
}
