import { Injectable } from '@angular/core';
import {
	CustomerListViewModel,
	EmailVerificationStatus,
	OrderStatusGeneral
} from '@core/api-client/data-contracts';
import { EmailVerificationActions } from '@store/email-verification/email-verification.actions';
import { PhoneVerificationActions } from '@store/phone-verification/phone-verification.actions';
import { CustomerActions } from './customer.actions';
import { SnackbarActions } from '@store/snackbar/snackbar.actions';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, EMPTY, map, tap, throwError } from 'rxjs';
import { ZipLookupResult } from '../../interfaces/zip-lookup-result.type';
import { ZipService } from '@modules/customer/services/zip.service';
import { ZipProgramStatus } from '../../models/zip-program-status';
import { CustomerService } from 'src/app/modules/customer/services/customer.service';
import { AddressStateModel, Customer } from '../../models/customer.type';
import { EligibilityProgramService } from 'src/app/modules/customer/services/eligibilityProgram.service';
import { EligibilityProgram } from '../../models/eligibility-program.type';
import { TribalService } from '@core/services/tribal.service';
import { MiddlewareService } from '@core/services/middleware.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { CustomerOnboardingStatus } from '@core/models/onboarding-status';
import { AbsoluteRoutes } from '@shared/constants/routes.enum';
import { RequestStatus } from '@core/interfaces/request-status';
import { OnboardingStep } from '@modules/customer/constants/onboarding-step.enum';
import { BaseState } from '@store/base.state';
import { EligibilityIncome } from '@core/models/eligibility-income';
import { OrderErrorsState } from './errors/order-errors.state';
import { DialogActions } from '@store/dialog/dialog.actions';
import { Errors } from '@core/interfaces/errors/middleware';
import { OrderErrors } from '@core/interfaces/errors/order';
import { Navigate } from '@ngxs/router-plugin';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PaymentMethodsState } from './payment-methods/payment-methods.state';
import { produce } from 'immer';
import { patch } from '@ngxs/store/operators';
import { PaymentDeviceDto } from '@core/interfaces/middleware/PaymentDeviceDto';
import ShowErrorDialog = DialogActions.ShowErrorDialog;
import GetEligibilityPrograms = CustomerActions.GetEligibilityPrograms;

import ValidateAddress = CustomerActions.ValidateAddress;

import AddCustomer = CustomerActions.AddCustomer;
import UpdateCustomer = CustomerActions.UpdateCustomer;

import GetCustomersList = CustomerActions.GetCustomersList;
import ValidateTribal = CustomerActions.ValidateTribal;

import UploadCustomerDocument = CustomerActions.UploadCustomerDocument;
import UpdateOnboardingStatus = CustomerActions.UpdateOnboardingStatus;

import ActivateHandover = CustomerActions.ActivateHandover;
import CancelOnboarding = CustomerActions.CancelOnboarding;

import ShowErrorMessage = SnackbarActions.ShowErrorMessage;
import GetCustomerById = CustomerActions.GetCustomerById;
import ResetCurrentCustomer = CustomerActions.ResetCurrentCustomer;
import SetValidateTribal = CustomerActions.SetValidateTribal;
import { isNullOrUndefined } from '@core/utils/utils';
import Middleware = Errors.Middleware;
import { AccountState, AccountStateModel } from '@store/account/account.state';
import { OrderDeliveryMethod, orderDeliveryMethodToArray } from '@core/models/OrderDeliveryMethod';
import { LifelineEnrollmentType } from '@modules/customer/constants/lifeline-enrollment-type.enum';
import { UsaStates } from '@shared/constants/usa-states.enum';
import { CustomerUtils } from '@core/utils/customer-utils';
import { StatusUtils } from '@core/utils/status.utils';

export interface CustomerStateModel {
	currentCustomer: Customer | null;
	onboardingStatus: CustomerOnboardingStatus;
	status: OrderStatusGeneral;

	customers: CustomerListViewModel[];
	deviceTypes: PaymentDeviceDto[];
	receivedPhoneWithinLastSixMonth: boolean;
	totalCustomersListCount: number;
	documentUrl: string | null;

	zipProgramInfoErrors: string[];
	zipInfo: ZipLookupResult | null;

	programStatus: ZipProgramStatus | null;
	eligibilityPrograms: EligibilityProgram[] | null;
	income: EligibilityIncome[];
	handoverActivationStatus: RequestStatus;

	address: AddressStateModel;
	mailAddress: AddressStateModel;
	duplicateEligibilityInfo: boolean;
}

const defaults: CustomerStateModel = {
	currentCustomer: null,
	onboardingStatus: CustomerOnboardingStatus.Created,
	status: OrderStatusGeneral.InProgress,
	customers: [],
	deviceTypes: [],
	receivedPhoneWithinLastSixMonth: false,
	totalCustomersListCount: 0,
	documentUrl: null,
	zipProgramInfoErrors: [],
	zipInfo: null,
	programStatus: null,
	eligibilityPrograms: null,
	income: [],
	handoverActivationStatus: RequestStatus.None,

	address: {
		validation: null,
		tribalValidation: null
	},
	mailAddress: {
		validation: null
	},
	duplicateEligibilityInfo: false
};

//TODO: NEED TO REFACTOR STATE AND CREATE THE NECESSARY SUB-STATES OR USING THE CURRENT ONES
@State<CustomerStateModel>({
	name: 'customer',
	defaults: defaults,
	children: [OrderErrorsState, PaymentMethodsState]
})
@Injectable()
export class CustomerState extends BaseState {
	constructor(
		store: Store,
		private customerService: CustomerService,
		private eligibilityProgramService: EligibilityProgramService,
		private zipProgramStatusService: ZipService,
		private tribalService: TribalService,
		private middlewareService: MiddlewareService,
		private translateService: TranslateService
	) {
		super(store);
	}

	@Selector()
	static state(state: CustomerStateModel): CustomerStateModel {
		return state;
	}

	@Selector()
	static handoverActivationStatus(state: CustomerStateModel): RequestStatus {
		return state.handoverActivationStatus;
	}

	@Selector()
	static zipInfo(state: CustomerStateModel): ZipLookupResult | null {
		return state.zipInfo;
	}

	@Selector()
	static zipInfoErrors(state: CustomerStateModel): string[] {
		return state.zipProgramInfoErrors;
	}

	@Selector()
	static programStatus(state: CustomerStateModel): ZipProgramStatus | null {
		return state.programStatus;
	}

	@Selector()
	static deviceTypes(state: CustomerStateModel): PaymentDeviceDto[] {
		return state.deviceTypes;
	}

	@Selector()
	static receivedPhoneWithinLastSixMonth(state: CustomerStateModel): boolean {
		return state.receivedPhoneWithinLastSixMonth;
	}

	@Selector()
	static eligibilityPrograms(state: CustomerStateModel): EligibilityProgram[] | null {
		return state.eligibilityPrograms;
	}

	@Selector()
	static lowIncomePrograms(state: CustomerStateModel): EligibilityIncome[] {
		return state.income;
	}

	@Selector()
	static currentCustomer(state: CustomerStateModel): Customer | null {
		return state.currentCustomer;
	}

	@Selector()
	static canReprocessCurrentCustomer(state: CustomerStateModel): boolean {
		return (
			StatusUtils.OrderStatusIsReprocessable(state.currentCustomer?.status) ||
			StatusUtils.OrderStatusIsReprocessable(state.status)
		);
	}

	@Selector()
	static onboardingStatus(state: CustomerStateModel): CustomerOnboardingStatus {
		return state.onboardingStatus;
	}

	@Selector()
	static status({ status }: CustomerStateModel): OrderStatusGeneral {
		return status;
	}

	@Selector()
	static canEditInfoRequiredForNv(state: CustomerStateModel): boolean {
		return (
			state.onboardingStatus === CustomerOnboardingStatus.Created ||
			state.onboardingStatus === CustomerOnboardingStatus.CheckedEligibility
		);
	}

	@Selector()
	static customers(state: CustomerStateModel): CustomerListViewModel[] | null {
		return state.customers;
	}

	@Selector()
	static totalCustomersListCount(state: CustomerStateModel): number {
		return state.totalCustomersListCount;
	}

	@Selector()
	static maxSipCustomerExists(state: CustomerStateModel): boolean | null {
		return state.currentCustomer?.existingAcpCustomer ?? null;
	}

	@Selector()
	static address(state: CustomerStateModel) {
		return state.address;
	}

	@Selector()
	static addressValidationResult(state: CustomerStateModel) {
		return state.address.validation;
	}

	@Selector()
	static mailAddress(state: CustomerStateModel) {
		return state.mailAddress;
	}

	@Selector()
	static mailAddressValidationResult(state: CustomerStateModel) {
		return state.mailAddress.validation;
	}

	@Selector()
	static duplicateEligibilityInfo(state: CustomerStateModel): boolean {
		return state.duplicateEligibilityInfo;
	}

	@Selector()
	static hasAddressError({ address, mailAddress }: CustomerStateModel): boolean {
		if (address.validation) {
			return !address.validation.validAddress || !address.validation.validApt;
		}

		if (mailAddress.validation) {
			return !mailAddress.validation.validAddress || !mailAddress.validation.validApt;
		}

		return false;
	}

	@Selector()
	static texasLifelineEnrollment(state: CustomerStateModel): boolean {
		return CustomerUtils.isTexasLifelineEnrollment(state.currentCustomer!);
	}

	@Selector([AccountState])
	static availableDeliveryMethods(
		state: CustomerStateModel,
		accountState: AccountStateModel
	): OrderDeliveryMethod[] {
		return CustomerUtils.isTexasLifelineEnrollment(state.currentCustomer!)
			? [OrderDeliveryMethod.Shipment & accountState.accountInfo!.orderDeliveryMethods]
			: orderDeliveryMethodToArray(accountState.accountInfo!.orderDeliveryMethods);
	}

	@Action(ValidateTribal)
	validateTribal(ctx: StateContext<CustomerStateModel>, { location }: ValidateTribal) {
		return this.tribalService
			.validateTribal(location)
			.pipe(
				map(response =>
					ctx.setState(patch({ address: patch({ tribalValidation: response.data }) }))
				)
			);
	}

	@Action(SetValidateTribal)
	resetValidateTribal(
		ctx: StateContext<CustomerStateModel>,
		{ value }: CustomerActions.SetValidateTribal
	) {
		return ctx.setState(patch({ address: patch({ tribalValidation: value }) }));
	}

	@Action(CustomerActions.ResetAddressValidationResult)
	resetAddressValidationResult(
		ctx: StateContext<CustomerStateModel>,
		{ mail }: CustomerActions.ResetAddressValidationResult
	) {
		if (mail) {
			return ctx.setState(patch({ mailAddress: patch({ validation: null }) }));
		}
		return ctx.setState(patch({ address: patch({ validation: null }) }));
	}

	@Action(ValidateAddress)
	validateAddress(
		ctx: StateContext<CustomerStateModel>,
		{ address, mailAddress, customerId }: ValidateAddress
	) {
		return this.middlewareService.validateAddress(address, mailAddress, customerId).pipe(
			tap(({ succeeded, data, message }) => {
				if (!succeeded) {
					return;
				}

				if (message) {
					this.store.dispatch(
						new ShowErrorDialog(this.getExceptionMessageByCode(message))
					);
				}

				ctx.setState(
					patch({
						duplicateEligibilityInfo: false,
						address: patch({
							validation: data.addressValidationResult
						}),
						mailAddress: patch({
							validation: data.mailAddressValidationResult
						})
					})
				);
			}),
			catchError((errorResponse: HttpErrorResponse) => {
				this.handleMiddlewareError(errorResponse);
				const errorKey = Errors.Middleware.getErrorCode(errorResponse);

				const duplicateEligibility =
					!!errorKey &&
					Errors.Middleware.dublicateEligibilityOrBqpErrors.includes(errorKey);

				ctx.patchState({
					duplicateEligibilityInfo: duplicateEligibility
				});

				if (duplicateEligibility) {
					return EMPTY;
				}

				if (this.isMailAddressError(errorResponse)) {
					ctx.setState(
						patch({
							mailAddress: patch({
								validation: {
									validAddress: false
								}
							})
						})
					);
				} else {
					ctx.setState(
						patch({
							address: patch({
								validation: {
									validAddress: false
								}
							})
						})
					);
				}

				return EMPTY;
			})
		);
	}

	@Action(CustomerActions.GetProgramStatus)
	getProgramStatus(
		{ getState, patchState }: StateContext<CustomerStateModel>,
		{ zip }: CustomerActions.GetProgramStatus
	) {
		return this.zipProgramStatusService.getProgramStatus(zip).pipe(
			tap(response => {
				const { succeeded, data } = response;
				if (succeeded) {
					patchState({
						programStatus: { ...data, zip }
					});
				} else {
					const state = getState();

					patchState({
						zipProgramInfoErrors: [
							...state.zipProgramInfoErrors,
							'Customer.Errors.ErrorWhileSearchingZip'
						]
					});
				}
			})
		);
	}

	@Action(GetEligibilityPrograms)
	getEligibilityDocuments(
		ctx: StateContext<CustomerStateModel>,
		{ zip, lifelineEnrollmentType, tribalEnabled }: GetEligibilityPrograms
	) {
		return this.eligibilityProgramService
			.getEligibilityPrograms(zip, lifelineEnrollmentType, tribalEnabled)
			.pipe(
				tap(response => {
					const { succeeded, data } = response;
					if (succeeded) {
						ctx.patchState({
							eligibilityPrograms: data.programs,
							income: data.income
						});
					}
				}),
				catchError((errorResponse: HttpErrorResponse) => {
					this.handleMiddlewareError(errorResponse);

					return EMPTY;
				})
			);
	}

	@Action(ResetCurrentCustomer)
	resetCurrentCustomer(ctx: StateContext<CustomerStateModel>) {
		ctx.patchState({
			currentCustomer: null
		});
	}

	@Action(GetCustomerById)
	getCustomerById(ctx: StateContext<CustomerStateModel>, { customerId }: GetCustomerById) {
		return this.customerService.getCustomerById(customerId).pipe(
			tap(response => {
				const { succeeded, data } = response;
				if (succeeded) {
					ctx.patchState({
						currentCustomer: data
					});
				} else {
					ctx.dispatch(
						new ShowErrorMessage(
							this.translateService.instant('CommonErrors.TryAgainLater')
						)
					);
				}
			})
		);
	}

	@Action(AddCustomer, { cancelUncompleted: true })
	addCustomer(ctx: StateContext<CustomerStateModel>, action: AddCustomer) {
		return this.customerService.addCustomer(action.customer).pipe(
			tap(response => {
				const { succeeded, data, errorCodes } = response;
				if (succeeded) {
					ctx.patchState({
						currentCustomer: data
					});

					return;
				}
				if (
					errorCodes.length > 0 &&
					errorCodes[0] === OrderErrors.emailOrPhoneIsNotVerified
				) {
					this.store.dispatch(
						new DialogActions.ShowErrorDialog(
							this.translateService.instant(
								OrderErrors.getErrorMessageKey(errorCodes[0])
							)
						)
					);
					return;
				}

				ctx.dispatch(
					new ShowErrorMessage(
						this.translateService.instant('CommonErrors.TryAgainLater')
					)
				);
			})
		);
	}

	@Action(UpdateCustomer, { cancelUncompleted: true })
	updateCustomer(ctx: StateContext<CustomerStateModel>, action: UpdateCustomer) {
		return this.customerService.updateCustomer(action.customer).pipe(
			tap(response => {
				const { succeeded, data } = response;
				if (succeeded) {
					ctx.patchState({
						currentCustomer: data,
						onboardingStatus: data.onboardingStatus
					});
				} else {
					ctx.dispatch(
						new ShowErrorMessage(
							this.translateService.instant('CommonErrors.TryAgainLater')
						)
					);
				}
			}),
			catchError((errorResponse: HttpErrorResponse) => {
				this.handleMiddlewareError(errorResponse);

				return EMPTY;
			})
		);
	}

	@Action(CustomerActions.UpdateDevicePlan, { cancelUncompleted: true })
	updateDevicePlan(
		ctx: StateContext<CustomerStateModel>,
		{ deviceType, deliveryMethod }: CustomerActions.UpdateDevicePlan
	) {
		const stateModel = ctx.getState();

		return this.customerService
			.updateDevicePlan({
				id: stateModel.currentCustomer!.id!,
				deviceType,
				deliveryMethod
			})
			.pipe(
				tap(({ succeeded }) => {
					if (succeeded) {
						ctx.patchState({
							currentCustomer: produce(stateModel.currentCustomer, draft => {
								draft!.deviceType = deviceType;
								draft!.delivery = { deliveryMethod, imei: null };
							})
						});
						return;
					}

					ctx.dispatch(
						new ShowErrorMessage(
							this.translateService.instant('CommonErrors.TryAgainLater')
						)
					);
				}),
				catchError((errorResponse: HttpErrorResponse) => {
					this.handleMiddlewareError(errorResponse);

					return EMPTY;
				})
			);
	}

	@Action(CustomerActions.ResumeOnboarding, { cancelUncompleted: true })
	resumeOnboarding(
		ctx: StateContext<CustomerStateModel>,
		{ customerId, destroyRef }: CustomerActions.ResumeOnboarding
	) {
		return this.customerService.resumeOnboarding(customerId).pipe(
			tap(response => {
				const { succeeded, data } = response;
				if (succeeded && data.customer) {
					ctx.patchState({
						currentCustomer: data.customer,
						status: data.customer.status
					});

					const { emailVerificationStatus, emailVerificationId, phoneVerified, email } =
						data.customer.contactInfo;

					this.store.dispatch(
						new PhoneVerificationActions.SetPhoneVerified(phoneVerified ?? false)
					);
					this.store.dispatch(
						new EmailVerificationActions.ResetEmailVerificationState({
							emailVerificationStatus:
								emailVerificationStatus ?? EmailVerificationStatus.None,
							emailVerificationId,
							email
						})
					);
				} else if (data.outdatedEnrollment) {
					ctx.dispatch(
						new SnackbarActions.ShowErrorMessage(
							this.translateService.instant('Customer.Errors.OutdatedEnrollment'),
							5
						)
					);
					this.store.dispatch(new Navigate([AbsoluteRoutes.OrdersList]));
				}
			}),
			catchError((errorResponse: HttpErrorResponse) => {
				ctx.dispatch(
					new ShowErrorMessage(
						this.translateService.instant('Customer.Errors.CantResume')
					)
				);
				this.store.dispatch(new Navigate([AbsoluteRoutes.OrdersList]));

				return EMPTY;
			}),
			takeUntilDestroyed(destroyRef)
		);
	}

	@Action(UploadCustomerDocument)
	uploadCustomerDocument(
		ctx: StateContext<CustomerStateModel>,
		{ customerId, file }: UploadCustomerDocument
	) {
		return this.customerService.uploadCustomerDocument(customerId, file).pipe(
			tap(response => {
				const { succeeded, data } = response;
				if (succeeded) {
					ctx.patchState({
						documentUrl: data
					});
				} else {
					ctx.dispatch(
						new ShowErrorMessage(
							this.translateService.instant(
								'UploadDocumentError.Errors.UploadDocumentError'
							)
						)
					);
				}
			})
		);
	}

	@Action(CancelOnboarding)
	cancelOnboarding(ctx: StateContext<CustomerStateModel>, action: CancelOnboarding) {
		return this.customerService.cancelOnboarding(action.cancelCommand).pipe(
			tap(response => {
				const { succeeded, data } = response;
				if (succeeded) {
					ctx.patchState({
						status: OrderStatusGeneral.Cancelled
					});

					this.store.dispatch(new Navigate([AbsoluteRoutes.OrdersList]));
				} else {
					ctx.dispatch(
						new ShowErrorMessage(
							this.translateService.instant('CommonErrors.TryAgainLater')
						)
					);
				}
			})
		);
	}

	@Action(CustomerActions.ExitOnboarding)
	exitOnboarding(ctx: StateContext<CustomerStateModel>, action: CustomerActions.ExitOnboarding) {
		return this.customerService.exitOnboarding(action.command).pipe(
			tap(({ succeeded }) => {
				if (succeeded) {
					ctx.patchState({
						status: OrderStatusGeneral.OnHold
					});
				} else {
					ctx.dispatch(
						new ShowErrorMessage(
							this.translateService.instant('CommonErrors.TryAgainLater')
						)
					);
				}
			})
		);
	}

	@Action(CustomerActions.Reprocess)
	reprocess(
		ctx: StateContext<CustomerStateModel>,
		{ enrollmentType }: CustomerActions.Reprocess
	) {
		const customerId = ctx.getState().currentCustomer?.id;

		if (isNullOrUndefined(customerId)) {
			throw new TypeError(`customerId value is required.}`);
		}

		return this.customerService.reprocessOnboarding({ id: customerId, enrollmentType }).pipe(
			tap(({ succeeded, data }) => {
				if (!succeeded) {
					ctx.dispatch(
						new ShowErrorMessage(
							this.translateService.instant('CommonErrors.TryAgainLater')
						)
					);
					return;
				}

				ctx.patchState({
					...defaults,
					currentCustomer: data,
					onboardingStatus: data.onboardingStatus,
					status: data.status
				});
			})
		);
	}

	@Action(GetCustomersList)
	getFilteredCustomersList(ctx: StateContext<CustomerStateModel>, { request }: GetCustomersList) {
		return this.customerService.getCustomersList(request).pipe(
			tap(response => {
				const { succeeded, totalCount, data } = response;
				if (succeeded) {
					ctx.patchState({
						customers: data,
						totalCustomersListCount: totalCount
					});
				}
			})
		);
	}

	@Action(ActivateHandover)
	activateHandover(
		{ getState, patchState, dispatch }: StateContext<CustomerStateModel>,
		{ handoverActivationCommand }: ActivateHandover
	) {
		return this.middlewareService.activateHandover(handoverActivationCommand).pipe(
			tap(({ succeeded, data, message, errorCodes }) => {
				if (!succeeded) {
					if (errorCodes?.includes(OrderErrors.paymentError)) {
						dispatch(
							new DialogActions.ShowErrorDialog(
								message ??
									this.translateService.instant(
										OrderErrors.getErrorMessageKey(OrderErrors.paymentError)
									)
							)
						);
						return;
					}

					dispatch(
						new ShowErrorMessage(
							this.translateService.instant('Middleware.Errors.ActivateHandover')
						)
					);
					return;
				}

				const state = getState();

				patchState({
					currentCustomer: produce(state.currentCustomer, draft => {
						if (!draft) {
							return;
						}

						draft.mdn = data.mdn;
						draft.currentStep = OnboardingStep.AssignDevice;

						if (!draft.delivery) {
							return;
						}

						draft.delivery.imei = data.imei;
					}),
					handoverActivationStatus: RequestStatus.Success
				});
			}),
			catchError((errorResponse: HttpErrorResponse) => {
				patchState({
					handoverActivationStatus: RequestStatus.Error
				});

				const errorCode = Errors.Middleware.getErrorCode(errorResponse);
				if (errorCode !== Errors.Middleware.invalidZip) {
					this.handleMiddlewareError(errorResponse);
				}

				return throwError(() => errorResponse);
			})
		);
	}

	@Action(UpdateOnboardingStatus)
	updateOnboardingStatus(
		{ patchState }: StateContext<CustomerStateModel>,
		{ status }: UpdateOnboardingStatus
	) {
		return patchState({
			onboardingStatus: status
		});
	}

	@Action(CustomerActions.UpdateOrderStatus)
	UpdateOrderStatus(
		{ patchState }: StateContext<CustomerStateModel>,
		{ status }: CustomerActions.UpdateOrderStatus
	) {
		return patchState({
			status
		});
	}

	@Action(CustomerActions.SetEnrollmentId)
	setEnrollmentId(
		ctx: StateContext<CustomerStateModel>,
		{ enrollmentId }: CustomerActions.SetEnrollmentId
	) {
		const currentCustomer = ctx.getState().currentCustomer;

		ctx.patchState({
			currentCustomer: {
				...currentCustomer!,
				enrollmentId
			}
		});
	}

	@Action(CustomerActions.GetDeviceTypes)
	getDeviceTypes(ctx: StateContext<CustomerStateModel>) {
		const customer = CustomerState.currentCustomer(ctx.getState());

		return this.customerService.getDeviceTypes(customer!.id!).pipe(
			tap(response => {
				const { data, succeeded } = response;

				if (succeeded) {
					ctx.patchState({
						deviceTypes: data
					});
					return;
				}

				ctx.patchState({
					deviceTypes: defaults.deviceTypes
				});
			})
		);
	}

	@Action(CustomerActions.UpdateIneligibleForTransfer, { cancelUncompleted: true })
	markAsIneligibleForTransfer(
		ctx: StateContext<CustomerStateModel>,
		{
			ineligibleForTransfer,
			ineligibleForTransferError
		}: CustomerActions.UpdateIneligibleForTransfer
	) {
		const state = ctx.getState();

		return ctx.patchState({
			currentCustomer: {
				...state.currentCustomer!,
				ineligibleForTransfer,
				ineligibleForTransferError
			}
		});
	}
}
