import { HttpErrorResponse } from '@angular/common/http';
import { ErrorResponse } from '@core/interfaces/error-response';
import {
	isArrayNotNullOrEmpty,
	isArrayNullOrEmpty,
	isNullOrUndefined,
	newlineSymbol,
	stringEmpty
} from '@core/utils/utils';

export function parseErrorToString(response: HttpErrorResponse): string {
	const errorResponse = response.error as ErrorResponse;
	if (isNullOrUndefined(errorResponse)) {
		return stringEmpty;
	}

	const { errors, failures } = errorResponse?.responseException?.exceptionMessage ?? {};
	if (isArrayNullOrEmpty(errors) && isArrayNullOrEmpty(failures)) {
		return stringEmpty;
	}

	const errorsString =
		!isArrayNullOrEmpty(errors) && Array.isArray(errors)
			? errors.join(newlineSymbol)
			: stringEmpty;
	const failuresString =
		!isArrayNullOrEmpty(failures) && Array.isArray(failures)
			? failures.join(newlineSymbol)
			: stringEmpty;

	return `${errorsString}\n${failuresString}`;

	//TODO: SHOW ALL ERRORS FOR NOW
	// const separatedErrors = errorString.split(':-');
	// if (separatedErrors.length > 1) {
	// 	return separatedErrors[separatedErrors.length - 1];
	// }
}

export function httpErrorResponseIncludesError(
	response: HttpErrorResponse,
	error: string
): boolean {
	const errorResponse = response.error as ErrorResponse;
	if (isNullOrUndefined(errorResponse)) {
		return false;
	}

	const exceptionMessage = errorResponse?.responseException?.exceptionMessage;
	return exceptionMessage?.errorCodes?.includes(error);
}

export function getHttpErrorResponseErrorCode(response: HttpErrorResponse): string | null {
	const errorResponse = response.error as ErrorResponse;
	if (isNullOrUndefined(errorResponse)) {
		return null;
	}

	const exceptionMessage = errorResponse?.responseException?.exceptionMessage;
	if (isArrayNotNullOrEmpty(exceptionMessage?.errorCodes)) {
		return exceptionMessage?.errorCodes[0];
	}

	return null;
}
