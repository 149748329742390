import { Injectable, inject } from '@angular/core';
import {
	OrderErrorCode,
	OrderErrorCodeToMessagesMap
} from '@core/models/order-error-code';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { OrderErrorsActions } from './order-errors.actions';
import { CustomerService } from '@modules/customer/services/customer.service';
import { tap } from 'rxjs';

interface OrderErrorsStateModel {
	errors: OrderErrorCodeToMessagesMap;
}

const defaults: OrderErrorsStateModel = {
	errors: {
		[OrderErrorCode.UpdateEnrollment]: [],
		[OrderErrorCode.ValidateAddress]: [],
		[OrderErrorCode.CheckEligibility]: [],
		[OrderErrorCode.EligibilityStatusCheck]: [],
		[OrderErrorCode.CreateLifeline]: [],
		[OrderErrorCode.HandoverActivation]: [],
		[OrderErrorCode.MakePayment]: [],
		[OrderErrorCode.PaymentDetails]: [],
		[OrderErrorCode.NladVerify]: [],
		[OrderErrorCode.TransferAcp]: []
	}
};

@State<OrderErrorsStateModel>({
	name: 'errors',
	defaults: defaults
})
@Injectable()
export class OrderErrorsState {
	private readonly customerService = inject(CustomerService);

	private readonly allErrorCodes = [
		OrderErrorCode.UpdateEnrollment,
		OrderErrorCode.ValidateAddress,
		OrderErrorCode.CheckEligibility,
		OrderErrorCode.EligibilityStatusCheck,
		OrderErrorCode.NladVerify,
		OrderErrorCode.PaymentDetails,
		OrderErrorCode.MakePayment,
		OrderErrorCode.CreateLifeline,
		OrderErrorCode.HandoverActivation,
		OrderErrorCode.TransferAcp
	];

	@Selector([OrderErrorsState])
	static errors(state: OrderErrorsStateModel) {
		return state.errors;
	}

	@Action(OrderErrorsActions.Get)
	get(
		{ patchState, getState }: StateContext<OrderErrorsStateModel>,
		{ orderId, codes }: OrderErrorsActions.Get
	) {
		const errors = { ...getState().errors };

		codes = !codes ? this.allErrorCodes : codes;

		return this.customerService.getErrors(orderId, codes).pipe(
			tap(response => {
				response.data.forEach(
					errorDto => (errors[errorDto.code] = errorDto.errorMessages)
				);

				patchState({
					errors: errors
				});
			})
		);
	}
}
