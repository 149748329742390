import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { EmployeesActions } from './employees.actions';

import { EmployeesService } from '../../services/employees.service';
import { EMPTY, filter, tap } from 'rxjs';
import { produce } from 'immer';

import { CarrierCode } from '../../models/carrier-code';
import {
	AddedEmployeeViewModel,
	Agent,
	Employee,
	EmployeeSignupRequest,
	MvnoMetadata
} from '../../models/employee';
import { SignupRequestStatus } from '@modules/employees/pages/employees/employee.type';
import { SnackbarActions, SupportMessages } from '@store/snackbar/snackbar.actions';
import { catchError } from 'rxjs/operators';
import { SupportErrorMessage } from '@core/utils/constants';
import { ImportBulkEmployeesViewModel } from '@core/models/import';
import { HttpErrorResponse } from '@angular/common/http';
import AddEmployee = EmployeesActions.AddEmployee;
import GetCarrierCodes = EmployeesActions.GetCarrierCodes;
import UpdateEmployee = EmployeesActions.UpdateEmployee;
import InviteEmployee = EmployeesActions.InviteEmployee;
import GetEmployeesList = EmployeesActions.GetEmployeesList;
import GetEmployeesRequests = EmployeesActions.GetEmployeesRequests;
import ApproveSignupRequest = EmployeesActions.ApproveSignupRequest;
import DeclineSignupRequest = EmployeesActions.DeclineSignupRequest;
import ShowMessage = SnackbarActions.ShowMessage;
import GetMvnoSettings = EmployeesActions.GetMvnoMetadata;
import GetAgentsList = EmployeesActions.GetAgentsList;
import GetAgentsByClecId = EmployeesActions.GetAgentsByClecId;

import ImportEmployeesFile = EmployeesActions.ImportEmployeesFile;
import ResetImportEmployeesFile = EmployeesActions.ResetImportEmployeesFile;

export interface EmployeesStateModel {
	agents: Agent[];
	employees: Employee[];
	totalEmployeesCount: number;
	carrierCodes: CarrierCode[];
	mvnoMetadata: MvnoMetadata[];
	signUpRequests: EmployeeSignupRequest[];
	totalSignUpRequestsCount: number;
	lastAddedEmployee: AddedEmployeeViewModel | null;
	lastImported: ImportBulkEmployeesViewModel[] | null;
	importedPasswordsFile: string | null;
}

@State<EmployeesStateModel>({
	name: 'employees',
	defaults: {
		agents: [],
		employees: [],
		totalEmployeesCount: 0,
		carrierCodes: [],
		mvnoMetadata: [],
		signUpRequests: [],
		lastAddedEmployee: null,
		totalSignUpRequestsCount: 0,
		lastImported: null,
		importedPasswordsFile: null
	}
})
@Injectable()
export class EmployeesState {
	@Selector()
	static carrierCodes(state: EmployeesStateModel): CarrierCode[] {
		return state.carrierCodes;
	}

	@Selector()
	static mvnoMetadata(state: EmployeesStateModel): MvnoMetadata[] {
		return state.mvnoMetadata;
	}

	@Selector()
	static employees(state: EmployeesStateModel): Employee[] {
		return state.employees;
	}

	@Selector()
	static totalEmployees(state: EmployeesStateModel) {
		return state.totalEmployeesCount;
	}

	@Selector()
	static agents(state: EmployeesStateModel): Agent[] {
		return state.agents;
	}

	@Selector()
	static lastImportedBulkStats(
		state: EmployeesStateModel
	): ImportBulkEmployeesViewModel[] | null {
		return state?.lastImported;
	}

	@Selector()
	static lastImportedPasswords(state: EmployeesStateModel): string | null {
		return state?.importedPasswordsFile;
	}

	@Selector()
	static carrierCodesTotalCounts({ carrierCodes }: EmployeesStateModel): number {
		return carrierCodes.length ?? 0;
	}

	@Selector()
	static signUpRequests(state: EmployeesStateModel): EmployeeSignupRequest[] {
		return state.signUpRequests;
	}

	@Selector()
	static totalRequests(state: EmployeesStateModel): number {
		return state.totalSignUpRequestsCount;
	}

	@Selector()
	static signUpRequestsCount(state: EmployeesStateModel): number {
		return state.signUpRequests.filter(
			request => request.status === SignupRequestStatus.Pending
		).length;
	}

	constructor(
		private employeesService: EmployeesService,
		private readonly store: Store
	) {}

	@Action(GetEmployeesList)
	getEmployeesList(ctx: StateContext<EmployeesStateModel>, { payload }: GetEmployeesList) {
		return this.employeesService.list(payload).pipe(
			tap(({ succeeded, data, totalCount }) => {
				if (succeeded && data) {
					ctx.patchState({
						employees: [...data],
						totalEmployeesCount: totalCount
					});
				}
			})
		);
	}

	@Action(GetEmployeesRequests)
	getSignUpRequests(
		ctx: StateContext<EmployeesStateModel>,
		{ payload }: EmployeesActions.GetEmployeesRequests
	) {
		return this.employeesService.getSignupRequests(payload).pipe(
			filter(response => response.succeeded),
			tap(response => {
				ctx.patchState({
					signUpRequests: response.data,
					totalSignUpRequestsCount: response.totalCount
				});
			})
		);
	}

	@Action(AddEmployee)
	addEmployee(ctx: StateContext<EmployeesStateModel>, { employeeViewModel }: AddEmployee) {
		const state = ctx.getState();

		return this.employeesService.add(employeeViewModel).pipe(
			tap(response => {
				const { succeeded, data, message } = response;
				if (succeeded) {
					ctx.patchState({
						lastAddedEmployee: data,
						employees: [...state.employees, data.employee]
					});
					return;
				}

				ctx.dispatch(new SnackbarActions.ShowErrorMessage(message ?? SupportErrorMessage));
			}),
			catchError(() => {
				ctx.dispatch(new SnackbarActions.ShowErrorMessage(SupportErrorMessage));
				return EMPTY;
			})
		);
	}

	@Action(UpdateEmployee)
	updateEmployee(ctx: StateContext<EmployeesStateModel>, { employeeViewModel }: UpdateEmployee) {
		const state = ctx.getState();

		const employeeIdx = state.employees.findIndex(x => x.email === employeeViewModel.email)!;
		const { authUserId, id } = state.employees[employeeIdx];
		const viewModel = {
			...employeeViewModel,
			id,
			authUserId
		};

		return this.employeesService.update(viewModel).pipe(
			tap(response => {
				const { succeeded, data } = response;
				if (succeeded) {
					ctx.patchState(
						produce(state, draft => {
							draft.employees[employeeIdx] = {
								...draft.employees[employeeIdx],
								...data,
								role: employeeViewModel.role
							};
						})
					);
				}
			})
		);
	}

	@Action(InviteEmployee)
	inviteEmployee(ctx: StateContext<EmployeesStateModel>, { employeeViewModel }: InviteEmployee) {
		const state = ctx.getState();

		return this.employeesService.invite(employeeViewModel).pipe(
			tap(response => {
				const { succeeded, data } = response;
				if (succeeded) {
					ctx.patchState({
						signUpRequests: [...state.signUpRequests, data]
					});
				}
			})
		);
	}

	@Action(GetCarrierCodes)
	getCarrierCodes(ctx: StateContext<EmployeesStateModel>) {
		return this.employeesService.getCarrierCodes().pipe(
			tap(response => {
				const { succeeded, data } = response;
				if (succeeded) {
					ctx.patchState({
						carrierCodes: data
					});
				}
			})
		);
	}

	@Action(GetMvnoSettings)
	getMvnoMetadata(ctx: StateContext<EmployeesStateModel>) {
		return this.employeesService.getMvnoMetadata().pipe(
			tap(response => {
				const { succeeded, data } = response;
				if (succeeded) {
					ctx.patchState({
						mvnoMetadata: data
					});
				}
			})
		);
	}

	@Action(ApproveSignupRequest)
	approveSignupRequest(
		ctx: StateContext<EmployeesStateModel>,
		{ payload }: ApproveSignupRequest
	) {
		const state = ctx.getState();
		const signupRequestIdx = state.signUpRequests.findIndex(x => x.id === payload.id)!;

		return this.employeesService.approveSignupRequest(payload).pipe(
			tap(({ succeeded, data }) => {
				if (succeeded) {
					ctx.patchState(
						produce(state, draft => {
							draft.signUpRequests.splice(
								signupRequestIdx,
								1,
								data as EmployeeSignupRequest
							);
						})
					);
					ctx.dispatch(new ShowMessage('Signup request approved'));
					return;
				}

				ctx.dispatch(new ShowMessage(SupportMessages.UnhandledError));
			})
		);
	}

	@Action(DeclineSignupRequest)
	declineSignupRequest(
		ctx: StateContext<EmployeesStateModel>,
		{ payload }: DeclineSignupRequest
	) {
		const state = ctx.getState();
		const signupRequestIdx = state.signUpRequests.findIndex(x => x.id === payload.id)!;

		return this.employeesService.declineSignupRequest(payload).pipe(
			tap(({ succeeded, data }) => {
				if (succeeded) {
					ctx.patchState(
						produce(state, draft => {
							draft.signUpRequests.splice(
								signupRequestIdx,
								1,
								data as EmployeeSignupRequest
							);
						})
					);
					ctx.dispatch(new ShowMessage('Signup request declined'));
					return;
				}

				ctx.dispatch(new ShowMessage(SupportMessages.UnhandledError));
			})
		);
	}

	@Action(GetAgentsList)
	getAgentsList(ctx: StateContext<EmployeesStateModel>, { search }: GetAgentsList) {
		return this.employeesService.agentsList(search).pipe(
			tap(({ succeeded, data }) => {
				if (succeeded) {
					ctx.patchState({
						agents: [...data]
					});
				}
			})
		);
	}

	@Action(ImportEmployeesFile)
	importEmployeesFile(ctx: StateContext<EmployeesStateModel>, { file }: ImportEmployeesFile) {
		return this.employeesService.importDataFile(file).pipe(
			tap(({ succeeded, data }) => {
				if (succeeded) {
					ctx.patchState({
						lastImported: data.items,
						importedPasswordsFile: data.passwordsFile
					});
				}
			}),
			catchError((errorResponse: HttpErrorResponse) => {
				ctx.patchState({
					lastImported: null
				});
				this.store.dispatch(new SnackbarActions.ShowErrorMessage(errorResponse.message));
				return EMPTY;
			})
		);
	}

	@Action(ResetImportEmployeesFile)
	resetImportEmployeesFile(ctx: StateContext<EmployeesStateModel>) {
		return ctx.patchState({
			lastImported: null
		});
	}

	@Action(GetAgentsByClecId)
	getAgentsListByClecId(
		ctx: StateContext<EmployeesStateModel>,
		{ associatedClecId }: GetAgentsByClecId
	) {
		return this.employeesService.agentsListByClecId(associatedClecId).pipe(
			tap(({ succeeded, data }) => {
				if (succeeded) {
					ctx.patchState({
						agents: [...data]
					});
				}
			})
		);
	}
}
