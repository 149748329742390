import { Injectable } from '@angular/core';
import { protectedResources } from '../msal/msal.config';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { GetEnrollmentLinksRequest } from '@core/models/enrollments';
import { EnrollmentLinksApiMethods } from '@core/utils/api/enrollment-links-api-methods';
import { ApiResponse, PaginatedApiResponse } from '@core/interfaces/api-response.type';
import {
	CreateEnrollmentLinkCommand,
	EnrollmentLinkViewModel
} from '@core/api-client/data-contracts';

@Injectable({
	providedIn: 'root'
})
export class EnrollmentLinksService {
	url = protectedResources.api.endpoint;

	constructor(private http: HttpClient) {}

	public create(
		body: CreateEnrollmentLinkCommand
	): Observable<ApiResponse<EnrollmentLinkViewModel>> {
		return this.http.post<ApiResponse<EnrollmentLinkViewModel>>(
			EnrollmentLinksApiMethods.create(),
			body
		);
	}

	public getEnrollmentLinks(
		request: GetEnrollmentLinksRequest
	): Observable<PaginatedApiResponse<EnrollmentLinkViewModel[]>> {
		return this.http.post<PaginatedApiResponse<EnrollmentLinkViewModel[]>>(
			EnrollmentLinksApiMethods.list(),
			request
		);
	}

	public getEnrollmentLink(path: string): Observable<ApiResponse<EnrollmentLinkViewModel>> {
		return this.http.get<ApiResponse<EnrollmentLinkViewModel>>(
			EnrollmentLinksApiMethods.get(path)
		);
	}

	public changeEnrollmentLinkAvailability(payload: {
		id: string;
		enabled: boolean;
	}): Observable<ApiResponse<EnrollmentLinkViewModel>> {
		return this.http.post<ApiResponse<EnrollmentLinkViewModel>>(
			EnrollmentLinksApiMethods.availability(),
			payload
		);
	}
}
