import { HttpErrorResponse } from '@angular/common/http';
import { httpErrorResponseIncludesError } from '@core/utils/http-error-response';
import { OrderErrors } from './order';
import { CommonErrors } from './common';

export namespace Errors {
	export class Middleware {
		static readonly unknown = 'Middleware.Unknown';

		static readonly nationalVerifierCompletionIsRequired =
			'NATIONAL_VERIFIER_COMPLETION_IS_REQUIRED';

		static readonly invalidEnrollmentId = 'INVALID_ENROLLMENT_ID';

		static readonly esnDoesNotExist = 'ESN_SIM_NOT_EXIST_IN_INVENTORY';
		static readonly esnAlreadyActivated = 'ESN_SIM_ALREADY_ACTIVE_WITH_ANOTHER';

		static readonly plansNotFound = 'PLANS_NOT_FOUND';

		static readonly unavailableDevice = 'UNAVAILABLE_DEVICE';

		static readonly duplicateSubscriber = 'DUPLICATE_SUBSCRIBER';

		static readonly applicationNotComplete = 'APPLICATION_NOT_COMPLETE';

		static readonly serviceNotAvailableForZip = 'SERVICE_NOT_AVAILABLE_FOR_ZIP';

		static readonly zipInvalidAgentId = 'INVALID_AGENT_ID';

		static readonly invalidProgramCode = 'INVALID_PROGRAM_CODE';

		static readonly deviceIdNotInInventory = 'DEVICE_ID_NOT_IN_INVENTORY';

		static readonly deviceIdAlreadyAssigned = 'DEVICE_ID_ALREADY_ASSIGNED';

		static readonly incompleteCustomerInformation = 'INCOMPLETE_CUSTOMER_INFORMATION';

		static readonly duplicateBqpCustomerExists = 'DUPLICATE_BQP_CUSTOMER_EXISTS';

		static readonly dublicateBqpTerracomWireless = 'DUPLICATE_BQP_TERRACOM_WIRELESS';

		static readonly duplicateEligibilityCustomerExists =
			'DUPLICATE_ELIGIBILITY_CUSTOMER_EXISTS';

		static readonly duplicateEligibilityMaxSipSubscriber =
			'DUPLICATE_ELIGIBILITY_MAXSIP_SUBSCRIBER';

		static readonly duplicateEligibilityTerracomSubscriber =
			'DUPLICATE_ELIGIBILITY_TERRACOM_WIRELESS_SUBSCRIBER';

		static readonly acpApplicationExists = 'ACP_APPLICATION_EXISTS';

		static readonly invalidAddress = 'ADDRESS_NOT_FOUND';

		static readonly invalidMailAddress = 'MAIL_ADDRESS_NOT_FOUND';

		static readonly businessAddressFound = 'BUSINESS_ADDRESS_FOUND';

		static readonly multipleAddresses = 'ADDRESS_MULTIPLE_ADDRESSES';

		static readonly aptNotConfirmed = 'DEFAULT_ADDRESS_DPV_CONFIRMED_PRIMARY_NO_SECONDARY';

		static readonly secondaryAddressRequired = 'SERVICE_ADDRESS_MORE_INFO_NEEDED';

		static readonly mailBusinessAddressFound = 'BUSINESS_MAIL_ADDRESS_FOUND';

		static readonly mailAptNotConfirmed =
			'DEFAULT_MAIL_ADDRESS_DPV_CONFIRMED_PRIMARY_NO_SECONDARY';

		static readonly mailSecondaryAddressRequired = 'MAIL_ADDRESS_MORE_INFO_NEEDED';

		static readonly ineligibleForTransfer = 'INELIGIBLE_FOR_TRANSFER';

		static readonly ineligibleforPortin = 'INELIGIBLE_FOR_PORTIN';

		static readonly ineligibleForHandover = 'ACCOUNT_NOT_ELIGIBLE_FOR_HANDOVER';

		static readonly directionalsOrSuffixMissingInvalid =
			'DIRECTIONALS_OR_SUFFIX_MISSING_INVALID';

		static readonly eligibilityCheckIdNotLinked = 'ELIGIBILITY_CHECKID_NOT_LINKED';

		static readonly annualAgreementDeadlineNotFound = 'ANNUAL_AGREEMENT_DEADLINE_NOT_FOUND';

		static readonly internalErrorFiveMinutesRetry = 'INTERNAL_ERROR_FIVE_MINUTES_RETRY';

		static readonly applicationNotSubmittedRecertificationOpen =
			'APPLICATION_NOT_SUBMITTED_RECERTIFICATION_OPEN';

		static readonly beneficiarySsnIncorrect = 'BENEFICIARY_SSN_INCORRECT_LAST4_DIGITS_REQUIRED';

		static readonly bqpInformationExceptMiddleName =
			'INCOMPLETE_BQP_INFORMATION_EXCEPT_MIDDLENAME';

		static readonly addressMatchingServiceOffline = 'ADDRESS_MATCHING_SERVICE_OFFLINE';

		static readonly enrollmentIdRequired = 'ENROLLMENT_ID_FIELD_REQUIRED';

		static readonly esnIdRequired = 'ESN_ID_REQUIRED';

		static readonly connectionFailedShippingApi = 'CONNECTION_FAILED_PRODUCTION_SHIPPING_API';

		static readonly eligibilityProgramCodeInvalid = 'ELIGIBILITY_PROGRAM_CODE_INVALID';

		static readonly invoiceAlreadyCreatedEnrollmentId = 'INVOICE_ALREADY_CREATED_ENROLLMENTID';

		static readonly zipWasChanged = 'ZIPCODE_CHANGED_OR_ADDED_ADDRESS_NUMBER_INVALID';

		static readonly streetDirectionalChanged =
			'STREET_DIRECTIONAL_CHANGED_SUITE_OR_APARTMENT_MISSING_INVALID_ADDRESS';

		static readonly cardNumberMissing = 'ENTER_CARD_NUMBER';
		static readonly subscriberNotEligibleForPortIn = 'SUBSCRIBER_NOT_ELIGIBLE_FOR_PORTIN';

		static readonly invalidZip = 'INVALID_ZIP';

		private static readonly mailingAddressErrors = [
			Middleware.invalidMailAddress,
			Middleware.mailBusinessAddressFound,
			Middleware.mailAptNotConfirmed,
			Middleware.mailSecondaryAddressRequired
		];

		public static readonly dublicateEligibilityOrBqpErrors = [
			Middleware.duplicateBqpCustomerExists,
			Middleware.dublicateBqpTerracomWireless,
			Middleware.duplicateEligibilityCustomerExists,
			Middleware.duplicateEligibilityMaxSipSubscriber,
			Middleware.duplicateEligibilityTerracomSubscriber
		];

		private static readonly errors = [
			Middleware.unknown,
			Middleware.nationalVerifierCompletionIsRequired,
			Middleware.invalidEnrollmentId,
			Middleware.esnDoesNotExist,
			Middleware.esnAlreadyActivated,
			Middleware.plansNotFound,
			Middleware.duplicateSubscriber,
			Middleware.unavailableDevice,
			Middleware.applicationNotComplete,
			Middleware.serviceNotAvailableForZip,
			Middleware.invalidProgramCode,
			Middleware.deviceIdNotInInventory,
			Middleware.deviceIdAlreadyAssigned,
			Middleware.incompleteCustomerInformation,
			Middleware.duplicateBqpCustomerExists,
			Middleware.dublicateBqpTerracomWireless,
			Middleware.duplicateEligibilityCustomerExists,
			Middleware.duplicateEligibilityMaxSipSubscriber,
			Middleware.duplicateEligibilityTerracomSubscriber,
			Middleware.invalidAddress,
			Middleware.invalidMailAddress,
			Middleware.businessAddressFound,
			Middleware.multipleAddresses,
			Middleware.aptNotConfirmed,
			Middleware.secondaryAddressRequired,
			Middleware.mailBusinessAddressFound,
			Middleware.mailAptNotConfirmed,
			Middleware.mailSecondaryAddressRequired,
			Middleware.acpApplicationExists,
			Middleware.ineligibleForTransfer,
			Middleware.ineligibleForHandover,
			Middleware.directionalsOrSuffixMissingInvalid,
			Middleware.eligibilityCheckIdNotLinked,
			Middleware.annualAgreementDeadlineNotFound,
			Middleware.internalErrorFiveMinutesRetry,
			Middleware.applicationNotSubmittedRecertificationOpen,
			Middleware.beneficiarySsnIncorrect,
			Middleware.bqpInformationExceptMiddleName,
			Middleware.addressMatchingServiceOffline,
			Middleware.enrollmentIdRequired,
			Middleware.esnIdRequired,
			Middleware.connectionFailedShippingApi,
			Middleware.eligibilityProgramCodeInvalid,
			Middleware.invoiceAlreadyCreatedEnrollmentId,
			Middleware.zipWasChanged,
			Middleware.streetDirectionalChanged,
			Middleware.cardNumberMissing,
			Middleware.invalidZip
		] as const;

		private static readonly errorToMessageKey: { [key: string]: string } = {
			[Middleware.unknown]: 'Middleware.Errors.Unknown',
			[Middleware.invalidEnrollmentId]: 'Middleware.Errors.InvalidEnrollmentId',
			[Middleware.nationalVerifierCompletionIsRequired]:
				'Middleware.Errors.NVCompletionIsRequiredText',
			[Middleware.esnDoesNotExist]: 'Middleware.Errors.EsnDoesNotExist',
			[Middleware.esnAlreadyActivated]: 'Middleware.Errors.EsnAlreadyActivated',
			[Middleware.plansNotFound]: 'Middleware.Errors.PlansNotFound',
			[Middleware.duplicateSubscriber]: 'Middleware.Errors.DuplicateSubscriber',
			[Middleware.unavailableDevice]: 'Middleware.Errors.UnavailableDevice',
			[Middleware.applicationNotComplete]: 'Middleware.Errors.ApplicationNotComplete',
			[Middleware.serviceNotAvailableForZip]: 'Middleware.Errors.ServiceNotAvailableForZip',
			[Middleware.invalidProgramCode]: 'Middleware.Errors.InvalidProgramCode',
			[Middleware.deviceIdNotInInventory]: 'Middleware.Errors.DeviceIdNotInInventory',
			[Middleware.deviceIdAlreadyAssigned]: 'Middleware.Errors.DeviceIdAlreadyAssigned',
			[Middleware.incompleteCustomerInformation]:
				'Middleware.Errors.IncompleteCustomerInformation',
			[Middleware.duplicateBqpCustomerExists]:
				'Middleware.Errors.AlreadyExistingActiveCustomer',
			[Middleware.dublicateBqpTerracomWireless]: '',
			[Middleware.duplicateEligibilityCustomerExists]:
				'Middleware.Errors.DuplicateEligibilityInfo',
			[Middleware.duplicateEligibilityMaxSipSubscriber]:
				'Middleware.Errors.DuplicateEligibilityMaxSipSubscriber',
			[Middleware.duplicateEligibilityTerracomSubscriber]:
				'Middleware.Errors.DuplicateEligibilityTerracomSubscriber',
			[Middleware.invalidAddress]: 'Middleware.Errors.InvalidAddress',
			[Middleware.multipleAddresses]: 'Middleware.Errors.MultipleAddresses',
			[Middleware.businessAddressFound]: 'Middleware.Errors.BusinessAddressFound',
			[Middleware.aptNotConfirmed]: 'Middleware.Errors.AptNotConfirmed',
			[Middleware.secondaryAddressRequired]: 'Middleware.Errors.SecondaryAddressRequired',
			[Middleware.invalidMailAddress]: 'Middleware.Errors.InvalidMailAddress',
			[Middleware.mailBusinessAddressFound]: 'Middleware.Errors.MailBusinessAddressFound',
			[Middleware.mailAptNotConfirmed]: 'Middleware.Errors.MailAptNotConfirmed',
			[Middleware.mailSecondaryAddressRequired]:
				'Middleware.Errors.MailSecondaryAddressRequired',
			[Middleware.acpApplicationExists]: 'Middleware.Errors.AcpApplicationExists',
			[Middleware.ineligibleForTransfer]: 'Middleware.Errors.IneligibleForTransfer',
			[Middleware.ineligibleForHandover]: 'Middleware.Errors.IneligibleForHandover',
			[Middleware.directionalsOrSuffixMissingInvalid]:
				'Middleware.Errors.DirectionalsOrSuffixMissingInvalid',
			[Middleware.eligibilityCheckIdNotLinked]:
				'Middleware.Errors.EligibilityCheckIdNotLinked',
			[Middleware.annualAgreementDeadlineNotFound]:
				'Middleware.Errors.AnnualAgreementDeadlineNotFound',
			[Middleware.internalErrorFiveMinutesRetry]:
				'Middleware.Errors.InternalErrorFiveMinutesRetry',
			[Middleware.applicationNotSubmittedRecertificationOpen]:
				'Middleware.Errors.ApplicationNotSubmittedRecertificationOpen',
			[Middleware.beneficiarySsnIncorrect]: 'Middleware.Errors.BeneficiarySsnIncorrect',
			[Middleware.bqpInformationExceptMiddleName]:
				'Middleware.Errors.BqpInformationExceptMiddleName',
			[Middleware.addressMatchingServiceOffline]:
				'Middleware.Errors.AddressMatchingServiceOffline',
			[Middleware.enrollmentIdRequired]: 'Middleware.Errors.EnrollmentIdRequired',
			[Middleware.esnIdRequired]: 'Middleware.Errors.EsnIdRequired',
			[Middleware.connectionFailedShippingApi]:
				'Middleware.Errors.InternalErrorFiveMinutesRetry',
			[Middleware.eligibilityProgramCodeInvalid]:
				'Middleware.Errors.EligibilityProgramCodeInvalid',
			[Middleware.invoiceAlreadyCreatedEnrollmentId]:
				'Middleware.Errors.InvoiceAlreadyCreatedEnrollmentId',
			[Middleware.zipWasChanged]: 'Middleware.Errors.ZipWasChanged',
			[Middleware.streetDirectionalChanged]:
				'Middleware.Errors.DirectionalsOrSuffixMissingInvalid',
			[Middleware.cardNumberMissing]: 'Middleware.Errors.CardNumberMissing',
			[Middleware.subscriberNotEligibleForPortIn]:
				'Middleware.Errors.SubscriberNotEligibleForPortIn',
			[Middleware.invalidZip]: 'Middleware.Errors.InvalidZip'
		};

		static isMailingAddressError(response: HttpErrorResponse): boolean {
			const knownMiddlewareError = Middleware.errors.find(middlewareError =>
				httpErrorResponseIncludesError(response, middlewareError)
			);

			if (!knownMiddlewareError) {
				return false;
			}

			return Errors.Middleware.mailingAddressErrors.includes(knownMiddlewareError);
		}

		// TODO: move to common errors class
		static getErrorKey(response: HttpErrorResponse): string | undefined {
			const knownMiddlewareError = Middleware.errors.find(middlewareError =>
				httpErrorResponseIncludesError(response, middlewareError)
			);

			if (knownMiddlewareError) {
				return Errors.Middleware.getErrorMessageKey(knownMiddlewareError);
			}

			const knownOrderError = OrderErrors.errors.find(orderError =>
				httpErrorResponseIncludesError(response, orderError)
			);

			if (knownOrderError) {
				return OrderErrors.getErrorMessageKey(knownOrderError);
			}

			const knownCommonError = CommonErrors.errors.find(orderError =>
				httpErrorResponseIncludesError(response, orderError)
			);

			if (knownCommonError) {
				return CommonErrors.getErrorMessageKey(knownCommonError);
			}

			return;
		}

		static getErrorMessageKey(key: string): string | undefined {
			return Errors.Middleware.errorToMessageKey[key];
		}

		static getErrorCode(response: HttpErrorResponse): string | undefined {
			const knownMiddlewareError = Middleware.errors.find(middlewareError =>
				httpErrorResponseIncludesError(response, middlewareError)
			);

			return knownMiddlewareError;
		}
	}
}
