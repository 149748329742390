import { Injectable } from '@angular/core';
import { NavigationItemNames, NavItemsMap } from './sidebar-data';
import { AbsoluteRoutes } from '@shared/constants/routes.enum';
import { Role } from '@modules/employees/pages/employees/employee.type';
import { map, Observable, of } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';
import { EnrollmentLinksService } from '@core/services/enrollment-links.service';
import { stringEmpty } from '@core/utils/utils';
import { FeatureFlagService } from '@core/services/feature-flags/feature-flag.service';
import { Store } from '@ngxs/store';
import { AccountState } from '@store/account/account.state';

@Injectable({
	providedIn: 'root'
})
export class SideBarDataService {
	showEnrollmentLink = false;

	constructor(
		private ngxPermissionsService: NgxPermissionsService,
		private enrollmentLinksService: EnrollmentLinksService,
		private store: Store,
		private featureFlagService: FeatureFlagService
	) {}

	public getSideBarItems(): NavItemsMap {
		const navigationItems: NavItemsMap = {
			[NavigationItemNames.home]: {
				navCap: 'Home'
			},
			[NavigationItemNames.dashboard]: {
				displayName: 'Dashboard.Dashboard',
				iconName: 'home',
				route: AbsoluteRoutes.Dashboard
			},
			[NavigationItemNames.employees]: {
				displayName: 'Agents',
				iconName: 'users',
				route: AbsoluteRoutes.Employees,
				eligibleRoles: [Role.SuperAdmin, Role.Admin]
			},
			[NavigationItemNames.orders]: {
				displayName: 'Orders',
				iconName: 'list',
				route: AbsoluteRoutes.OrdersList
			},
			[NavigationItemNames.customerOnboarding]: {
				displayName: 'Customer Enrollments',
				iconName: 'user-plus',
				route: AbsoluteRoutes.NewCustomer,
				eligibleRoles: [
					Role.SuperAdmin,
					Role.Admin,
					Role.ClecManager,
					Role.ExternalSalesAgent,
					Role.InternalSalesAgent
				]
			},
			[NavigationItemNames.Retail]: {
				displayName: 'Retail',
				iconName: 'building-store',
				route: AbsoluteRoutes.Retail,
				displayRule: of(this.featureFlagService.getFeature('retail'))
			},
			[NavigationItemNames.optimum]: {
				displayName: 'Maxsip Home Internet',
				iconName: 'network',
				route: AbsoluteRoutes.OptimumEnrollmentList,
				displayRule: this.store.select(AccountState.optimumEnabled)
			},
			[NavigationItemNames.webOnboarding]: {
				displayName: 'Affiliate Links',
				iconName: 'app-window',
				route: AbsoluteRoutes.WebEnrollment,
				eligibleRoles: [Role.SuperAdmin, Role.Admin]
			},
			[NavigationItemNames.webEnrollmentsList]: {
				displayName: 'Web Enrollments',
				iconName: 'list-details',
				route: AbsoluteRoutes.WebEnrollmentList,
				displayRule: this.showWebEnrollments()
			},
			[NavigationItemNames.invitations]: {
				displayName: 'Invitations',
				iconName: 'link',
				route: AbsoluteRoutes.Invitations,
				eligibleRoles: [Role.SuperAdmin, Role.Admin, Role.ClecManager]
			},
			[NavigationItemNames.signupRequests]: {
				displayName: 'Signup Requests',
				iconName: 'list-check',
				route: AbsoluteRoutes.SignupRequests,
				eligibleRoles: [Role.SuperAdmin, Role.Admin, Role.ClecManager]
			},
			[NavigationItemNames.masterZip]: {
				displayName: 'Master Zip',
				iconName: 'map-pin',
				route: AbsoluteRoutes.MasterZip,
				eligibleRoles: [Role.SuperAdmin, Role.Admin, Role.ClecManager]
			},
			[NavigationItemNames.devices]: {
				displayName: 'Devices',
				iconName: 'devices',
				route: AbsoluteRoutes.Devices,
				eligibleRoles: [Role.SuperAdmin, Role.Admin, Role.ClecManager]
			},
			[NavigationItemNames.payments]: {
				displayName: 'Payments',
				iconName: 'wallet',
				disabled: true,
				route: AbsoluteRoutes.NotFound,
				eligibleRoles: [Role.SuperAdmin, Role.Admin, Role.ClecManager]
			},
			[NavigationItemNames.settings]: {
				displayName: 'Settings',
				iconName: 'settings',
				disabled: true,
				route: AbsoluteRoutes.NotFound
			},
			[NavigationItemNames.help]: {
				displayName: 'Help',
				iconName: 'help-circle',
				disabled: true,
				route: AbsoluteRoutes.NotFound
			},
			[NavigationItemNames.healthCheck]: {
				displayName: 'Health Check',
				iconName: 'health-recognition',
				route: AbsoluteRoutes.HealthCheck,
				eligibleRoles: [Role.SuperAdmin, Role.Admin]
			},
			[NavigationItemNames.auditLogs]: {
				displayName: 'Audit Logs',
				iconName: 'circle-letter-a',
				route: AbsoluteRoutes.AuditLogs,
				eligibleRoles: [Role.SuperAdmin, Role.Admin]
			}
		};

		return navigationItems;
	}

	private showWebEnrollments(): Observable<boolean> {
		const permissions = Object.values(this.ngxPermissionsService.getPermissions());
		if (permissions.length > 0 && !this.showEnrollmentLink) {
			const role = permissions[0]?.name;
			if (role === Role.InternalSalesAgent || role == Role.ExternalSalesAgent) {
				const request = { pageNumber: 1, pageSize: 1, search: stringEmpty };

				return this.enrollmentLinksService.getEnrollmentLinks(request).pipe(
					map(result => {
						this.showEnrollmentLink = result.data.length > 0;
						return this.showEnrollmentLink;
					})
				);
			}
		}
		return of(true);
	}
}
