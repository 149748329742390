import { EnrollmentLinkViewModel } from '@core/api-client/data-contracts';

export namespace AccountActions {
	export class LoadPermissions {
		static readonly type = '[Account] Load Permissions';
		constructor() {}
	}

	export class SetupPermissions {
		static readonly type = '[Account] Setup Permissions';
		constructor(permissions: string[]) {}
	}

	export class GetInfo {
		static readonly type = '[Account] Get Info';
		constructor() {}
	}

	export class SetAffiliateInfo {
		static readonly type = '[Account] Set affiliate info';
		constructor(readonly enrollmentLinkViewModel: EnrollmentLinkViewModel) {}
	}
}
