import { HttpErrorResponse } from '@angular/common/http';
import { httpErrorResponseIncludesError } from '@core/utils/http-error-response';

export class OrderErrors {
	static readonly paymentError = 'Order.PaymentError';
	static readonly emailOrPhoneIsNotVerified = 'Order.EmailOrPhoneIsNotVerified';
	static readonly proofsNotValid = 'Order.ProofsNotValid';

	static readonly errors = [
		OrderErrors.paymentError,
		OrderErrors.emailOrPhoneIsNotVerified,
		OrderErrors.proofsNotValid
	];

	static getErrorMessageKey(key: string): string {
		return OrderErrors.errorToMessageKey[key];
	}

	static getErrorCode(response: HttpErrorResponse): string | undefined {
		const knownError = OrderErrors.errors.find(error =>
			httpErrorResponseIncludesError(response, error)
		);

		return knownError;
	}

	private static readonly errorToMessageKey: { [key: string]: string } = {
		[OrderErrors.paymentError]: 'Order.Errors.PaymentError',
		[OrderErrors.emailOrPhoneIsNotVerified]: 'Order.Errors.EmailOrPhoneIsNotVerified',
		[OrderErrors.proofsNotValid]: 'Order.Errors.ProofsNotValid'
	};
}
