import { Component, Inject, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';

import { Observable } from 'rxjs';
import { ActionsExecuting, actionsExecuting } from '@ngxs-labs/actions-executing';

import { CommonModule } from '@angular/common';

import { EmployeeDialogContentAction } from './self-signup-invite.type';

import { Employee } from '@core/models/employee';
import { MaterialModule } from '../../../material.module';
import { CarrierCode } from '@core/models/carrier-code';
import { EmployeesState } from '@store/employees/employees.state';

import { InvitationsActions } from '@store/invitations/invitations.actions';
import { SelfSignupInviteViewModel } from '@core/models/invitations';
import { TablerIconsModule } from 'angular-tabler-icons';
import { MaskDateDirective } from '@shared/directives/mask-date.directive';

@Component({
	selector: 'app-self-signup-invite',
	templateUrl: 'self-sign-up-invite.component.html',
	standalone: true,
	imports: [
		MaterialModule,
		FormsModule,
		ReactiveFormsModule,
		CommonModule,
		TablerIconsModule,
		MaskDateDirective
	],
	encapsulation: ViewEncapsulation.None
})
export class AppSelfSignupInviteDialogComponent implements OnInit {
	@Select(EmployeesState.carrierCodes) carrierCodes$: Observable<CarrierCode[]>;
	@Select(actionsExecuting([InvitationsActions.SelfSignup]))
	actionIsExecuting$: Observable<ActionsExecuting>;

	action: EmployeeDialogContentAction;
	selfSignupInviteForm = this.formBuilder.group({
		description: [''],
		name: ['', Validators.required],
		associatedClecId: [0, [Validators.required, Validators.min(1)]],
		expirationDate: [Date.now(), Validators.required]
	});

	constructor(
		private store: Store,
		private formBuilder: FormBuilder,
		public dialogRef: MatDialogRef<AppSelfSignupInviteDialogComponent>,
		@Optional()
		@Inject(MAT_DIALOG_DATA)
		public data: { model: Employee; action: EmployeeDialogContentAction }
	) {
		this.action = data.action;
	}

	public ngOnInit(): void {}

	public doAction(): void {
		this.store
			.dispatch(
				new InvitationsActions.SelfSignup(
					this.selfSignupInviteForm.getRawValue() as SelfSignupInviteViewModel
				)
			)
			.subscribe(_ => this.dialogRef.close());
	}

	public closeDialog(): void {
		this.dialogRef.close({ event: 'Cancel' });
	}
}
