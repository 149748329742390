import { Directive, OnInit } from '@angular/core';
import {
	GridColumn,
	GridFilter,
	PageChangedEvent,
	SortChangedEvent
} from '@shared/components/grid/grid.component.type';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, map } from 'rxjs';
import { getSearchFilter } from '@shared/components/grid-filters/grid-filters.component.type';
import { isStringNullOrEmpty } from '@core/utils/utils';
import { Sort } from '@angular/material/sort';

@Directive()
export abstract class BasicGridPageComponent<T, TR = any> implements OnInit {
	public columns: GridColumn<T>[] = [];
	public paginationSubject = new BehaviorSubject({
		pageIndex: 0,
		pageSize: 15
	});
	public filters: GridFilter<TR>[] = [getSearchFilter()];
	public filterSubject = new BehaviorSubject<Partial<TR>>({});
	public sortingSubject = new BehaviorSubject<SortChangedEvent | null>(null);

	public get sorting(): Sort {
		const value = this.sortingSubject.value;
		if (!value) {
			return { active: '', direction: '' };
		}
		return {
			active: value?.column,
			direction: value.ascending ? 'asc' : 'desc'
		};
	}

	public get filterValues() {
		return combineLatest([
			this.filterSubject.pipe(map(this.cleanupFilter)),
			this.paginationSubject,
			this.sortingSubject
		]).pipe(distinctUntilChanged(), debounceTime(100));
	}

	private cleanupFilter(payload: Partial<TR>): TR {
		const cleanedFilter = Object.keys(payload).reduce((acc, key) => {
			const value = payload[key as never];
			if (isStringNullOrEmpty(value)) {
				return acc;
			}
			return { ...acc, [key]: value };
		}, {});
		const isEmptyFiltration = !Object.keys(cleanedFilter).length;
		return (isEmptyFiltration ? null : cleanedFilter) as TR;
	}

	public get pageSize(): number {
		return this.paginationSubject.value.pageSize;
	}

	public get pageIndex(): number {
		return this.paginationSubject.value.pageIndex;
	}

	protected constructor() {}

	public ngOnInit() {}

	public filtersChanged(event: Partial<TR>) {
		this.filterSubject.next(event);
		this.paginationSubject.next({ pageIndex: 0, pageSize: this.pageSize });
	}

	public pageChanged(event: PageChangedEvent) {
		this.paginationSubject.next({ ...event });
	}

	public sortingChanged(event: SortChangedEvent | null) {
		this.sortingSubject.next(event);
		this.paginationSubject.next({ pageIndex: 0, pageSize: this.pageSize });
	}
}
