import { HttpErrorResponse } from '@angular/common/http';
import { httpErrorResponseIncludesError } from '@core/utils/http-error-response';

export class CommonErrors {
	static readonly esnDeviceTypeMismatch = 'Common.EsnDeviceTypeMismatch';
	static readonly esnNotFound = 'Common.EsnNotFound';
	static readonly undeliverableEmail = 'Common.UndeliverableEmail';

	static readonly errors = [
		CommonErrors.esnDeviceTypeMismatch,
		CommonErrors.esnNotFound,
		CommonErrors.undeliverableEmail
	];

	static getErrorMessageKey(key: string): string {
		return CommonErrors.errorToMessageKey[key];
	}

	static getErrorCode(response: HttpErrorResponse): string | undefined {
		const knownError = CommonErrors.errors.find(error =>
			httpErrorResponseIncludesError(response, error)
		);

		return knownError;
	}

	private static readonly errorToMessageKey: { [key: string]: string } = {
		[CommonErrors.esnDeviceTypeMismatch]: 'Errors.Common.EsnDeviceTypeMismatch',
		[CommonErrors.esnNotFound]: 'Errors.Common.EsnNotFound',
		[CommonErrors.undeliverableEmail]: 'Errors.Common.UndeliverableEmail'
	};
}
