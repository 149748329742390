import {
	Component,
	Output,
	EventEmitter,
	Input,
	ViewEncapsulation,
	OnInit,
	DestroyRef
} from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { MaterialModule } from 'src/app/material.module';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Select, Store } from '@ngxs/store';

import { TablerIconsModule } from 'angular-tabler-icons';

import { map, Observable } from 'rxjs';

import { MsalService } from '@azure/msal-angular';
import { environment } from '@environment/environment';

import { User } from '@core/interfaces/user.type';
import { notifications } from '@core/interfaces/header.types';

import { AccountState } from '@core/store/account/account.state';
import { AccountActions } from '@core/store/account/account.actions';
import { EmployeesState } from '@core/store/employees/employees.state';
import { MsalSignInService } from '@core/services/msal-sign-in.service';

import * as LogRocket from 'logrocket';
import * as Sentry from '@sentry/angular-ivy';
import { CustomerState } from '@store/customer/customer.state';
import { Customer } from '@core/models/customer.type';
import { datadogRum } from '@datadog/browser-rum';
import { UserSessionEvent, MonitoringIdentity } from '@shared/components/header/header.type';
import { filter } from 'rxjs/operators';
import { isNullOrUndefined, isStringNotNullOrEmpty, isStringNullOrEmpty } from '@core/utils/utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { Role } from '@modules/employees/pages/employees/employee.type';

@Component({
	selector: 'app-header',
	standalone: true,
	imports: [RouterModule, CommonModule, NgScrollbarModule, TablerIconsModule, MaterialModule],
	templateUrl: './header.component.html',
	encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
	@Input() showToggle = true;
	@Input() toggleChecked = false;
	@Output() toggleMobileNav = new EventEmitter<void>();
	@Output() toggleMobileFilterNav = new EventEmitter<void>();
	@Output() toggleCollapsed = new EventEmitter<void>();

	@Select(AccountState.permissions) permissions$: Observable<string>;
	@Select(AccountState.carrierCodesDescription)
	carrierCodesDescription$: Observable<string>;
	@Select(EmployeesState.signUpRequestsCount)
	signUpRequestsCount$: Observable<number>;

	@Select(CustomerState.currentCustomer)
	currentCustomer$: Observable<Customer>;

	currentUser: User;
	guestUserName = 'Guest';

	readonly newCustomerAvailableForRoles = [
		Role.SuperAdmin,
		Role.Admin,
		Role.ExternalSalesAgent,
		Role.InternalSalesAgent,
		Role.ClecManager
	];

	constructor(
		private store: Store,
		public dialog: MatDialog,
		private destroyRef: DestroyRef,
		public msalService: MsalService,
		public msalSignInService: MsalSignInService
	) {
		this.store.dispatch(new AccountActions.GetInfo());
	}

	public ngOnInit(): void {
		const activeAccounts = this.msalService.instance.getAllAccounts();
		if (activeAccounts.length == 0) {
			return;
		}

		const { name, username, localAccountId } = activeAccounts[0];
		this.currentUser = {
			name: name ?? this.guestUserName,
			email: username
		};

		const { version, stage } = environment;

		if (stage === 'dev') {
			return;
		}

		if (isStringNullOrEmpty(localAccountId)) {
			return;
		}

		const identity = {
			id: localAccountId,
			email: username,
			releaseVersion: version,
			releaseEnvironment: stage,
			name: this.currentUser.name
		};
		this.setupMonitoringToolsIdentity(identity);
		this.trackEnrollmentId();
	}

	private setupMonitoringToolsIdentity(identity: MonitoringIdentity) {
		Sentry.setUser({
			id: identity.id
		});
		LogRocket.identify(identity.id, { ...identity });
		datadogRum.setUser({ ...identity });
	}

	private trackEnrollmentId() {
		this.enrollmentId$
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				filter(enrollmentId => isStringNotNullOrEmpty(enrollmentId))
			)
			.subscribe(enrollmentId => {
				LogRocket.track(UserSessionEvent.NewEnrollment, {
					enrollmentId
				});
				datadogRum.addAction(UserSessionEvent.NewEnrollment, {
					enrollmentId
				});
			});
	}

	get enrollmentId$() {
		return this.currentCustomer$.pipe(map(c => c?.enrollmentId));
	}
}
