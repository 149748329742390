import {
	EmployeeViewModel,
	GetEmployeeSignupRequests,
	GetEmployeesRequest
} from '../../models/employee';

export namespace EmployeesActions {
	export class GetEmployeesList {
		static readonly type = '[Employees] Get Employees List';

		constructor(public payload: GetEmployeesRequest) {}
	}

	export class AddEmployee {
		static readonly type = '[Employees] Add Agent';

		constructor(public employeeViewModel: EmployeeViewModel) {}
	}

	export class UpdateEmployee {
		static readonly type = '[Employees] Update Employee';

		constructor(public employeeViewModel: EmployeeViewModel) {}
	}

	export class GetCarrierCodes {
		static readonly type = '[Employees] Get Carrier Codes';

		constructor() {}
	}

	export class InviteEmployee {
		static readonly type = '[Employees] Invite Agent';

		constructor(public employeeViewModel: EmployeeViewModel) {}
	}

	export class GetEmployeesRequests {
		static readonly type = '[Employees] Get Employees SignUp Requests';

		constructor(public payload: GetEmployeeSignupRequests) {}
	}

	export class ApproveSignupRequest {
		static readonly type = '[Employees] Approve Signup Request';

		constructor(public payload: { id: string }) {}
	}

	export class DeclineSignupRequest {
		static readonly type = '[Employees] Decline Signup Request';

		constructor(public payload: { id: string }) {}
	}

	export class GetMvnoMetadata {
		static readonly type = '[Employees] Get Mvno Metadata';

		constructor() {}
	}

	export class GetAgentsList {
		static readonly type = '[Employees] Get Agents List';

		constructor(public search: string) {}
	}

	export class ImportEmployeesFile {
		static readonly type = '[Employees] Import Employees File';

		constructor(public file: File) {}
	}

	export class ResetImportEmployeesFile {
		static readonly type = '[Employees] Reset Import Employees File';

		constructor() {}
	}

	export class GetAgentsByClecId {
		static readonly type = '[Employees] Get Agents By ClecId';

		constructor(public associatedClecId: number) {}
	}
}
