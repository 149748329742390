import { environment } from '@environment/environment';

export interface AppSettings {
	dir: 'ltr' | 'rtl';
	theme: string;
	sidenavOpened: boolean;
	sidenavCollapsed: boolean;
	boxed: boolean;
	horizontal: boolean;
	activeTheme: string;
	language: string;
	cardBorder: boolean;
	navPos: 'side' | 'top';
	menuMaxCollapseWidth: number;
}

export const defaults: AppSettings = {
	dir: 'ltr',
	theme: 'main',
	sidenavOpened: false,
	sidenavCollapsed: false,
	boxed: true,
	horizontal: false,
	cardBorder: false,
	activeTheme: 'blue_theme',
	language: 'en-us',
	navPos: 'side',
	menuMaxCollapseWidth: 1024
};

//TODO: REMOVE WHEN MOVE TO THE WORKSPACES
export const protectedResourceMap = new Map([
	[environment.endpoint + '/api/v1/LifelineEnrollment', null],
	[environment.endpoint + '/api/v1/LifelineEnrollment/*', null],
	[environment.endpoint + '/api/v1/webEnrollment/get', null],
	[environment.endpoint + '/api/v1/SelfEnrollment', null],
	[environment.endpoint + '/api/v1/SelfEnrollment/*', null],
	[environment.endpoint + '/api/v1/DocumentTypes', null],
	[environment.endpoint + '/api/v1/DocumentTypes/*', null],
	[environment.endpoint + '/api/v1/tribal/validate', null],
	[environment.endpoint + '/api/v1/Addresses/validate', null],
	[environment.endpoint + '/api/v1/Messages/send', null],
	[environment.endpoint + '/api/v1/Messages/verify', null],
	[environment.endpoint + '/api/v1/optimum/affiliates', null],
	[environment.endpoint, environment.endpointScopes]
]);
