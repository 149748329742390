import { EnrollmentType } from '@core/models/enrollment-type';

export namespace PhoneVerificationActions {
	export class SendVerificationMessage {
		static readonly type = '[PhoneVerification] Send Verification Message';
		constructor(
			readonly phone: string,
			readonly enrollmentType?: EnrollmentType
		) {}
	}

	export class VerifyMessage {
		static readonly type = '[PhoneVerification] Verify Message';
		constructor(
			public phone: string,
			public code: string
		) {}
	}

	export class UpdateTimer {
		static readonly type = '[PhoneVerification] Update Timer';
		constructor() {}
	}

	export class SetPhoneVerified {
		static readonly type = '[PhoneVerification] Set Phone Verified';
		constructor(public verified: boolean) {}
	}

	export class ResetPhoneVerificationState {
		static readonly type = '[PhoneVerification] Reset';
		constructor() {}
	}
}
