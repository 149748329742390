export enum OrderErrorCode {
	UpdateEnrollment = 1,
	ValidateAddress = 2,
	CheckEligibility = 3,
	EligibilityStatusCheck = 4,
	NladVerify = 5,
	PaymentDetails = 6,
	MakePayment = 7,
	CreateLifeline = 8,
	HandoverActivation = 9,
	TransferAcp = 10
}

export type OrderErrorCodeToMessagesMap = { [key in OrderErrorCode]: string[] };
