import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AccountApiMethods } from '../utils/api/account-api-methods';
import { AccountInfoViewModel } from '@store/account/account.types';
import { ApiResponse } from '@core/interfaces/api-response.type';

@Injectable({
	providedIn: 'root'
})
export class AccountService {
	constructor(private http: HttpClient) {}

	public getPermissions(): Observable<ApiResponse<string[]>> {
		return this.http.get<ApiResponse<string[]>>(AccountApiMethods.permissions());
	}

	public getInfo(): Observable<ApiResponse<AccountInfoViewModel>> {
		return this.http.get<ApiResponse<AccountInfoViewModel>>(AccountApiMethods.getInfo());
	}

	public validateHash(hash: string): Observable<ApiResponse<boolean>> {
		return this.http.get<ApiResponse<boolean>>(AccountApiMethods.validateHash(hash));
	}
}
