<div class="px-3 md:px-5 flex flex-col gap-y-8 py-8">
	<div class="flex gap-x-2">
		<i-tabler
			class="fill-icon"
			name="health-recognition"
		></i-tabler>
		<h4 class="font-semibold text-xl">Health Check</h4>
	</div>

	<mat-card class="shadow-none bg-white overflow-hidden">
		<mat-card-content class="!pt-4 !px-0">
			<div class="flex items-center justify-center">
				<iframe
					class="w-full h-56"
					[src]="baseUrl"
				></iframe>
			</div>
		</mat-card-content>
	</mat-card>
</div>
