export interface MonitoringIdentity {
	id: string;
	name: string;
	email: string;
	releaseVersion: string;
	releaseEnvironment: string;
}

export enum UserSessionEvent {
	NewEnrollment = 'NewEnrollment',
	NewWebEnrollment = 'NewWebEnrollment',
	LifelineEnrollmentRequest = 'LifelineEnrollmentRequest'
}
