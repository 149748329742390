import { EmployeeViewModel } from '@core/models/employee';
export const CorporateAccountDomain = 'maxsiptel.com';
export enum InvitationPolicies {
	LocalAccountPassword = 'B2C_1A_INVITATION_LINK_PASSWORD_MAXSIP',
	LocalAccountPasswordVerified = 'B2C_1A_INVITATION_LINK_PASSWORD_VERIFIED_MAXSIP',
	SelfSignup = 'B2C_1A_INVITATION_LINK_SELFSIGNUP_MAXSIP'
}

export interface EmailPasswordCredentials {
	email: string;
	password: string;
	model: EmployeeViewModel;
}

export enum LetterType {
	Welcome,
	WelcomeCorporate,
	ResetPassword
}

export interface EmployeeInvitationEmailLetter {
	email: string;
	type: LetterType;
	passwordLink: string | null;
}
