import { Component, Inject, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TablerIconsModule } from 'angular-tabler-icons';
import { MaterialModule } from '../../material.module';
import { RouterLink } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { InvitationsState } from '@store/invitations/invitations.state';
import { Observable, Subject, takeUntil } from 'rxjs';
import { InvitationsActions } from '@store/invitations/invitations.actions';
import { InvitationViewModel } from '@core/models/invitations';
import { ActivationStatus } from '../employees/pages/employees/employee.type';
import { EmployeeDialogContentAction } from '../employees/pages/employees/add-employee-dialog/employee-dialog-content.type';
import { AppAddEmployeeDialogComponent } from '../employees/pages/employees/add-employee-dialog/employee-dialog-content.component';
import { AppSelfSignupInviteDialogComponent } from './self-signup-invite-dialog/self-signup-invite.component';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { EmployeeSignupRequest, EmployeeViewModel } from '../../core/models/employee';
import { Clipboard } from '@angular/cdk/clipboard';
import { environment } from '@environment/environment';
import { InvitationPolicies } from '../employees/pages/employees/employee-credentials-dialog/employee-credentials.type';
import { SnackbarActions } from '@store/snackbar/snackbar.actions';
import { EmployeesState } from '@store/employees/employees.state';
import { CarrierCode } from '@core/models/carrier-code';

@Component({
	selector: 'app-invitations',
	standalone: true,
	imports: [CommonModule, TablerIconsModule, MaterialModule, RouterLink],
	templateUrl: './invitations.component.html',
	styleUrls: ['./invitations.component.css']
})
export class InvitationsComponent {
	@Select(InvitationsState.invitations) invitations$: Observable<InvitationViewModel[]>;
	@Select(EmployeesState.carrierCodes) carrierCodes$: Observable<CarrierCode[]>;

	private carrierCodes: CarrierCode[];
	private readonly _destroying$ = new Subject<void>();

	constructor(
		public dialog: MatDialog,
		private store: Store,
		private clipboard: Clipboard,
		@Optional() @Inject(MAT_DIALOG_DATA) public data: EmployeeViewModel
	) {
		this.carrierCodes$.pipe(takeUntil(this._destroying$)).subscribe(carrierCodes => {
			this.carrierCodes = carrierCodes;
		});

		this.store.dispatch(new InvitationsActions.GetInvitations());
	}

	public openDialog(action: string, obj: any): void {
		obj.action = action;

		const component =
			action === EmployeeDialogContentAction.Add
				? AppAddEmployeeDialogComponent
				: AppSelfSignupInviteDialogComponent;

		this.dialog.open(component as any, {
			data: obj
		});
	}

	private generatePolicyInviteLink(
		policy: string,
		invitation: EmployeeSignupRequest | InvitationViewModel
	): string {
		const { nonce, token } = invitation;
		const { inviteBaseUrl, inviteClientId, redirectUri } = environment;

		return `${inviteBaseUrl}/${policy}/oauth2/v2.0/authorize?client_id=${inviteClientId}&nonce=${nonce}&redirect_uri=${redirectUri}&scope=openid&response_type=id_token&id_token_hint=${token}`;
	}

	public copyInvitationLink(invitation: InvitationViewModel): void {
		this.clipboard.copy(
			this.generatePolicyInviteLink(InvitationPolicies.SelfSignup, invitation)
		);
		this.store.dispatch(new SnackbarActions.ShowMessage('Link Copied'));
	}

	public openMailClient(invitation: InvitationViewModel) {
		let invitationLink = this.generatePolicyInviteLink(
			InvitationPolicies.SelfSignup,
			invitation
		);

		window.location.href = `mailto:?subject=Maxsip Sales Agent Portal Invitation&body=${encodeURIComponent(
			invitationLink
		)}`;
	}

	public toggleActivation(invitation: InvitationViewModel) {
		this.store.dispatch(
			new InvitationsActions.ChangeAvailability({
				id: invitation.id,
				enabled: !invitation.enabled
			})
		);
	}

	public identify(index: number, item: InvitationViewModel) {
		return item.id;
	}

	public getCarrierDescription(carrierCodeId: number): string {
		const carrierCode = this.carrierCodes.find(
			carrierCode => carrierCode.id === +carrierCodeId
		);

		return carrierCode?.description ?? '';
	}

	public getHeaderClass(invitation: InvitationViewModel): string {
		if (invitation.expired) {
			return 'expired';
		}

		if (!invitation.enabled) {
			return 'disabled';
		}

		return '';
	}

	protected readonly ActivationStatus = ActivationStatus;
	protected readonly EmployeeDialogContentAction = EmployeeDialogContentAction;
}
