<mat-sidenav-container
	class="mainWrapper"
	autosize
	autoFocus
	[ngClass]="{
		'sidebarNav-mini': options.sidenavCollapsed && options.navPos !== 'top',
		'sidebarNav-horizontal': options.horizontal,
		cardBorder: options.cardBorder
	}"
	[dir]="options.dir!"
>
	<mat-sidenav
		#leftsidenav
		[mode]="isOver ? 'over' : 'side'"
		[opened]="options.navPos === 'side' && options.sidenavOpened && !isOver"
		*ngIf="!options.horizontal"
		(openedChange)="onSidenavOpenedChange($event)"
		class="sidebarNav"
	>
		<div class="flex-layout">
			<app-sidebar *ngIf="!options.sidenavCollapsed"></app-sidebar>
			<ng-scrollbar class="position-relative height-100">
				<mat-nav-list class="sidebar-list">
					<ng-container *ngFor="let item of getNavigationItems()">
						<app-nav-item
							*ngIf="!item.displayRule || (item.displayRule | async)"
							[item]="item"
							(notify)="sidenav.toggle()"
						>
						</app-nav-item>
					</ng-container>
				</mat-nav-list>
			</ng-scrollbar>
		</div>
	</mat-sidenav>

	<mat-sidenav-content
		class="contentWrapper"
		#content
	>
		<ng-container>
			<app-header
				[showToggle]="!isOver"
				(toggleCollapsed)="toggleCollapsed()"
				(toggleMobileNav)="sidenav.toggle()"
			></app-header>
		</ng-container>

		<main
			class="pageWrapper"
			[ngClass]="{
				maxWidth: options.boxed
			}"
		>
			<router-outlet></router-outlet>
		</main>
	</mat-sidenav-content>
</mat-sidenav-container>
