import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	Inject,
	OnInit,
	Optional
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { distinctUntilChanged, Observable, tap } from 'rxjs';
import { ActionsExecuting, actionsExecuting } from '@ngxs-labs/actions-executing';
import { EmployeesActions } from '@store/employees/employees.actions';

import { match } from 'ts-pattern';
import { parseName } from 'humanparser';
import { EmployeesState } from '@store/employees/employees.state';
import { CarrierCode, ClecId } from '@core/models/carrier-code';
import { Employee, EmployeeViewModel, MvnoMetadata, MvnoSetting } from '@core/models/employee';
import { EmployeeDialogContentAction } from './employee-dialog-content.type';
import { PermissionUtils } from '@core/utils/permission-utils';
import {
	OrderDeliveryMethod,
	orderDeliveryMethodsArrayToFlags,
	orderDeliveryMethodToArray
} from '@core/models/OrderDeliveryMethod';

import { isStringNullOrEmpty } from '@core/utils/utils';
import {
	Role,
	ActivationStatus,
	Mvno,
	mapAccountStatusToLabel,
	mapRoleToLabel
} from '../employee.type';

@Component({
	selector: 'app-employee-dialog-content',
	styleUrls: ['./employee-dialog-content.component.css'],
	templateUrl: 'employee-dialog-content.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppAddEmployeeDialogComponent implements OnInit {
	readonly deliveryMethods = [
		{ label: 'Handover', value: OrderDeliveryMethod.Handover },
		{ label: 'Shipment', value: OrderDeliveryMethod.Shipment }
	];

	@Select(EmployeesState.carrierCodes) carrierCodes$: Observable<CarrierCode[]>;
	@Select(EmployeesState.mvnoMetadata) mvnoMetadata$: Observable<MvnoMetadata[]>;
	@Select(
		actionsExecuting([
			EmployeesActions.AddEmployee,
			EmployeesActions.UpdateEmployee,
			EmployeesActions.InviteEmployee
		])
	)
	actionIsExecuting$: Observable<ActionsExecuting>;

	action: EmployeeDialogContentAction;
	employeeForm = this.formBuilder.group({
		firstName: ['', Validators.required],
		lastName: ['', Validators.required],
		email: ['', [Validators.required, Validators.email]],
		role: [Role.Admin, Validators.required],
		phone: [''],
		clecIdCode: [0],
		clecIdCodes: new FormControl<number[]>([]),
		activationStatus: [ActivationStatus.Approved],
		orderDeliveryMethods: [[OrderDeliveryMethod.None], [Validators.required]],
		mvnoList: [[Mvno.Maxsip], [Validators.required]],
		radId: [''],
		maxsipAgentId: [''],
		terracomAgentId: ['']
	});

	constructor(
		private store: Store,
		private formBuilder: FormBuilder,
		private readonly destroyRef: DestroyRef,
		public dialogRef: MatDialogRef<AppAddEmployeeDialogComponent>,
		@Optional()
		@Inject(MAT_DIALOG_DATA)
		public data: { model: Employee; action: EmployeeDialogContentAction }
	) {
		this.action = data.action;

		if (this.action === EmployeeDialogContentAction.Update) {
			this.setupEmployeeForm(data.model);
		}
	}

	public ngOnInit(): void {
		if (this.action === EmployeeDialogContentAction.Add) {
			this.employeeForm.controls.phone.setValidators([Validators.required]);
			this.employeeForm.updateValueAndValidity();
		}

		this.employeeForm.controls.mvnoList.valueChanges
			.pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
			.subscribe(value => {
				this.mvnoUpdateAndValidity(value!);
			});
		this.employeeForm.controls.role.valueChanges
			.pipe(
				distinctUntilChanged(),
				takeUntilDestroyed(this.destroyRef),
				tap(value => {
					if (
						value &&
						[
							Role.InternalSalesAgent,
							Role.ExternalSalesAgent,
							Role.ClecManager
						].includes(value)
					) {
						this.employeeForm.controls.radId.setValidators(Validators.required);
						this.employeeForm.controls.radId.updateValueAndValidity({ onlySelf: true });
					} else {
						this.employeeForm.controls.radId.clearValidators();
						this.employeeForm.controls.radId.setErrors(null);
					}
				})
			)
			.subscribe();
	}

	private mvnoUpdateAndValidity(value: Mvno[]): void {
		this.employeeForm.controls.terracomAgentId.clearValidators();
		this.employeeForm.controls.terracomAgentId.setErrors(null);

		this.employeeForm.controls.maxsipAgentId.clearValidators();
		this.employeeForm.controls.maxsipAgentId.setErrors(null);

		if (value?.includes(Mvno.Maxsip)) {
			this.employeeForm.controls.maxsipAgentId.setValidators(Validators.required);
		}

		if (value?.includes(Mvno.Terracom)) {
			this.employeeForm.controls.terracomAgentId.setValidators(Validators.required);
		}

		this.employeeForm.controls.maxsipAgentId.updateValueAndValidity();
		this.employeeForm.controls.terracomAgentId.updateValueAndValidity();
	}

	private setupEmployeeForm(employee: Employee): void {
		const {
			clecIdCodes,
			userName,
			displayName,
			role,
			phone,
			email,
			activationStatus,
			orderDeliveryMethods,
			mvnoSettings,
			radId
		} = employee;
		const { firstName, lastName } = !isStringNullOrEmpty(displayName)
			? parseName(displayName)
			: parseName(userName);

		this.employeeForm.patchValue({
			firstName,
			lastName,
			phone,
			email,
			radId,
			activationStatus,
			role: role as Role,
			orderDeliveryMethods: orderDeliveryMethodToArray(orderDeliveryMethods)
		});

		this.patchMvnos(mvnoSettings!);
		this.patchClecCodeId(clecIdCodes);
	}

	private patchClecCodeId(clecIdCodes: ClecId[] | null): void {
		if (this.isClecIdMultipleSelectionAvailable) {
			this.employeeForm.patchValue({
				clecIdCodes: clecIdCodes?.map(code => code.carrierId)
			});
			return;
		}

		if (clecIdCodes?.length) {
			this.employeeForm.patchValue({
				clecIdCode: clecIdCodes[0].carrierId
			});
		}
	}

	private patchMvnos(mvnos: MvnoSetting[]): void {
		const mvnoMetadata = this.store.selectSnapshot(EmployeesState.mvnoMetadata);
		const mvnoList = mvnoMetadata
			.filter(x => mvnos.findIndex(i => i.mvnoId === x.id) > -1)
			.map(m => m.mvno);

		const maxsipAgentId = mvnos.find(x => x.mvno === Mvno.Maxsip)?.agentId;
		const terracomAgentId = mvnos.find(x => x.mvno === Mvno.Terracom)?.agentId;

		this.employeeForm.patchValue({
			mvnoList,
			maxsipAgentId,
			terracomAgentId
		});
	}

	protected get isClecIdFieldAvailable(): boolean {
		const role = this.employeeForm.controls.role.value;
		return PermissionUtils.canAccessClecControl([role] as string[]);
	}

	protected get isClecIdMultipleSelectionAvailable(): boolean {
		const role = this.employeeForm.controls.role.value;

		return [Role.InternalSalesAgent].includes(role as Role);
	}

	public doAction(): void {
		match(this.action)
			.with(EmployeeDialogContentAction.Update, () => this.updateEmployee())
			.otherwise(() => this.addEmployee());
	}

	public isMvnoRequired(mvno: Mvno): boolean {
		return this.employeeForm.controls.mvnoList.value?.includes(mvno) ?? false;
	}

	private getEmployeeViewModel(): EmployeeViewModel {
		let formViewModel = this.employeeForm.getRawValue();

		if (!this.isClecIdMultipleSelectionAvailable && formViewModel.clecIdCode) {
			formViewModel.clecIdCodes = [formViewModel.clecIdCode];
		}

		let employeeViewModel: EmployeeViewModel = {
			firstName: formViewModel.firstName,
			lastName: formViewModel.lastName,
			role: formViewModel.role!,
			email: formViewModel.email!,
			phone: formViewModel.phone,
			radId: formViewModel.radId,
			activationStatus: formViewModel.activationStatus!,
			clecIdCodes: formViewModel.clecIdCodes || [],
			mvnoSettings: this.getMvnoSettings() ?? [],
			orderDeliveryMethods: orderDeliveryMethodsArrayToFlags(
				this.employeeForm.controls.orderDeliveryMethods.value || []
			)
		};
		employeeViewModel.mvnoSettings = this.getMvnoSettings() ?? [];

		return employeeViewModel;
	}

	private getMvnoSettings(): MvnoSetting[] {
		const { mvnoList } = this.employeeForm.getRawValue();
		const mvnos = this.store.selectSnapshot(EmployeesState.mvnoMetadata);

		return mvnos
			?.filter(({ mvno }) => mvnoList?.includes(mvno))
			.map(({ mvno, id }) =>
				match(mvno)
					.with(Mvno.Terracom, () => {
						return {
							mvno,
							mvnoId: id,
							agentId: this.employeeForm.controls.terracomAgentId.value!
						};
					})
					.otherwise(() => {
						return {
							mvno,
							mvnoId: id,
							agentId: this.employeeForm.controls.maxsipAgentId.value!
						};
					})
			);
	}

	private addEmployee() {
		let employeeModel = this.getEmployeeViewModel();
		this.store
			.dispatch(new EmployeesActions.AddEmployee(employeeModel))
			.subscribe(({ employees }) => {
				const { lastAddedEmployee } = employees;
				if (lastAddedEmployee) {
					this.dialogRef.close({
						event: this.action,
						data: {
							model: employeeModel,
							email: employeeModel.email,
							password: lastAddedEmployee.tempPassword
						}
					});
				}
			});
	}

	private updateEmployee() {
		let employeeModel = this.getEmployeeViewModel();
		this.store
			.dispatch(new EmployeesActions.UpdateEmployee(employeeModel))
			.subscribe(_ => this.dialogRef.close({}));
	}

	public closeDialog(): void {
		this.dialogRef.close({ event: 'Cancel' });
	}

	public getAccountStatusKeys(): number[] {
		return Object.keys(mapAccountStatusToLabel).map(key => parseInt(key));
	}

	public getAccountStatus(key: number): string {
		return mapAccountStatusToLabel[key];
	}

	protected readonly mapRoleToLabel = mapRoleToLabel;
	protected readonly EmployeeDialogContentAction = EmployeeDialogContentAction;
	protected readonly Mvno = Mvno;
}
