/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** @format int32 */
export enum AcpTimeZones {
	Samoa = 2,
	Hawaii = 3,
	Alaska = 4,
	PacificTime = 5,
	Arizona = 6,
	MountainTime = 8,
	CentralTime = 10,
	EasternTime = 14,
	IndianaEast = 15,
	AtlanticTime = 16,
	GuamPortMoresby = 69,
	UTC = 87,
	AmericanSamoaMidway = 112
}

/** @format int32 */
export enum ActivationStatus {
	PendingActivation = 0,
	Activated = 1,
	Blocked = 2,
	Invited = 3
}

export interface AddCustomerCommand {
	currentStep: OnboardingStep;
	lifelineEnrollmentType: LifelineEnrollmentType;
	programInfo: CustomerProgramInfoDTO;
	personalInfo: CustomerPersonalInfoDto;
	bqpEnabled: boolean;
	bqpPersonalInfo: CustomerPersonalInfoDto;
	contactInfo: CustomerContactDto;
	addressInfo: CustomerAddressDto;
	hasMailAddress: boolean;
	mailAddressInfo: CustomerAddressDto;
	enrollmentId?: string | null;
	/** @format int32 */
	agentId: number;
	/** @format int32 */
	carrierId: number;
}

export interface AddEmployeeCommand {
	firstName: string;
	lastName: string;
	role: string;
	phone: string;
	email: string;
	radId: string;
	orderDeliveryMethods: OrderDeliveryMethod;
	activationStatus: ActivationStatus;
	clecIdCodes: number[];
	mvnoSettings: MvnoSetting[];
}

export interface AddSelfEnrollmentCommand {
	/** @format uuid */
	enrollmentLinkId: string;
	zip: string;
	email: string;
	enrollmentId: string;
	lifelineEnrollmentType: LifelineEnrollmentType;
}

export interface AddressDto {
	/** @format uuid */
	id?: string | null;
	zip?: string | null;
	address: string;
	apartmentNum?: string | null;
	city: string;
	state: string;
	/** @format double */
	longitude: number;
	/** @format double */
	latitude: number;
}

export interface AddressValidateCommand {
	/** @format uuid */
	customerId: string;
	address: CustomerAddressDto;
	mailAddress: CustomerAddressDto;
}

export interface AddressValidationResult {
	validAddress?: boolean | null;
	validApt?: boolean | null;
	errorMessageCode?: string | null;
	formattedAddress?: string | null;
	formattedAptNum?: string | null;
	formattedCity?: string | null;
	formattedState?: string | null;
	formattedZip?: string | null;
}

export interface AddressValidationResultResponse {
	succeeded: boolean;
	message?: string | null;
	errors?: string[] | null;
	failures?: string[] | null;
	errorCodes: string[];
	data: AddressValidationResult;
}

export interface ApproveSignupRequestCommand {
	id: string;
}

/** @format int32 */
export enum AuditAction {
	Unknown = 0,
	Insert = 1,
	Read = 2,
	Update = 3,
	Delete = 4,
	Login = 5,
	Logout = 6,
	Export = 7,
	Import = 8
}

export interface AuditLogFiltrationDto {
	search?: string | null;
	actionType: AuditAction;
	/** @format date-time */
	dateFrom?: string | null;
	/** @format date-time */
	dateTo?: string | null;
}

export interface AuditLogViewModel {
	/** @format date-time */
	created?: string | null;
	createdBy?: string | null;
	/** @format date-time */
	lastModified?: string | null;
	lastModifiedBy?: string | null;
	action: AuditAction;
	entityId?: string | null;
	entityType?: string | null;
	changes: Change[];
	rawData: string;
}

export interface AuditLogViewModelPaginatedResponse {
	succeeded: boolean;
	message?: string | null;
	errors?: string[] | null;
	failures?: string[] | null;
	errorCodes: string[];
	data: AuditLogViewModel;
	/** @format int32 */
	totalCount: number;
}

export interface BooleanResponse {
	succeeded: boolean;
	message?: string | null;
	errors?: string[] | null;
	failures?: string[] | null;
	errorCodes: string[];
	data: boolean;
}

export interface CancelEnrollmentCommand {
	/** @format uuid */
	enrollmentId: string;
	/** @format int32 */
	agentId: number;
}

export interface CancelOnboardingCommand {
	/** @format uuid */
	id: string;
	cancellationReasonCode: OnboardingCancellationReason;
	cancellationReasonText?: string | null;
}

export interface CapturePersonalInfoCommand {
	/** @format uuid */
	id: string;
	phone?: string | null;
	email?: string | null;
}

export interface CarrierCodeViewModel {
	/** @format int32 */
	id: number;
	/** @format int32 */
	code: number;
	description: string;
}

export interface Change {
	columnName: string;
	originalValue: any;
	newValue: any;
}

export interface ChangeAvailabilityCommand {
	id: string;
	enabled: boolean;
}

export interface ChangeEnrollmentLinkAvailabilityCommand {
	/** @format uuid */
	id: string;
	enabled: boolean;
}

export interface CheckAvailabilityCommand {
	zip: string;
}

export interface CheckContactDto {
	firstName: string;
	lastName: string;
	/** @format date-time */
	birthDate?: string | null;
	phoneNumber: string;
	email: string;
	retailUserId: string;
	address: RetailAddressDto;
}

export interface CheckContactDtoResponse {
	succeeded: boolean;
	message?: string | null;
	errors?: string[] | null;
	failures?: string[] | null;
	errorCodes: string[];
	data: CheckContactDto;
}

export interface CheckEligibilityCommand {
	/** @format uuid */
	customerId: string;
}

export interface CheckOptimumAvailabilityQuery {
	zip: string;
	address: string;
	apartmentNum?: string | null;
	city: string;
	state: string;
}

export interface CheckSelfEnrollmentAvailabilityCommand {
	/** @format uuid */
	enrollmentLinkId: string;
	zip: string;
}

export interface ConsentCommand {
	/** @format uuid */
	id: string;
	termsAndConditionsAccepted: boolean;
}

export interface Coordinate {
	/** @format double */
	x: number;
	/** @format double */
	y: number;
	/** @format double */
	z: number;
	/** @format double */
	m: number;
	coordinateValue: Coordinate;
	isValid: boolean;
}

export interface CreateEnrollmentLinkCommand {
	name: string;
	description: string;
	flows: EnrollmentLinkFlowFlags;
	path: string;
	/** @format int32 */
	associatedClecId: number;
	/** @format int32 */
	associatedAgentId: number;
	/** @format date-time */
	expirationDate: string;
}

export interface CreateLifelineCustomerCommand {
	/** @format uuid */
	customerId: string;
}

export interface CreateLifelineCustomerViewModel {
	createLifelineStatus: CreateLifelineStatus;
	messages?: string[] | null;
	reviewResponse?: string | null;
	customerOnboardingStatus: CustomerOnboardingStatus;
	status: OrderStatusGeneral;
}

export interface CreateLifelineCustomerViewModelResponse {
	succeeded: boolean;
	message?: string | null;
	errors?: string[] | null;
	failures?: string[] | null;
	errorCodes: string[];
	data: CreateLifelineCustomerViewModel;
}

/** @format int32 */
export enum CreateLifelineStatus {
	Rejected = 0,
	Accepted = 1,
	Pending = 2
}

export interface CreateOptimumEnrollmentCommand {
	availability: OptimumAvailability;
	firstName: string;
	lastName: string;
	phoneNumber: string;
	email: string;
	address: AddressDto;
	/** @format uuid */
	serviceOptionId?: string | null;
	portExistingNumber: boolean;
	accountName?: string | null;
	accountPin?: string | null;
	accountPhoneNumber?: string | null;
	addPhoneLine: boolean;
	addStaticIp: boolean;
	/** @format uuid */
	emailVerificationId: string;
	/** @format int32 */
	agentId: number;
	/** @format int32 */
	carrierId: number;
}

export interface CreateOptimumEnrollmentDto {
	/** @format uuid */
	resumeOrderId?: string | null;
	enrollment: OptimumEnrollmentDto;
}

export interface CreateOptimumEnrollmentDtoResponse {
	succeeded: boolean;
	message?: string | null;
	errors?: string[] | null;
	failures?: string[] | null;
	errorCodes: string[];
	data: CreateOptimumEnrollmentDto;
}

export interface CustomerAcpBenefitSignedDto {
	acpBenefitTransfer?: boolean | null;
	alreadyHaveAcp?: boolean | null;
	termsAccepted?: boolean | null;
}

export interface CustomerAcpSignedDto {
	acpFormSigned?: boolean | null;
	acpSignedDateTimeNaive?: string | null;
	acpSignedTimeZone: AcpTimeZones;
}

export interface CustomerAddressDto {
	/** @format uuid */
	id?: string | null;
	zip?: string | null;
	address?: string | null;
	apartmentNum?: string | null;
	city: string;
	state: string;
	/** @format double */
	longitude?: number | null;
	/** @format double */
	latitude?: number | null;
	validated: boolean;
	tribalEnabled?: boolean | null;
}

export interface CustomerContactDto {
	/** @format uuid */
	emailVerificationId?: string | null;
	emailVerificationStatus: EmailVerificationStatus;
	email?: string | null;
	hasNoEmail: boolean;
	primaryPhone?: string | null;
	phoneVerified: boolean;
	secondaryPhone?: string | null;
	alternativeContact?: string | null;
}

export interface CustomerDeliveryMethodDto {
	deliveryMethod: DeliveryMethod;
	imei?: string | null;
}

export interface CustomerFiltrationDto {
	search?: string | null;
	enrollmentId?: string | null;
	firstName?: string | null;
	lastName?: string | null;
	primaryPhone?: string | null;
	email?: string | null;
	ssn?: string | null;
	status: OrderStatusGeneral;
	/** @format int32 */
	agentId?: number | null;
}

export interface CustomerListViewModel {
	/** @format uuid */
	id: string;
	enrollmentId: string;
	status: OrderStatusGeneral;
	firstName: string;
	lastName: string;
	/** @format date-time */
	birthDate: string;
	zip: string;
	ssn: string;
	primaryPhone: string;
	email: string;
	deliveryMethod: DeliveryMethod;
	esn?: string | null;
	lifelineEnrollmentType: LifelineEnrollmentType;
	currentStep: OnboardingStep;
	deviceType: DeviceType;
	createLifelineStatus: CreateLifelineStatus;
	state?: string | null;
	/** @format date-time */
	activatedAt?: string | null;
	/** @format date-time */
	lastModified?: string | null;
	/** @format uuid */
	paymentDeviceId?: string | null;
}

export interface CustomerListViewModelIEnumerablePaginatedResponse {
	succeeded: boolean;
	message?: string | null;
	errors?: string[] | null;
	failures?: string[] | null;
	errorCodes: string[];
	data: CustomerListViewModel[];
	/** @format int32 */
	totalCount: number;
}

/** @format int32 */
export enum CustomerOnboardingStatus {
	Created = 1,
	CheckedEligibility = 3,
	CompletedNv = 5,
	NladVerified = 7,
	TransferAcp = 8,
	CreatedLifeline = 9,
	Paid = 15,
	Handover = 20
}

export interface CustomerPersonalInfoDto {
	/** @format uuid */
	id?: string | null;
	firstName?: string | null;
	middleName?: string | null;
	lastName?: string | null;
	/** @format date-time */
	birthDate?: string | null;
	ssn?: string | null;
	tribalId?: string | null;
	/** @format uuid */
	documentTypeId?: string | null;
	idVerificationStatus: IdVerificationStatuses;
}

export interface CustomerProgramInfoDTO {
	householdAdult: boolean;
	householdLifeline?: boolean | null;
	householdShared?: boolean | null;
	programCode?: string | null;
	programName?: string | null;
	federalPublicHousingProgram: FederalPublicHousingProgram;
	eligibilityOption: EligibilityOption;
	/** @format int32 */
	personHouseholdCount?: number | null;
}

export interface CustomerViewModel {
	/** @format uuid */
	id?: string | null;
	currentStep: OnboardingStep;
	onboardingStatus: CustomerOnboardingStatus;
	status: OrderStatusGeneral;
	lifelineEnrollmentType: LifelineEnrollmentType;
	createLifelineStatus: CreateLifelineStatus;
	enrollmentId: string;
	programInfo: CustomerProgramInfoDTO;
	personalInfo: CustomerPersonalInfoDto;
	bqpEnabled: boolean;
	bqpPersonalInfo: CustomerPersonalInfoDto;
	acpSigned: CustomerAcpSignedDto;
	acpBenefitSigned: CustomerAcpBenefitSignedDto;
	contactInfo: CustomerContactDto;
	addressInfo: CustomerAddressDto;
	hasMailAddress: boolean;
	mailAddressInfo: CustomerAddressDto;
	penaltyOfPerjury: PenaltyOfPerjuryDto;
	delivery: CustomerDeliveryMethodDto;
	deviceType: DeviceType;
	/** @format int32 */
	planId?: number | null;
	mdn?: string | null;
	deviceUnavailable: boolean;
	/** @format int32 */
	carrierId: number;
	penaltyOfPerjuryAccepted: boolean;
	nationalVerifierStatus: NationalVerifierStatus;
	applicationId?: string | null;
	esn?: string | null;
	paymentMethod: PaymentMethod;
	existingAcpCustomer?: boolean | null;
	enrollmentDetail: TelgooEnrollmentDetailDto;
	ineligibleForTransfer: boolean;
	transferException: TransferExceptionCode;
	/** @format uuid */
	paymentDeviceId?: string | null;
	proofsUploaded?: boolean | null;
}

export interface CustomerViewModelResponse {
	succeeded: boolean;
	message?: string | null;
	errors?: string[] | null;
	failures?: string[] | null;
	errorCodes: string[];
	data: CustomerViewModel;
}

export interface DeclineSignupRequestCommand {
	id: string;
}

/** @format int32 */
export enum DeliveryMethod {
	Handover = 0,
	Shipment = 1
}

export interface DeviceCompatibilityDto {
	esimCompatibility: EsimCompatibilityCode;
	simAvailable: boolean;
}

export interface DeviceCompatibilityDtoResponse {
	succeeded: boolean;
	message?: string | null;
	errors?: string[] | null;
	failures?: string[] | null;
	errorCodes: string[];
	data: DeviceCompatibilityDto;
}

/** @format int32 */
export enum DeviceType {
	Sim = 0,
	FreePhone = 1,
	SubsidizedTablet = 2,
	PaidPhone = 3
}

/** @format int32 */
export enum DocumentTypeCode {
	Ssn = 1,
	TribalId = 2,
	DriverLicense = 3,
	MilitaryId = 4,
	Passport = 5,
	TaxpayerId = 6,
	OtherGovernmentId = 7
}

/** @format int32 */
export enum EligibilityOption {
	Program = 1,
	Income = 2
}

export interface EligibilityStatusCheckCommand {
	/** @format uuid */
	customerId: string;
}

/** @format int32 */
export enum EmailVerificationStatus {
	None = 0,
	Pending = 1,
	Verified = 2
}

export interface EmailVerificationStatusResponse {
	succeeded: boolean;
	message?: string | null;
	errors?: string[] | null;
	failures?: string[] | null;
	errorCodes: string[];
	data: EmailVerificationStatus;
}

export interface EmployeeFiltrationDto {
	search?: string | null;
	status: ActivationStatus;
	/** @format int32 */
	clecId?: number | null;
	role?: string | null;
}

/** @format int32 */
export enum EnrollmentLinkFlowFlags {
	SelfEnrollment = 1,
	Optimum = 2
}

export interface EnrollmentLinkViewModel {
	/** @format uuid */
	id: string;
	name: string;
	path: string;
	description: string;
	nonce: string;
	token: string;
	flows: EnrollmentLinkFlowFlags;
	enabled: boolean;
	/** @format int32 */
	associatedClecId: number;
	associatedClecDescription: string;
	/** @format int32 */
	associatedAgentId: number;
	/** @format date-time */
	expirationDate: string;
}

/** @format int32 */
export enum EsimCompatibilityCode {
	Compatible = 1,
	NotCompatible = 2,
	NotAvailable = 3
}

export interface EsnImeiFiltrationDto {
	search?: string | null;
	active?: boolean | null;
}

export interface ExitCommand {
	/** @format uuid */
	id: string;
	reasonCode: WebEnrollmentExitReason;
	reasonText?: string | null;
}

export interface ExitOnboardingCommand {
	/** @format uuid */
	id: string;
	reasonCode: OnboardingExitReason;
	reasonText?: string | null;
	/** @format date-time */
	continueOn: string;
}

export interface ExportOptimumEnrollmentResponseViewModel {
	fileName: string;
	file: string;
}

export interface ExportOptimumEnrollmentResponseViewModelResponse {
	succeeded: boolean;
	message?: string | null;
	errors?: string[] | null;
	failures?: string[] | null;
	errorCodes: string[];
	data: ExportOptimumEnrollmentResponseViewModel;
}

export interface ExportOptimumEnrollmentsCommand {
	enrollmentsFilters: OptimumEnrollmentFiltrationDto;
}

/** @format int32 */
export enum FederalPublicHousingProgram {
	HousingChoiceVoucherProgram = 1,
	ProjectBasedRentalAssistance = 2,
	PublicHousing = 3,
	AffordableHousingProgramsForAmericanIndians = 4
}

export interface GetAgentsByClecIdQuery {
	/** @format int32 */
	associatedClecId: number;
}

export interface GetAgentsQuery {
	search: string;
}

export interface GetAuditLogsQuery {
	/**
	 * @format int32
	 * @min 1
	 * @max 50
	 */
	pageSize: number;
	/** @format int32 */
	pageNumber: number;
	filtration: AuditLogFiltrationDto;
	sorting: SortingDto;
}

export interface GetCustomersCountsQuery {
	/** @format date */
	dateFrom: string;
	/** @format date */
	dateTo: string;
	enrollmentTypes: LifelineEnrollmentType[];
}

export interface GetCustomersQuery {
	/** @format int32 */
	pageNumber: number;
	/**
	 * @format int32
	 * @min 1
	 * @max 50
	 */
	pageSize: number;
	filtration: CustomerFiltrationDto;
	sorting: SortingDto;
}

export interface GetDashboardCountsQuery {
	/** @format date */
	dateFrom: string;
	/** @format date */
	dateTo: string;
}

export interface GetEmployeesQuery {
	/**
	 * @format int32
	 * @min 1
	 * @max 50
	 */
	pageSize: number;
	/** @format int32 */
	pageNumber: number;
	filtration: EmployeeFiltrationDto;
}

export interface GetEsnForImeisQuery {
	/**
	 * @format int32
	 * @min 1
	 * @max 50
	 */
	pageSize: number;
	/** @format int32 */
	pageNumber: number;
	filtration: EsnImeiFiltrationDto;
}

export interface GetOptimumEnrollmentsQuery {
	/**
	 * @format int32
	 * @min 1
	 * @max 50
	 */
	pageSize: number;
	/** @format int32 */
	pageNumber: number;
	filtration: OptimumEnrollmentFiltrationDto;
}

export interface GetOrderStatusCommand {
	/** @format uuid */
	customerId: string;
}

export interface GetPaymentDetailsCommand {
	/** @format uuid */
	customerId: string;
	paymentMethod: PaymentMethod;
}

export interface GetPlansListQuery {
	/** @format uuid */
	id?: string | null;
	isTribal?: boolean | null;
	zip: string;
	lifelineEnrollmentType: LifelineEnrollmentType;
}

export interface GetProgramsListQuery {
	zip: string;
	lifelineEnrollmentType: LifelineEnrollmentType;
	tribalEnabled: boolean;
}

export interface GetRetailDeviceTypesQuery {
	/** @format uuid */
	id: string;
	deviceOption: RetailDeviceSelectionOption;
}

export interface GetRetailEnrollmentsQuery {
	/**
	 * @format int32
	 * @min 1
	 * @max 50
	 */
	pageSize: number;
	/** @format int32 */
	pageNumber: number;
	filtration: RetailEnrollmentFiltrationDto;
}

export interface GetSelfEnrollmentPaymentDetailsCommand {
	/** @format uuid */
	selfEnrollmentId: string;
	/** @format uuid */
	enrollmentLinkId: string;
	deviceType: DeviceType;
	/** @format int32 */
	planId: number;
}

export interface GetSelfEnrollmentPlansListQuery {
	/** @format uuid */
	enrollmentLinkId: string;
	/** @format uuid */
	id?: string | null;
	zip: string;
	email: string;
	lifelineEnrollmentType: LifelineEnrollmentType;
}

export interface GetSelfEnrollmentsCountsQuery {
	/** @format date */
	dateFrom: string;
	/** @format date */
	dateTo: string;
	enrollmentTypes: LifelineEnrollmentType[];
}

export interface GetSelfEnrollmentsQuery {
	/** @format int32 */
	pageNumber: number;
	/** @format int32 */
	pageSize: number;
	filtration: SelfEnrollmentFiltrationDto;
	sorting: SortingDto;
}

export interface GetSignupRequestsQuery {
	/** @format int32 */
	pageNumber: number;
	/**
	 * @format int32
	 * @min 1
	 * @max 50
	 */
	pageSize: number;
}

export interface GetTotalOrdersCountsQuery {
	/** @format date */
	dateFrom: string;
	/** @format date */
	dateTo: string;
	enrollmentTypes: LifelineEnrollmentType[];
}

export interface GetTotalTransactionsCostQuery {
	/** @format date */
	dateFrom: string;
	/** @format date */
	dateTo: string;
	paymentMethods: PaymentMethod[];
	orderTypes: OrderType[];
}

export interface GetWebEnrollmentLinksQuery {
	/** @format int32 */
	pageSize: number;
	/** @format int32 */
	pageNumber: number;
	search?: string | null;
}

export interface GetZipProgramStatusesQuery {
	/**
	 * @format int32
	 * @min 1
	 * @max 50
	 */
	pageSize: number;
	/** @format int32 */
	pageNumber: number;
	filtration: ZipProgramStatusFiltrationDto;
}

export interface GetZipProgramsQuery {
	zip: string;
	enrollmentLinkId?: string | null;
}

export interface HandoverActivationCommand {
	/** @format uuid */
	customerId: string;
	paymentMethod: PaymentMethod;
	esn: string;
	nearbyZip?: string | null;
	cardType?: string | null;
	cardNumber?: string | null;
	cvv?: string | null;
	expirationMonth?: string | null;
	expirationYear?: string | null;
	nameOnCard?: string | null;
}

export interface HubspotEventPayload {
	/** @format int64 */
	appId: number;
	/** @format int64 */
	eventId: number;
	/** @format int64 */
	subscriptionId: number;
	/** @format int64 */
	portalId: number;
	/** @format int64 */
	occurredAt: number;
	subscriptionType: string;
	/** @format int64 */
	objectId: number;
	changeSource: string;
	propertyName: string;
	propertyValue: string;
}

/** @format int32 */
export enum IdVerificationStatuses {
	NotVerified = 0,
	Fake = 1,
	Verified = 2
}

export interface InitLifelineEnrollmentCommand {
	/** @format uuid */
	id?: string | null;
	enrollmentId: string;
	lastName: string;
	/** @format date-time */
	dateOfBirth: string;
}

export interface InstallationInfoDto {
	/** @format date */
	installationDate: string;
	installationTimeWindow: InstallationTimeWindow;
	/** @format uuid */
	id?: string | null;
}

/** @format int32 */
export enum InstallationTimeWindow {
	FromEightToElevenAm = 1,
	FromElevenToTwoPm = 2,
	FromTwoToFivePm = 3
}

export interface InviteEmployeeCommand {
	firstName?: string | null;
	lastName?: string | null;
	email: string;
	activationStatus: ActivationStatus;
}

/** @format int32 */
export enum LetterType {
	Welcome = 0,
	WelcomeCorporate = 1,
	ResetPassword = 2,
	VerifyEmail = 3
}

/** @format int32 */
export enum LifelineEnrollmentType {
	Acp = 1,
	Lifeline = 2,
	AcpPlusLifeline = 3
}

export interface LoginEventCommand {
	data?: string | null;
}

export interface LogoutEventCommand {
	data?: string | null;
}

export interface MakePaymentCommand {
	/** @format uuid */
	customerId: string;
	paymentMethod: PaymentMethod;
	cardType?: string | null;
	cardNumber?: string | null;
	cvv?: string | null;
	expirationMonth?: string | null;
	expirationYear?: string | null;
	nameOnCard?: string | null;
	paymentMethodOption?: string | null;
	deviceId?: string | null;
}

export interface MarkAsUsedInvitationCommand {
	email: string;
	extension_invitationId: string;
}

export interface MaxSipCustomerExistsQuery {
	/** @format uuid */
	customerId: string;
}

/** @format int32 */
export enum Mvno {
	Maxsip = 0,
	Terracom = 1
}

export interface MvnoSetting {
	/** @format int32 */
	mvnoId: number;
	mvno: Mvno;
	agentId: string;
}

/** @format int32 */
export enum NationalVerifierStatus {
	PendingResolution = 0,
	PendingEligibility = 1,
	PendingReview = 2,
	PendingCert = 3,
	Complete = 4,
	InProgress = 5,
	PendingRecertificationResolution = 6
}

export interface NladVerifyCommand {
	/** @format uuid */
	customerId: string;
}

/** @format int32 */
export enum OnboardingCancellationReason {
	Other = 1,
	BenefitUnavailability = 2,
	PersonalReasons = 3,
	TechnicalIssues = 4,
	CompetitorSelection = 5,
	FinancialConstraints = 6
}

/** @format int32 */
export enum OnboardingExitReason {
	Other = 1,
	CustomerHasNoMoney = 2,
	WaitingForDocuments = 3,
	NotEligibleForNow = 4
}

/** @format int32 */
export enum OnboardingStep {
	Clec = 0,
	ZipBenefit = 1,
	PersonalInfo = 2,
	Contact = 3,
	Address = 4,
	Program = 5,
	Review = 6,
	DeviceAndPlan = 7,
	Payment = 8,
	AssignDevice = 9,
	Completed = 10
}

/** @format int32 */
export enum OptimumAvailability {
	Available = 1,
	NotAvailable = 2
}

export interface OptimumCheckAvailabilityDto {
	availability: OptimumAvailability;
	serviceOptions: OptimumServiceOptionListDto[];
}

export interface OptimumCheckAvailabilityDtoResponse {
	succeeded: boolean;
	message?: string | null;
	errors?: string[] | null;
	failures?: string[] | null;
	errorCodes: string[];
	data: OptimumCheckAvailabilityDto;
}

export interface OptimumCheckServiceabilityDto {
	availability: OptimumAvailability;
	serviceOptions: OptimumServiceOptionListDto[];
}

export interface OptimumCheckServiceabilityDtoResponse {
	succeeded: boolean;
	message?: string | null;
	errors?: string[] | null;
	failures?: string[] | null;
	errorCodes: string[];
	data: OptimumCheckServiceabilityDto;
}

export interface OptimumCheckServiceabilityQuery {
	/** @format uuid */
	enrollmentId: string;
}

export interface OptimumEnrollmentDto {
	/** @format uuid */
	id: string;
	enrollmentId: string;
	firstName: string;
	lastName: string;
	phoneNumber: string;
	email: string;
	addPhoneLine: boolean;
	addStaticIp: boolean;
	/** @format uuid */
	serviceOptionId?: string | null;
	serviceOption: OptimumServiceOptionDto;
	enrollmentStatus: OptimumEnrollmentStatus;
	status: OrderStatusGeneral;
	currentStep: OptimumStep;
	/** @format uuid */
	addressId: string;
	address: AddressDto;
	portExistingNumber: boolean;
	accountName?: string | null;
	accountPin?: string | null;
	accountPhoneNumber?: string | null;
	additionalInfo?: string | null;
	/** @format uuid */
	emailVerificationId: string;
	emailVerificationStatus: EmailVerificationStatus;
	/** @format date-time */
	nextVerificationEmailCanBeSendAt?: string | null;
	/** @format uuid */
	billingAddressId?: string | null;
	billingAddress: AddressDto;
	/** @format date-time */
	confirmedInstalledDate?: string | null;
	/** @format date-time */
	installedDate?: string | null;
	/** @format int32 */
	agentId: number;
	agentDisplayName: string;
	/** @format int32 */
	submittedAgentId?: number | null;
	submittedAgentDisplayName?: string | null;
	/** @format int32 */
	carrierId?: number | null;
	carrier: CarrierCodeViewModel;
	/** @format date-time */
	created?: string | null;
	/** @format date-time */
	submitDate?: string | null;
	/** @format date-time */
	activatedDate?: string | null;
	/** @format date-time */
	unsubscribedDate?: string | null;
	/** @format double */
	totalPrice: number;
	optimumInstallationInfos: InstallationInfoDto[];
}

export interface OptimumEnrollmentDtoPaginatedResponse {
	succeeded: boolean;
	message?: string | null;
	errors?: string[] | null;
	failures?: string[] | null;
	errorCodes: string[];
	data: OptimumEnrollmentDto;
	/** @format int32 */
	totalCount: number;
}

export interface OptimumEnrollmentDtoResponse {
	succeeded: boolean;
	message?: string | null;
	errors?: string[] | null;
	failures?: string[] | null;
	errorCodes: string[];
	data: OptimumEnrollmentDto;
}

export interface OptimumEnrollmentFiltrationDto {
	search?: string | null;
	enrollmentStatus: OptimumEnrollmentStatus;
	/** @format int32 */
	carrierId?: number | null;
	/** @format int32 */
	agentId?: number | null;
	/** @format int32 */
	submitAgentId?: number | null;
	/** @format uuid */
	serviceOptionId?: string | null;
}

/** @format int32 */
export enum OptimumEnrollmentStatus {
	Created = 1,
	NotServiceable = 2,
	PaymentFailed = 3,
	Paid = 4,
	WaitingInstallation = 5,
	InstallationCanceled = 6,
	Installed = 7,
	Activated = 8,
	Unsubscribed = 10,
	Error = 11,
	Cancelled = 13,
	WaitingCancellation = 14,
	WaitingDeactivation = 15
}

export interface OptimumMakePaymentCommand {
	/** @format uuid */
	enrollmentId: string;
	/** @format int32 */
	agentId: number;
	fullName: string;
	cardNumber: string;
	cvv: string;
	expirationMonth: string;
	expirationYear: string;
	billingAddress: AddressDto;
}

export interface OptimumSendVerificationEmailCommand {
	email: string;
	/** @format uuid */
	id?: string | null;
}

export interface OptimumSendVerifyPhoneCodeCommand {
	phoneNumber: string;
}

export interface OptimumServiceOptionDto {
	/** @format uuid */
	id: string;
	name: string;
	description?: string | null;
	/** @format int32 */
	dataMegabytes: number;
	/** @format double */
	price: number;
}

export interface OptimumServiceOptionListDto {
	/** @format uuid */
	id: string;
	name: string;
	description?: string | null;
	/** @format int32 */
	dataMegabytes: number;
	/** @format double */
	price: number;
}

export interface OptimumServiceOptionListDtoIEnumerableResponse {
	succeeded: boolean;
	message?: string | null;
	errors?: string[] | null;
	failures?: string[] | null;
	errorCodes: string[];
	data: OptimumServiceOptionListDto[];
}

/** @format int32 */
export enum OptimumStep {
	ClecSelect = 1,
	Availability = 2,
	CustomerInfo = 3,
	Installation = 4,
	Review = 5,
	Payment = 6
}

/** @format int32 */
export enum OrderDeliveryMethod {
	None = 0,
	Handover = 1,
	Shipment = 2
}

/** @format int32 */
export enum OrderErrorCode {
	UpdateEnrollment = 1,
	ValidateAddress = 2,
	CheckEligibility = 3,
	EligibilityStatusCheck = 4,
	NladVerify = 5,
	PaymentDetails = 6,
	MakePayment = 7,
	CreateLifeline = 8,
	HandoverActivation = 9,
	TransferAcp = 10,
	UploadProofs = 11
}

/** @format int32 */
export enum OrderStatusGeneral {
	InProgress = 1,
	OnHold = 2,
	Cancelled = 3,
	Approved = 4,
	Rejected = 5,
	Pending = 6,
	Completed = 7,
	Error = 8
}

/** @format int32 */
export enum OrderType {
	SapEnrollment = 0,
	SelfEnrollment = 1
}

export interface PaymentDeviceDto {
	deviceType: DeviceType;
	planDescription: string;
	/** @format double */
	devicePriceCode: number;
	paymentMethods: PaymentMethodFlags;
}

export interface PaymentDeviceDtoIEnumerableResponse {
	succeeded: boolean;
	message?: string | null;
	errors?: string[] | null;
	failures?: string[] | null;
	errorCodes: string[];
	data: PaymentDeviceDto[];
}

/** @format int32 */
export enum PaymentMethod {
	Cash = 0,
	CreditCard = 1
}

/** @format int32 */
export enum PaymentMethodFlags {
	None = 0,
	Cash = 1,
	CreditCard = 2
}

export interface PaymentMethodListResponse {
	succeeded: boolean;
	message?: string | null;
	errors?: string[] | null;
	failures?: string[] | null;
	errorCodes: string[];
	data: PaymentMethod[];
}

export interface PenaltyOfPerjuryDto {
	accepted: boolean;
}

export interface PortInSendSupportEmailCommand {
	/** @format uuid */
	id: string;
	portInError: string;
}

/** @format int32 */
export enum PortInStatus {
	NotPerformed = 1,
	InProgress = 2,
	ResolutionRequired = 3,
	Successful = 4,
	Error = 5,
	Cancelled = 6
}

export interface QueryPortInCommand {
	/** @format uuid */
	id: string;
}

export interface QueryPortInDto {
	portInStatus: PortInStatus;
	message?: string | null;
}

export interface QueryPortInDtoResponse {
	succeeded: boolean;
	message?: string | null;
	errors?: string[] | null;
	failures?: string[] | null;
	errorCodes: string[];
	data: QueryPortInDto;
}

export interface ReprocessCommand {
	/** @format uuid */
	id: string;
	enrollmentType: LifelineEnrollmentType;
}

export interface ResumeOnboardingCommand {
	/** @format uuid */
	customerId: string;
}

export interface ResumeOptimumEnrollmentCommand {
	/** @format uuid */
	enrollmentId: string;
}

export interface ResumeSelfEnrollmentCommand {
	/** @format uuid */
	selfEnrollmentId: string;
}

export interface RetailAddressDto {
	/** @format uuid */
	id?: string | null;
	zip: string;
	address: string;
	apartmentNum?: string | null;
	city: string;
	state: string;
	/** @format double */
	longitude?: number | null;
	/** @format double */
	latitude?: number | null;
}

export interface RetailContactDetailsDto {
	enrollment: RetailEnrollmentDto;
	verificationEmailSent: boolean;
}

export interface RetailContactDetailsDtoResponse {
	succeeded: boolean;
	message?: string | null;
	errors?: string[] | null;
	failures?: string[] | null;
	errorCodes: string[];
	data: RetailContactDetailsDto;
}

export interface RetailCreateOrderCommand {
	/** @format uuid */
	id: string;
	esn?: string | null;
}

export interface RetailCreateOrderDto {
	mdn?: string | null;
	qrActivationCode?: string | null;
	status: RetailEnrollmentStatus;
	esimAllocationFailed: boolean;
}

export interface RetailCreateOrderDtoResponse {
	succeeded: boolean;
	message?: string | null;
	errors?: string[] | null;
	failures?: string[] | null;
	errorCodes: string[];
	data: RetailCreateOrderDto;
}

/** @format int32 */
export enum RetailDeviceSelectionOption {
	New = 1,
	Existing = 2
}

/** @format int32 */
export enum RetailDeviceType {
	Sim = 1,
	Phone = 2,
	Tablet = 3
}

export interface RetailDeviceTypeIEnumerableResponse {
	succeeded: boolean;
	message?: string | null;
	errors?: string[] | null;
	failures?: string[] | null;
	errorCodes: string[];
	data: RetailDeviceType[];
}

export interface RetailDocument {
	/** @format uuid */
	id?: string | null;
	typeCode: DocumentTypeCode;
}

export interface RetailEnrollmentDto {
	/** @format uuid */
	id: string;
	enrollmentId: string;
	zip: string;
	/** @format int32 */
	planId: number;
	retailStep: RetailStep;
	status: RetailEnrollmentStatus;
	userFriendlyRetailEnrollmentStatus: UserFriendlyRetailEnrollmentStatus;
	firstName?: string | null;
	middleName?: string | null;
	lastName?: string | null;
	/** @format date-time */
	birthDate?: string | null;
	verificationValue?: string | null;
	verificationDocument: RetailDocument;
	phoneNumber?: string | null;
	email?: string | null;
	deviceType: RetailDeviceType;
	deliveryMethod: DeliveryMethod;
	termsAndConditionsAccepted: boolean;
	esn?: string | null;
	mdn?: string | null;
	qrActivationCode?: string | null;
	emailVerificationStatus: EmailVerificationStatus;
	/** @format date-time */
	nextVerificationEmailCanBeSendAt?: string | null;
	hasBillingAddress: boolean;
	address: RetailAddressDto;
	billingAddress: RetailAddressDto;
	/** @format int32 */
	carrierId?: number | null;
	/** @format date-time */
	created: string;
	subscriberNumberType: SubscriberNumberType;
	subscriberPhoneNumber?: string | null;
	subscriberAccountNumber?: string | null;
	subscriberPin?: string | null;
	deviceSelectionOption: RetailDeviceSelectionOption;
	existingDeviceImei?: string | null;
	esimCompatibility: EsimCompatibilityCode;
	simAvailable: boolean;
	esimAllocationFailed: boolean;
	simType: SimType;
	setAutoPay: boolean;
	portInStatus: PortInStatus;
}

export interface RetailEnrollmentDtoPaginatedResponse {
	succeeded: boolean;
	message?: string | null;
	errors?: string[] | null;
	failures?: string[] | null;
	errorCodes: string[];
	data: RetailEnrollmentDto;
	/** @format int32 */
	totalCount: number;
}

export interface RetailEnrollmentDtoResponse {
	succeeded: boolean;
	message?: string | null;
	errors?: string[] | null;
	failures?: string[] | null;
	errorCodes: string[];
	data: RetailEnrollmentDto;
}

export interface RetailEnrollmentFiltrationDto {
	search?: string | null;
	enrollmentId?: string | null;
	firstName?: string | null;
	lastName?: string | null;
	email?: string | null;
	/** @format int32 */
	agentId?: number | null;
	status: UserFriendlyRetailEnrollmentStatus;
}

/** @format int32 */
export enum RetailEnrollmentStatus {
	Created = 1,
	SetPaymentDetails = 9,
	Paid = 10,
	OrderCreated = 20,
	HandoverActivated = 25,
	SetAutoPay = 29,
	WaitingForPortIn = 33,
	Completed = 35
}

export interface RetailHandoverActivationCommand {
	/** @format uuid */
	id: string;
	esn: string;
}

export interface RetailMakePaymentCommand {
	/** @format uuid */
	id: string;
	nameOnCard: string;
	cardNumber: string;
	cvv: string;
	expirationMonth: string;
	expirationYear: string;
	hasBillingAddress: boolean;
	billingAddress: RetailAddressDto;
}

export interface RetailPaymentDetailsDto {
	totalAmount: string;
	planPrice: string;
	devicePrice: string;
}

export interface RetailPaymentDetailsDtoResponse {
	succeeded: boolean;
	message?: string | null;
	errors?: string[] | null;
	failures?: string[] | null;
	errorCodes: string[];
	data: RetailPaymentDetailsDto;
}

export interface RetailPaymentDto {
	invoiceNumber: string;
	enrollmentStatus: RetailEnrollmentStatus;
}

export interface RetailPaymentDtoResponse {
	succeeded: boolean;
	message?: string | null;
	errors?: string[] | null;
	failures?: string[] | null;
	errorCodes: string[];
	data: RetailPaymentDto;
}

export interface RetailPlanDto {
	/** @format int32 */
	id: number;
	name: string;
	dataGigabytes: string;
}

export interface RetailPlanDtoIEnumerableResponse {
	succeeded: boolean;
	message?: string | null;
	errors?: string[] | null;
	failures?: string[] | null;
	errorCodes: string[];
	data: RetailPlanDto[];
}

export interface RetailSendVerificationEmailCommand {
	/** @format uuid */
	id: string;
	email: string;
}

export interface RetailServiceAvailabilityDto {
	enrollmentId: string;
	city: string;
	state?: string | null;
}

export interface RetailServiceAvailabilityDtoResponse {
	succeeded: boolean;
	message?: string | null;
	errors?: string[] | null;
	failures?: string[] | null;
	errorCodes: string[];
	data: RetailServiceAvailabilityDto;
}

/** @format int32 */
export enum RetailStep {
	Clec = 1,
	PlanAndZip = 2,
	PersonalDetails = 3,
	ContactInfo = 4,
	Address = 5,
	DeviceDelivery = 6,
	PhoneSelection = 7,
	Review = 8,
	Payment = 9,
	Activation = 10
}

export interface RetailUpdateAddressCommand {
	/** @format uuid */
	id: string;
	address: RetailAddressDto;
}

export interface RetailUpdateContactDetailsCommand {
	/** @format uuid */
	id: string;
	phoneNumber: string;
	password: string;
}

export interface RetailUpdatePersonalDetailsCommand {
	/** @format uuid */
	id: string;
	firstName: string;
	lastName: string;
	/** @format date-time */
	birthDate?: string | null;
}

export interface RetailUpdatePhoneSelectionCommand {
	/** @format uuid */
	id: string;
	subscriberNumberType: SubscriberNumberType;
	subscriberPhoneNumber?: string | null;
	subscriberAccountNumber?: string | null;
	subscriberPin?: string | null;
}

export interface RetailVerifyEmailCommand {
	/** @format uuid */
	id: string;
	code: string;
}

export interface SaveRetailZipAndPlanCommand {
	/** @format uuid */
	id?: string | null;
	zip: string;
	enrollmentId: string;
	/** @format int32 */
	planId: number;
	planName?: string | null;
	/** @format int32 */
	agentId: number;
	/** @format int32 */
	carrierId: number;
}

export interface SelfEnrollmentAddressValidateCommand {
	/** @format uuid */
	enrollmentId: string;
	/** @format uuid */
	linkId: string;
	address: CustomerAddressDto;
	mailAddress: CustomerAddressDto;
	customer: SelfEnrollmentPersonalInfoDto;
	customerBqp: SelfEnrollmentPersonalInfoDto;
}

export interface SelfEnrollmentCheckEligibilityCommand {
	/** @format uuid */
	selfEnrollmentId: string;
	/** @format uuid */
	enrollmentLinkId: string;
}

export interface SelfEnrollmentCreateLifelineCustomerCommand {
	/** @format uuid */
	id: string;
	/** @format uuid */
	linkId: string;
}

export interface SelfEnrollmentEligibilityStatusCheckCommand {
	/** @format uuid */
	selfEnrollmentId: string;
	/** @format uuid */
	enrollmentLinkId: string;
}

export interface SelfEnrollmentFiltrationDto {
	search?: string | null;
	enrollmentId?: string | null;
	firstName?: string | null;
	lastName?: string | null;
	primaryPhone?: string | null;
	email?: string | null;
	ssn?: string | null;
	status: OrderStatusGeneral;
}

export interface SelfEnrollmentGetOrderStatusCommand {
	/** @format uuid */
	selfEnrollmentId: string;
	/** @format uuid */
	enrollmentLinkId: string;
}

export interface SelfEnrollmentGetProgramsListQuery {
	/** @format uuid */
	linkId: string;
	zip: string;
	lifelineEnrollmentType: LifelineEnrollmentType;
	tribalEnabled: boolean;
}

export interface SelfEnrollmentMakePaymentCommand {
	/** @format uuid */
	id: string;
	/** @format uuid */
	linkId: string;
	cardType?: string | null;
	cardNumber?: string | null;
	cvv?: string | null;
	expirationMonth?: string | null;
	expirationYear?: string | null;
	nameOnCard?: string | null;
}

export interface SelfEnrollmentNladVerifyCommand {
	/** @format uuid */
	selfEnrollmentId: string;
	/** @format uuid */
	enrollmentLinkId: string;
}

export interface SelfEnrollmentPersonalInfoDto {
	firstName: string;
	middleName?: string | null;
	lastName: string;
	/** @format date-time */
	birthDate: string;
	ssn?: string | null;
}

export interface SelfEnrollmentTransferAcpCommand {
	/** @format uuid */
	selfEnrollmentId: string;
	/** @format uuid */
	enrollmentLinkId: string;
	transferExceptionCode: TransferExceptionCode;
}

export interface SelfSignupCommand {
	name: string;
	description: string;
	/** @format int32 */
	associatedClecId: number;
	/** @format date-time */
	expirationDate: string;
}

export interface SendInvitationEmailCommand {
	email: string;
	type: LetterType;
	passwordLink?: string | null;
}

export interface SendVerificationEmailCommand {
	email: string;
	/** @format uuid */
	id?: string | null;
}

export interface SendVerificationEmailDto {
	status: EmailVerificationStatus;
	/** @format date-time */
	nextVerificationEmailCanBeSendAt: string;
	/** @format uuid */
	id?: string | null;
}

export interface SendVerificationEmailDtoResponse {
	succeeded: boolean;
	message?: string | null;
	errors?: string[] | null;
	failures?: string[] | null;
	errorCodes: string[];
	data: SendVerificationEmailDto;
}

export interface SendVerificationMessageCommand {
	phoneNumber: string;
}

export interface SetAutoPayCommand {
	/** @format uuid */
	id: string;
	nameOnCard?: string | null;
	cardNumber?: string | null;
	cvv?: string | null;
	expirationMonth?: string | null;
	expirationYear?: string | null;
}

export interface SetAutoPayDto {
	status: RetailEnrollmentStatus;
}

export interface SetAutoPayDtoResponse {
	succeeded: boolean;
	message?: string | null;
	errors?: string[] | null;
	failures?: string[] | null;
	errorCodes: string[];
	data: SetAutoPayDto;
}

export interface SignAcpConsentCommand {
	/** @format uuid */
	id: string;
	/** @format uuid */
	enrollmentLinkId: string;
	consentLifelineBenefitTransfer: boolean;
	consentSigned: boolean;
	/** @format date-time */
	consentSignedAt: string;
	consentSignedTimeZone: AcpTimeZones;
}

export interface SignConsentCommand {
	/** @format uuid */
	id: string;
	consentSigned: boolean;
	/** @format date-time */
	consentSignedAt: string;
	consentSignedTimeZone: AcpTimeZones;
}

/** @format int32 */
export enum SimType {
	Regular = 1,
	Esim = 2
}

export interface SortingDto {
	column: string;
	ascending: boolean;
}

export interface StringResponse {
	succeeded: boolean;
	message?: string | null;
	errors?: string[] | null;
	failures?: string[] | null;
	errorCodes: string[];
	data: string;
}

export interface SubmitOptimumEnrollmentCommand {
	/** @format uuid */
	id: string;
	/** @format int32 */
	agentId: number;
}

/** @format int32 */
export enum SubscriberNumberType {
	New = 1,
	KeepCurrent = 2
}

export interface TelgooEnrollmentDetailDto {
	enrollmentId: string;
	enrollmentStatus: TelgooEnrollmentStatus;
	childEnrollmentId?: string | null;
	childEnrollmentStatus: TelgooEnrollmentStatus;
}

/** @format int32 */
export enum TelgooEnrollmentStatus {
	Rejected = 0,
	Approved = 1,
	Pending = 2
}

export interface TransferAcpCommand {
	/** @format uuid */
	id: string;
	transferExceptionCode: TransferExceptionCode;
}

/** @format int32 */
export enum TransferExceptionCode {
	ImproperTransfer = 1,
	OperationsCeased = 2,
	MovedOutsideServiceArea = 3
}

export interface UpdateContactAndAddressCommand {
	/** @format uuid */
	id: string;
	retailUserId?: string | null;
	phoneNumber: string;
	password?: string | null;
	firstName: string;
	lastName: string;
	/** @format date-time */
	birthDate?: string | null;
	address: RetailAddressDto;
}

export interface UpdateContactCommand {
	/** @format uuid */
	id: string;
	/** @format uuid */
	enrollmentLinkId: string;
	personalInfo: CustomerPersonalInfoDto;
	bqpEnabled: boolean;
	bqpPersonalInfo: CustomerPersonalInfoDto;
	primaryPhone: string;
	addressInfo: CustomerAddressDto;
	mailAddressInfo: CustomerAddressDto;
}

export interface UpdateCustomerCommand {
	/** @format uuid */
	id?: string | null;
	currentStep: OnboardingStep;
	lifelineEnrollmentType: LifelineEnrollmentType;
	programInfo: CustomerProgramInfoDTO;
	personalInfo: CustomerPersonalInfoDto;
	bqpEnabled: boolean;
	bqpPersonalInfo: CustomerPersonalInfoDto;
	contactInfo: CustomerContactDto;
	acpSigned: CustomerAcpSignedDto;
	acpBenefitSigned: CustomerAcpBenefitSignedDto;
	addressInfo: CustomerAddressDto;
	hasMailAddress: boolean;
	mailAddressInfo: CustomerAddressDto;
	penaltyOfPerjury: PenaltyOfPerjuryDto;
	enrollmentId: string;
	/** @format int32 */
	agentId: number;
	/** @format int32 */
	carrierId: number;
}

export interface UpdateDeviceAndPlanCommand {
	/** @format uuid */
	id: string;
	/** @format uuid */
	enrollmentLinkId: string;
	/** @format int32 */
	planId: number;
	deviceType: DeviceType;
}

export interface UpdateDeviceCommand {
	/** @format uuid */
	id: string;
	deviceType: DeviceType;
	deliveryMethod: DeliveryMethod;
}

export interface UpdateDeviceDeliveryCommand {
	/** @format uuid */
	id: string;
	deviceType: RetailDeviceType;
	deviceSelectionOption: RetailDeviceSelectionOption;
	simType: SimType;
	imei?: string | null;
	deliveryMethod: DeliveryMethod;
}

export interface UpdateEmployeeCommand {
	/** @format int32 */
	id: number;
	authUserId: string;
	firstName: string;
	lastName: string;
	email: string;
	role: string;
	radId?: string | null;
	phone: string;
	orderDeliveryMethods: OrderDeliveryMethod;
	activationStatus: ActivationStatus;
	clecIdCodes: number[];
	mvnoSettings: MvnoSetting[];
}

export interface UpdateInstallationInfoCommand {
	/** @format uuid */
	id: string;
	installationInfos: InstallationInfoDto[];
	additionalInfo?: string | null;
	/** @format int32 */
	agentId: number;
}

export interface UpdateOptimumAddressCommand {
	/** @format uuid */
	id: string;
	address: AddressDto;
	availability: OptimumAvailability;
	/** @format int32 */
	agentId: number;
}

export interface UpdateOptimumEnrollmentCommand {
	/** @format uuid */
	enrollmentId: string;
	availability: OptimumAvailability;
	firstName: string;
	lastName: string;
	phoneNumber: string;
	email: string;
	/** @format uuid */
	serviceOptionId?: string | null;
	portExistingNumber: boolean;
	accountName?: string | null;
	accountPin?: string | null;
	accountPhoneNumber?: string | null;
	addPhoneLine: boolean;
	addStaticIp: boolean;
	/** @format int32 */
	agentId: number;
}

export interface UpdatePortInCommand {
	/** @format uuid */
	id: string;
	firstName: string;
	lastName: string;
	accountNumber: string;
	accountPin: string;
	esn: string;
}

export interface UpdateSelfEnrollmentProgramInfo {
	/** @format uuid */
	id: string;
	/** @format uuid */
	enrollmentLinkId: string;
	programInfo: CustomerProgramInfoDTO;
}

export interface UpdateZipProgramStatusCommand {
	zip: string;
}

export interface UploadProofCommand {
	/** @format uuid */
	id: string;
	idDocument: string;
	gaDocument: string;
}

/** @format int32 */
export enum UserFriendlyRetailEnrollmentStatus {
	InProgress = 1,
	WaitingForPayment = 2,
	Paid = 3,
	SimReadyForHandover = 4,
	EsimAllocatedSuccessfully = 5,
	ReadyForShipment = 6,
	HandoverActivated = 7,
	WaitingForPortIn = 8,
	Completed = 9
}

export interface ValidateAddressCommand {
	zip?: string | null;
	address?: string | null;
	apartmentNum?: string | null;
	city: string;
	state: string;
}

export interface ValidateInvitationCommand {
	email: string;
	extension_invitationId: string;
}

export interface VerificationResult {
	isValid: boolean;
	sid?: string | null;
	errors: string[];
	isInvalidCode: boolean;
}

export interface VerificationResultResponse {
	hasValue: boolean;
	value: VerificationResult;
}

export interface VerifyEmailCommand {
	/** @format uuid */
	id: string;
	code: string;
}

export interface VerifyPhoneCommand {
	phoneNumber: string;
	code: string;
}

/** @format int32 */
export enum WebEnrollmentExitReason {
	Other = 1,
	ChangedDecision = 2,
	WaitingNvApproval = 3,
	TechnicalIssues = 4,
	ExploringOtherOptions = 5,
	UnsureAboutService = 6,
	PreferAssistance = 7
}

export interface ZipProgramStatusFiltrationDto {
	search?: string | null;
	lifelineAvailable?: boolean | null;
	active?: boolean | null;
}
