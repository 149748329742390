import { ApiMethods } from './api-methods';

export class EnrollmentLinksApiMethods {
	private static controller: string = 'enrollmentLinks';

	private static wrapped(action: string): string {
		return ApiMethods.prefixed(this.controller, action);
	}
	public static create(): string {
		return this.wrapped('create');
	}
	public static list(): string {
		return this.wrapped(``);
	}
	public static availability(): string {
		return this.wrapped('availability');
	}

	public static get(path: string): string {
		return ApiMethods.prefixed('SelfEnrollment', `?path=${path}`);
	}
}
