import {
	ActivatedRouteSnapshot,
	Router,
	RouterStateSnapshot,
	UrlTree
} from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { EMPTY, Observable, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import {
	MsalBroadcastService,
	MsalGuardConfiguration,
	MsalService,
	MSAL_GUARD_CONFIG
} from '@azure/msal-angular';
import { BaseGuard } from './base.guard';
import { NgxPermissionsService } from 'ngx-permissions';
import { AbsoluteRoutes } from '../../shared/constants/routes.enum';

@Injectable()
export class RoleGuard extends BaseGuard {
	constructor(
		@Inject(MSAL_GUARD_CONFIG)
		protected override msalGuardConfig: MsalGuardConfiguration,
		protected override msalBroadcastService: MsalBroadcastService,
		protected override authService: MsalService,
		protected override location: Location,
		protected override router: Router,
		private ngxPermissionsService: NgxPermissionsService
	) {
		super(
			msalGuardConfig,
			msalBroadcastService,
			authService,
			location,
			router
		);
	}

	override activateHelper(
		state?: RouterStateSnapshot,
		route?: ActivatedRouteSnapshot
	): Observable<boolean | UrlTree> {
		let result = super.activateHelper(state, route);

		const expectedRoles: string[] = route
			? route.data['expectedRoles']
			: [];
		const permissions = this.ngxPermissionsService.getPermissions();

		if (Object.keys(permissions).length === 0) {
			this.router.navigateByUrl(AbsoluteRoutes.Sandbox);
			return EMPTY;
		}

		return result.pipe(
			concatMap(_ => {
				let activeAccount =
					this.authService.instance.getActiveAccount();

				if (
					!activeAccount &&
					this.authService.instance.getAllAccounts().length > 0
				) {
					activeAccount =
						this.authService.instance.getAllAccounts()[0];
				}

				// TODO: Logoff
				if (!activeAccount) {
					window.alert(
						'Token does not have roles claim. Please ensure that your account is assigned to an app role and then sign-out and sign-in again.'
					);
					return of(false);
				}

				// const hasRequiredRole = expectedRoles.some((role: string) => activeAccount?.idTokenClaims?.roles?.includes(role));
				const hasRequiredRole = expectedRoles.some(
					(role: string) => permissions[role]
				);
				if (!hasRequiredRole) {
					window.alert(
						'You do not have access as the expected role is not found. Please ensure that your account is assigned to an app role and then sign-out and sign-in again.'
					);
				}

				return of(hasRequiredRole);
			})
		);
	}
}
