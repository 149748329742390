import { Injectable, signal } from '@angular/core';
import { environment } from '@environment/environment';
import { FeatureFlagKeys } from './feature-flag-keys';

@Injectable({ providedIn: 'root' })
export class FeatureFlagService {
	private features = signal<Record<FeatureFlagKeys, boolean>>({ retail: false });

	getFeatureFlags() {
		const features = environment.featureFlags;
		this.features.set(features);
	}

	getFeature(feature: FeatureFlagKeys): boolean {
		return this.features()[feature];
	}
}
