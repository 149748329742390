import { ApiMethods } from './api-methods';

export class InvitationsApiMethods {
	private static controller: string = 'invitations';

	private static wrapped(action: string): string {
		return ApiMethods.prefixed(this.controller, action);
	}
	public static selfSignup(): string {
		return this.wrapped('self-signup');
	}
	public static list(): string {
		return this.wrapped('list');
	}
	public static availability(): string {
		return this.wrapped('availability');
	}
	public static sendEmail(): string {
		return this.wrapped('send-email');
	}
}
