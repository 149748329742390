import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse, PaginatedApiResponse } from 'src/app/core/interfaces/api-response.type';
import { GetZipsRequest, ZipProgramStatus } from '@core/models/zip-program-status';
import { ApiMethods } from 'src/app/core/utils/api/api-methods';
import { ZipApiMethods } from '@core/utils/api/zip-api-methods';
import { ImportZipViewModel } from '@core/models/import';

@Injectable({
	providedIn: 'root'
})
export class ZipService {
	constructor(private http: HttpClient) {}

	public getProgramStatus(zip: string): Observable<ApiResponse<ZipProgramStatus>> {
		return this.http.get<ApiResponse<ZipProgramStatus>>(ApiMethods.getZipProgramStatus(zip));
	}

	public getList(request: GetZipsRequest) {
		return this.http.post<PaginatedApiResponse<ZipProgramStatus[]>>(
			ZipApiMethods.getZips(),
			request
		);
	}

	public importDataFile(file: File) {
		const formData = new FormData();
		formData.append('file', file, file.name);
		return this.http.post<ApiResponse<ImportZipViewModel[]>>(
			ZipApiMethods.importDataFile(),
			formData
		);
	}

	public delete(zip: string): Observable<ApiResponse<any>> {
		return this.http.delete<ApiResponse<any>>(ZipApiMethods.deleteZip(zip));
	}
}
