import { GridColumn } from '@shared/components/grid/grid.component.type';
import { EmployeeSignupRequest } from '@core/models/employee';
import {
	mapSignupRequestStatusToLabel,
	SignupRequestStatus
} from '@modules/employees/pages/employees/employee.type';
import { match } from 'ts-pattern';

export const EmployeeSignupRequestsColumns: GridColumn<EmployeeSignupRequest>[] = [
	{
		field: 'createdDateTime',
		header: 'Created',
		isDate: true,
		dateFormat: 'mediumDate'
	},
	{
		field: 'status',
		header: 'Activation Status',
		formatter: getActivationStatusDescription,
		className: getActivationStatusClass
	},
	{
		field: 'clecId',
		header: 'CLEC'
		// formatter will be placed after the codes will be loaded
	},
	{
		field: 'lastName',
		header: 'Name',
		formatter: ({ firstName, lastName }) => `${firstName} ${lastName}`
	},
	{
		field: 'email',
		header: 'Email'
	},
	{
		field: 'mobilePhone',
		header: 'Phone'
	}
];

function getActivationStatusDescription(request: EmployeeSignupRequest): string {
	const { status, clecManagerId, maxsipManagerId } = request;

	if (status === SignupRequestStatus.Pending) {
		if (!clecManagerId) {
			return 'Pending CLEC approval';
		}
		if (!maxsipManagerId) {
			return 'Awaiting Maxsip Approval';
		}
	}

	const activationResponsible = maxsipManagerId ? 'Maxsip' : 'CLEC';
	return `${activationResponsible} ${mapSignupRequestStatusToLabel[status]}`;
}

function getActivationStatusClass({ status }: EmployeeSignupRequest): string {
	return match(status)
		.with(SignupRequestStatus.Pending, () => 'chip bg-light-primary text-primary')
		.with(SignupRequestStatus.Approved, () => 'chip bg-light-primary text-primary')
		.otherwise(() => 'chip bg-light-error text-primary');
}
