export enum LifelineEnrollmentType {
	Acp = 1,
	Lifeline = 2,
	AcpPlusLifeline = 3
}

export type LifelineEnrollmentTypeMap = {
	[key in LifelineEnrollmentType]: string;
};

export const lifelineEnrollmentTypeToTranslationKeyMap: LifelineEnrollmentTypeMap = {
	[LifelineEnrollmentType.Acp]: 'ACP',
	[LifelineEnrollmentType.Lifeline]: 'Lifeline',
	[LifelineEnrollmentType.AcpPlusLifeline]: 'AcpPlusLifeline'
};

export const enrolmentTypes = {
	acp: {
		value: LifelineEnrollmentType.Acp,
		labelKey: 'ACP',
		disabled: false
	},
	lifeline: {
		value: LifelineEnrollmentType.Lifeline,
		labelKey: 'Lifeline',
		disabled: true
	},
	combo: {
		value: LifelineEnrollmentType.AcpPlusLifeline,
		labelKey: 'AcpPlusLifeline',
		disabled: true
	}
};
