import { Component, EventEmitter, HostBinding, Input, OnChanges, Output } from '@angular/core';
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { TablerIconsModule } from 'angular-tabler-icons';
import { MaterialModule } from 'src/app/material.module';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { NavService } from '@core/services/nav.service';
import { CoreService } from '@core/services/core.service';

import { NavItem } from './nav-item';

@Component({
	selector: 'app-nav-item',
	standalone: true,
	imports: [TranslateModule, TablerIconsModule, MaterialModule, CommonModule],
	templateUrl: './nav-item.component.html',
	styleUrls: [],
	animations: [
		trigger('indicatorRotate', [
			state('collapsed', style({ transform: 'rotate(0deg)' })),
			state('expanded', style({ transform: 'rotate(180deg)' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4,0.0,0.2,1)'))
		])
	]
})
export class AppNavItemComponent implements OnChanges {
	@Output() toggleMobileLink: any = new EventEmitter<void>();
	@Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();

	expanded: boolean = false;
	disabled: boolean = false;
	options = this.settings.getOptions();
	@HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
	@Input() item: NavItem | any;
	@Input() depth: number;

	constructor(
		public navService: NavService,
		public router: Router,
		private settings: CoreService
	) {
		if (this.depth === undefined) {
			this.depth = 0;
		}
	}

	public ngOnChanges(): void {
		this.navService.currentUrl.subscribe((url: string) => {
			if (this.item.route && url) {
				this.expanded = url.indexOf(`/${this.item.route}`) === 0;
				this.ariaExpanded = this.expanded;
			}
		});
	}

	public isActive(url: string): boolean {
		return this.router.isActive(url, {
			paths: 'subset',
			fragment: 'ignored',
			matrixParams: 'ignored',
			queryParams: 'ignored'
		});
	}

	public onItemSelected(item: NavItem): void {
		if (item.disabled) {
			return;
		}

		if (!item.children || !item.children.length) {
			this.router.navigate([item.route]);
		}

		if (item.children && item.children.length) {
			this.expanded = !this.expanded;
		}
		//scroll
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
		if (!this.expanded) {
			if (window.innerWidth < this.options.menuMaxCollapseWidth) {
				this.notify.emit();
			}
		}
	}

	public onSubItemSelected(item: NavItem): void {
		if (!item.children || !item.children.length) {
			if (this.expanded && window.innerWidth < this.options.menuMaxCollapseWidth) {
				this.notify.emit();
			}
		}
	}
}
