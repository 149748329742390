import { LifelineEnrollmentType } from '@modules/customer/constants/lifeline-enrollment-type.enum';
import {
	CancelOnboardingCommand,
	CustomerAddressInfo,
	CustomerUpdateDto,
	ExitOnboardingCommand,
	GetCustomersRequest
} from '../../models/customer.type';
import { HandoverActivationCommand } from '@core/interfaces/middleware/activate-handover-request.type';
import { CustomerOnboardingStatus } from '@core/models/onboarding-status';
import { DestroyRef } from '@angular/core';
import { LatLng } from '@core/interfaces/address-lookup-result.type';
import { DeliveryMethod } from '@modules/customer/components/device-delivery-form/device-delivery-form.types';
import { DeviceType, OrderStatusGeneral } from '@core/api-client/data-contracts';

export namespace CustomerActions {
	export class SetEnrollmentId {
		static readonly type = '[Customer] Set enrollment id';
		constructor(public enrollmentId: string) {}
	}

	export class ValidateAddress {
		static readonly type = '[Customer] Validate Address';

		constructor(
			public customerId: string,
			public address: CustomerAddressInfo,
			public mailAddress: CustomerAddressInfo | null
		) {}
	}

	export class ResetAddressValidationResult {
		static readonly type = '[Customer] Reset Address Validation Result';

		constructor(public mail: boolean) {}
	}

	export class GetProgramStatus {
		static readonly type = '[Customer] Get Program Status';

		constructor(public zip: string) {}
	}

	export class GetDocumentTypes {
		static readonly type = '[Customer] Get Document Types';

		constructor() {}
	}

	export class GetEligibilityPrograms {
		static readonly type = '[Customer] Get Eligibility Programs';

		constructor(
			public zip: string,
			public lifelineEnrollmentType: LifelineEnrollmentType,
			public tribalEnabled: boolean
		) {}
	}

	export class AddCustomer {
		static readonly type = '[Customer] Add Customer';

		constructor(public customer: CustomerUpdateDto) {}
	}

	export class UpdateCustomer {
		static readonly type = '[Customer] Update Customer';

		constructor(public customer: CustomerUpdateDto) {}
	}

	export class UpdateDevicePlan {
		static readonly type = '[Customer] Update device type';
		constructor(
			readonly deviceType: DeviceType,
			readonly deliveryMethod: DeliveryMethod
		) {}
	}

	export class GetCustomerById {
		static readonly type = '[Customer] Get Customer By Id';

		constructor(public customerId: string) {}
	}

	export class ResetCurrentCustomer {
		static readonly type = '[Customer] Reset Current Customer';
	}

	export class ResumeOnboarding {
		static readonly type = '[Customer] Resume Onboarding';

		constructor(
			public customerId: string,
			readonly destroyRef: DestroyRef
		) {}
	}

	export class UploadCustomerDocument {
		static readonly type = '[Customer] Upload Customer Document';

		constructor(
			public customerId: string,
			public file: File
		) {}
	}

	export class CancelOnboarding {
		static readonly type = '[Customer] Cancel Onboarding';

		constructor(public cancelCommand: CancelOnboardingCommand) {}
	}

	export class ExitOnboarding {
		static readonly type = '[Customer] Exit Onboarding';

		constructor(public command: ExitOnboardingCommand) {}
	}

	export class Reprocess {
		static readonly type = '[Customer] Reprocess onboarding';
		constructor(readonly enrollmentType: LifelineEnrollmentType) {}
	}

	export class GetCustomersList {
		static readonly type = '[Customer] Get Customers';

		constructor(public request: GetCustomersRequest) {}
	}

	export class SetValidateTribal {
		static readonly type = '[Customer] Set Validate Tribal';

		constructor(public value: boolean | null) {}
	}

	export class ValidateTribal {
		static readonly type = '[Customer] Validate Tribal';

		constructor(public location: LatLng) {}
	}

	export class ActivateHandover {
		static readonly type = '[Customer] Activate Handover';

		constructor(public handoverActivationCommand: HandoverActivationCommand) {}
	}

	export class UpdateOnboardingStatus {
		static readonly type = '[Customer] Update Onboarding Status';

		constructor(public status: CustomerOnboardingStatus) {}
	}

	export class UpdateOrderStatus {
		static readonly type = '[Customer] Update Order Status';

		constructor(public status: OrderStatusGeneral) {}
	}

	export class GetCustomersCounts {
		static readonly type = '[Customer] Get Counts';

		constructor() {}
	}

	export class GetDeviceTypes {
		static readonly type = '[Customer] Get device types';
	}

	export class UpdateIneligibleForTransfer {
		static readonly type = '[Customer] Mark as ineligible for transfer';

		constructor(
			public ineligibleForTransfer: boolean,
			public ineligibleForTransferError?: string
		) {}
	}
}
