import { SelfSignupInviteViewModel } from '../../models/invitations';
import {
	LetterType,
	EmployeeInvitationEmailLetter
} from '@modules/employees/pages/employees/employee-credentials-dialog/employee-credentials.type';

export namespace InvitationsActions {
	export class SelfSignup {
		static readonly type = '[Invitations] Create SelfSignup';
		constructor(public payload: SelfSignupInviteViewModel) {}
	}
	export class GetInvitations {
		static readonly type = '[Invitations] Get List';
		constructor() {}
	}
	export class ChangeAvailability {
		static readonly type = '[Invitations] Change Availability';

		constructor(public payload: { id: string; enabled: boolean }) {}
	}
	export class SendInvitationEmail {
		static readonly type = '[Invitations] Send Invitation Email';

		constructor(public payload: EmployeeInvitationEmailLetter) {}
	}
}
