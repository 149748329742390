import { Injectable, inject } from '@angular/core';
import { State, Action, StateContext, Selector, NgxsOnInit, Store } from '@ngxs/store';
import { Subject, combineLatest, filter, map, of, switchMap, takeUntil, tap } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';

import { AccountActions } from './account.actions';
import { AccountService } from '../../services/account.service';

import LoadPermissions = AccountActions.LoadPermissions;
import SetupPermissions = AccountActions.SetupPermissions;
import GetInfo = AccountActions.GetInfo;
import { AccountInfoViewModel } from './account.types';
import { ApiResponse } from '@core/interfaces/api-response.type';
import { OrderDeliveryMethod, orderDeliveryMethodToArray } from '@core/models/OrderDeliveryMethod';
import { isArrayNotNullOrEmpty, isNotNullish } from '@core/utils/utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PermissionUtils } from '@core/utils/permission-utils';
import { EmployeesState, EmployeesStateModel } from '@store/employees/employees.state';
import { CarrierCode } from '@core/models/carrier-code';
import { EnrollmentLinkFlowFlags } from '@core/api-client/data-contracts';

export interface AccountStateModel {
	permissions: string[];
	accountInfo?: AccountInfoViewModel;
}

@State<AccountStateModel>({
	name: 'auth',
	defaults: {
		permissions: [],
		accountInfo: undefined
	}
})
@Injectable()
export class AccountState implements NgxsOnInit {
	private readonly _destroying$ = new Subject<void>();

	@Selector()
	static permissions(state: AccountStateModel): string[] {
		return state.permissions;
	}

	@Selector()
	static accountInfo(state: AccountStateModel): AccountInfoViewModel | undefined {
		return state.accountInfo;
	}

	@Selector()
	static optimumEnabled(state: AccountStateModel): boolean {
		return state.accountInfo?.optimumEnabled ?? false;
	}

	@Selector([EmployeesState])
	static userCarrierCodes(
		state: AccountStateModel,
		employeeState: EmployeesStateModel
	): CarrierCode[] | null {
		if (!isNotNullish(state.accountInfo)) {
			return null;
		}
		if (PermissionUtils.canAccessClecCodes(state.permissions)) {
			if (isArrayNotNullOrEmpty(employeeState.carrierCodes)) {
				return employeeState.carrierCodes;
			}
		}
		return state.accountInfo!.carrierCodes;
	}

	@Selector()
	static providedCarrierCodes(state: AccountStateModel): CarrierCode[] | null {
		if (!isNotNullish(state.accountInfo)) {
			return null;
		}
		return state.accountInfo!.carrierCodes;
	}

	@Selector()
	static carrierCodesDescription(state: AccountStateModel): string {
		return state.accountInfo?.carrierCodesDescription ?? '';
	}

	@Selector()
	static permissionsString(state: AccountStateModel): string {
		return state.permissions.join('');
	}

	@Selector()
	static orderDeliveryMethods(state: AccountStateModel): OrderDeliveryMethod[] {
		return isNotNullish(state.accountInfo?.orderDeliveryMethods)
			? orderDeliveryMethodToArray(state.accountInfo!.orderDeliveryMethods)
			: [];
	}

	constructor(
		private accountService: AccountService,
		private permissionsService: NgxPermissionsService
	) {}

	@Action(LoadPermissions)
	loadPermissions(ctx: StateContext<AccountStateModel>) {
		return this.accountService.getPermissions().pipe(
			tap(({ succeeded, data }: ApiResponse<string[]>) => {
				// TODO: HANDLE ERRORS
				if (succeeded) {
					ctx.setState({ permissions: data });
					this.permissionsService.loadPermissions(data);
				}
			})
		);
	}

	@Action(SetupPermissions)
	setupPermissions(ctx: StateContext<AccountStateModel>, permissions: string[]) {
		ctx.patchState({
			permissions
		});

		return this.permissionsService.loadPermissions(permissions);
	}

	@Action(GetInfo)
	getInfo(ctx: StateContext<AccountStateModel>) {
		return this.accountService.getInfo().subscribe(response => {
			if (response.succeeded) {
				ctx.patchState({
					accountInfo: response.data
				});
			}
		});
	}

	@Action(AccountActions.SetAffiliateInfo)
	setAffiliateInfo(
		ctx: StateContext<AccountStateModel>,
		{ enrollmentLinkViewModel }: AccountActions.SetAffiliateInfo
	) {
		ctx.patchState({
			accountInfo: {
				employeeId: enrollmentLinkViewModel.associatedAgentId,
				carrierCodes: [
					{
						id: enrollmentLinkViewModel.associatedClecId,
						code: 0,
						description: enrollmentLinkViewModel.associatedClecDescription
					}
				],
				carrierCodesDescription: '',
				orderDeliveryMethods: OrderDeliveryMethod.None,
				optimumEnabled:
					(enrollmentLinkViewModel.flows & EnrollmentLinkFlowFlags.Optimum) ===
					EnrollmentLinkFlowFlags.Optimum
			}
		});
	}

	public ngxsOnInit(ctx: StateContext<any>): void {
		this.permissionsService.permissions$
			.pipe(takeUntil(this._destroying$))
			.subscribe(permissionsObject => {
				ctx.patchState({
					permissions: Object.keys(permissionsObject)
				});
			});
	}
}
