import { NavItem } from './nav-item/nav-item';
export enum NavigationItemNames {
	home = 'Home',
	dashboard = 'Dashboard',
	employees = 'Agents',
	invitations = 'Invitations',
	customerOnboarding = 'Customer Enrollments',
	orders = 'Orders',
	masterZip = 'Master Zip',
	devices = 'Devices',
	payments = 'Payments',
	settings = 'Settings',
	help = 'Help',
	signupRequests = 'Signup Requests',
	webOnboarding = 'Web Enrollment Links',
	webEnrollmentsList = 'Web Enrollments',
	optimum = 'Optimum Enrollments',
	Retail = 'Retail',
	healthCheck = 'Health Check',
	auditLogs = 'Audit Logs'
}

export type NavItemsMap = {
	[key in NavigationItemNames]: NavItem;
};
