import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/app/core/interfaces/api-response.type';
import { ApiMethods } from 'src/app/core/utils/api/api-methods';
import { LifelineEnrollmentType } from '../constants/lifeline-enrollment-type.enum';
import { EligibilityPrograms } from '@core/models/eligibility-programs';

@Injectable({
	providedIn: 'root'
})
export class EligibilityProgramService {
	constructor(private http: HttpClient) {}

	public getEligibilityPrograms(
		zip: string,
		lifelineEnrollmentType: LifelineEnrollmentType,
		tribalEnabled: boolean
	): Observable<ApiResponse<EligibilityPrograms>> {
		return this.http.get<ApiResponse<EligibilityPrograms>>(
			ApiMethods.getEligibilityPrograms(
				zip,
				lifelineEnrollmentType,
				tribalEnabled
			)
		);
	}
}
