import { LifelineEnrollmentType } from '@modules/customer/constants/lifeline-enrollment-type.enum';
import { protectedResources } from '../../msal/msal.config';
import { OrderErrorCode } from '@core/models/order-error-code';

export class ApiMethods {
	private static url = protectedResources.api.endpoint;

	public static prefixed(controller: string, action: string): string {
		return `${ApiMethods.url}/${controller}/${action}`;
	}

	public static getZipProgramStatus(zip: string) {
		return ApiMethods.url + '/zipProgramStatuses/' + zip;
	}

	public static checkServiceAvailability() {
		return ApiMethods.url + '/zipProgramStatuses/checkAvailability';
	}

	public static getEligibilityPrograms(
		zip: string,
		lifelineEnrollmentType: LifelineEnrollmentType,
		tribalEnabled: boolean
	) {
		return `${ApiMethods.url}/EligibilityPrograms?zip=${zip}&lifelineEnrollmentType=${lifelineEnrollmentType}&tribalEnabled=${tribalEnabled}`;
	}

	public static getDocumentTypes() {
		return `${ApiMethods.url}/DocumentTypes`;
	}

	public static getCustomer(customerId: string) {
		return `${ApiMethods.url}/Customers/${customerId}`;
	}

	public static getDeviceTypes(customerId: string) {
		return `${ApiMethods.url}/Customers/${customerId}/device-types`;
	}

	public static addCustomer() {
		return `${ApiMethods.url}/Customers/add`;
	}

	public static updateCustomer() {
		return `${ApiMethods.url}/Customers/update`;
	}

	public static updateDevicePlan() {
		return `${ApiMethods.url}/Customers/device-plan`;
	}

	public static resumeOnboarding() {
		return `${ApiMethods.url}/Customers/resume`;
	}

	public static uploadCustomerDocument() {
		return `${ApiMethods.url}/Customers/documents`;
	}

	public static cancelOnboarding() {
		return `${ApiMethods.url}/Customers/cancel`;
	}

	public static exitOnboarding() {
		return `${ApiMethods.url}/Customers/exit`;
	}

	public static reprocess() {
		return `${ApiMethods.url}/Customers/reprocess`;
	}

	public static getCustomers() {
		return `${ApiMethods.url}/Customers`;
	}

	public static sendMessage() {
		return `${ApiMethods.url}/Messages/send`;
	}

	public static sendOptimumMessage() {
		return `${ApiMethods.url}/Messages/optimum/send`;
	}

	public static verifyMessage() {
		return `${ApiMethods.url}/Messages/verify`;
	}

	public static maxsipCustomerExists(): string {
		return `${ApiMethods.url}/Customers/maxsip-exists`;
	}

	public static getErrors(orderId: string, codes: OrderErrorCode[]) {
		return (
			`${ApiMethods.url}/Customers/${orderId}/errors?` +
			codes.map((c, index) => `codes=${c}${index !== codes.length - 1 ? '&' : ''}`).join('')
		);
	}

	public static getPaymentMethods(id: string) {
		return `${ApiMethods.url}/Customers/${id}/payment-methods`;
	}
}
