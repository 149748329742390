import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../../material.module';
import { MsalSignInService } from '@core/services/msal-sign-in.service';

@Component({
	selector: 'app-sandbox',
	standalone: true,
	imports: [RouterModule, MaterialModule],
	templateUrl: './sandbox.component.html'
})
export class AppSandboxComponent {
	constructor(private msalSignInService: MsalSignInService) {}

	public async logout() {
		await this.msalSignInService.signOut();
	}

	public checkAccess(): void {
		location.reload();
	}
}
