import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { DocumentTypesActions } from './document-types.actions';
import { DocumentTypeService } from '@modules/customer/services/document-types.service';
import { tap } from 'rxjs';
import { DocumentType } from 'src/app/core/models/document-type.type';
import { documentNames } from '@modules/customer/constants/document-names.constants';

export interface DocumentTypesStateModel {
	documentTypes: DocumentType[];
}

const defaults: DocumentTypesStateModel = {
	documentTypes: []
};

@State<DocumentTypesStateModel>({
	name: 'documentTypes',
	defaults: defaults
})
@Injectable()
export class DocumentTypesState {
	@Selector()
	static documentTypes(state: DocumentTypesStateModel): DocumentType[] {
		return state.documentTypes;
	}

	@Selector()
	static availableDocumentTypes(state: DocumentTypesStateModel): DocumentType[] {
		return state.documentTypes.filter(
			d => d.description === documentNames.SSN || d.description === documentNames.TribalID
		);
	}

	constructor(private documentTypeService: DocumentTypeService) {}

	@Action(DocumentTypesActions.LoadDocumentTypes)
	getDocumentTypes(
		ctx: StateContext<DocumentTypesStateModel>,
		_: DocumentTypesActions.LoadDocumentTypes
	) {
		const documentsLoaded = ctx.getState().documentTypes.length > 0;

		if (documentsLoaded) {
			return;
		}

		return this.documentTypeService.getDocumentTypes().pipe(
			tap(response => {
				const { succeeded, data } = response;
				if (succeeded) {
					ctx.patchState({
						documentTypes: data
					});
				}
			})
		);
	}
}
