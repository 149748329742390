import { protectedResources } from '../msal/msal.config';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MiddlewareApiMethods } from '../utils/api/middleware-api-methods';
import { CheckEligibilityResult } from '@core/interfaces/middleware/check-eligibility-result.type';
import { PlanViewModel } from '@core/interfaces/middleware/plan-view-model.type';
import { LifelineEnrollmentType } from '@modules/customer/constants/lifeline-enrollment-type.enum';
import { MakePaymentRequest } from '@core/interfaces/middleware/make-payment-request.type';
import { PaymentDetailsViewModel } from '@core/interfaces/middleware/payment-details-view-model';
import { HandoverActivationCommand } from '@core/interfaces/middleware/activate-handover-request.type';
import { HandoverActivationViewModel } from '@core/interfaces/middleware/handover-activation-view-model';
import { PaymentMethod } from '@modules/customer/components/payment-form/payment-form.types';
import { CustomerAddressInfo } from '@core/models/customer.type';
import { AddressValidationViewModel } from '@core/interfaces/middleware/addreess-validation-view-model.type';
import { ApiResponse } from '@core/interfaces/api-response.type';
import { OrderStatusViewModel } from '@core/interfaces/middleware/order-status-view-model.type';
import { TransferAcpCommand } from '@core/interfaces/middleware/TransferAcpCommand';
import { NladVerifyResponseDto } from '@core/interfaces/middleware/NladVerifyResponseDto';
import { TransferAcpResponseDto } from '@core/interfaces/middleware/TransferAcpResponseDto';
import {
	CreateLifelineCustomerViewModel,
	UploadProofCommand
} from '@core/api-client/data-contracts';
import { AvailabilityInfo } from '@core/interfaces/middleware/check-availability-result.type';

@Injectable({
	providedIn: 'root'
})
export class MiddlewareService {
	url = protectedResources.api.endpoint;

	constructor(private http: HttpClient) {}

	public validateAddress(
		address: CustomerAddressInfo,
		mailAddress: CustomerAddressInfo | null,
		customerId: string
	): Observable<ApiResponse<AddressValidationViewModel>> {
		return this.http.post<ApiResponse<AddressValidationViewModel>>(
			MiddlewareApiMethods.validateAddress(),
			{
				address: address,
				mailAddress: mailAddress,
				customerId: customerId
			}
		);
	}

	public checkServiceAvailability(zip: string): Observable<ApiResponse<AvailabilityInfo>> {
		return this.http.post<ApiResponse<AvailabilityInfo>>(
			MiddlewareApiMethods.checkServiceAvailability(),
			{
				zip
			}
		);
	}

	public checkEligibility(customerId: string): Observable<ApiResponse<CheckEligibilityResult>> {
		return this.http.post<ApiResponse<CheckEligibilityResult>>(
			MiddlewareApiMethods.checkEligibility(),
			{
				customerId
			}
		);
	}

	public eligibilityStatusCheck(
		customerId: string
	): Observable<ApiResponse<CheckEligibilityResult>> {
		return this.http.post<ApiResponse<CheckEligibilityResult>>(
			MiddlewareApiMethods.eligibilityStatusCheck(),
			{
				customerId
			}
		);
	}

	public nladVerify(customerId: string): Observable<ApiResponse<NladVerifyResponseDto>> {
		return this.http.post<ApiResponse<NladVerifyResponseDto>>(
			MiddlewareApiMethods.nladVerify(),
			{
				customerId
			}
		);
	}

	public transferAcp(command: TransferAcpCommand) {
		return this.http.post<ApiResponse<TransferAcpResponseDto>>(
			MiddlewareApiMethods.transferAcp(),
			command
		);
	}

	// TODO: remove if not used
	public getPlansList(
		id: string | null,
		zip: string,
		LifelineEnrollmentType: LifelineEnrollmentType
	): Observable<ApiResponse<PlanViewModel[]>> {
		return this.http.post<ApiResponse<PlanViewModel[]>>(MiddlewareApiMethods.getPlansList(), {
			id,
			zip,
			LifelineEnrollmentType
		});
	}

	public getPaymentDetails(
		paymentMethod: PaymentMethod,
		customerId: string
	): Observable<ApiResponse<PaymentDetailsViewModel>> {
		return this.http.post<ApiResponse<PaymentDetailsViewModel>>(
			MiddlewareApiMethods.getPaymentDetails(),
			{ paymentMethod, customerId }
		);
	}

	public makePayment(makePaymentRequest: MakePaymentRequest): Observable<ApiResponse<string>> {
		return this.http.post<ApiResponse<string>>(
			MiddlewareApiMethods.makePayment(),
			makePaymentRequest
		);
	}

	public createLifelineCustomer(
		customerId: string
	): Observable<ApiResponse<CreateLifelineCustomerViewModel>> {
		return this.http.post<ApiResponse<CreateLifelineCustomerViewModel>>(
			MiddlewareApiMethods.createLifelineCustomer(),
			{
				customerId
			}
		);
	}

	public activateHandover(
		handoverActivationCommand: HandoverActivationCommand
	): Observable<ApiResponse<HandoverActivationViewModel>> {
		return this.http.post<ApiResponse<HandoverActivationViewModel>>(
			MiddlewareApiMethods.activateHandover(),
			handoverActivationCommand
		);
	}

	public checkOrderExists(customerId: string): Observable<ApiResponse<boolean>> {
		return this.http.post<ApiResponse<boolean>>(MiddlewareApiMethods.checkOrderExists(), {
			customerId
		});
	}

	public getOrderStatus(customerId: string): Observable<ApiResponse<OrderStatusViewModel>> {
		return this.http.post<ApiResponse<OrderStatusViewModel>>(
			MiddlewareApiMethods.getOrderStatus(),
			{ customerId }
		);
	}

	public uploadProof(request: UploadProofCommand): Observable<ApiResponse<string>> {
		return this.http.post<ApiResponse<string>>(MiddlewareApiMethods.uploadProof(), request);
	}
}
