import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { MaterialModule } from '../../../material.module';
import { AsyncPipe, LowerCasePipe, NgIf } from '@angular/common';
import { match } from 'ts-pattern';
import { EmployeeRequestAction } from '../employee-signup-requests.component';
import { Select, Store } from '@ngxs/store';
import { EmployeesActions } from '@store/employees/employees.actions';
import { Observable } from 'rxjs';
import {
	ActionsExecuting,
	actionsExecuting
} from '@ngxs-labs/actions-executing';

@Component({
	selector: 'dialog-employee-signup-request-action',
	standalone: true,
	templateUrl: 'employee-request-action.component.html',
	imports: [MaterialModule, LowerCasePipe, AsyncPipe, NgIf]
})
export class AppEmployeeRequestActionDialogComponent {
	@Select(
		actionsExecuting([
			EmployeesActions.ApproveSignupRequest,
			EmployeesActions.DeclineSignupRequest
		])
	)
	actionIsExecuting$: Observable<ActionsExecuting>;

	constructor(
		private store: Store,
		public dialogRef: MatDialogRef<AppEmployeeRequestActionDialogComponent>,
		@Optional()
		@Inject(MAT_DIALOG_DATA)
		public data: { action: string; id: string }
	) {}

	private mapActionToRequest(action: string, id: string): Observable<any> {
		return match(action)
			.with(EmployeeRequestAction.Approve, () =>
				this.store.dispatch(
					new EmployeesActions.ApproveSignupRequest({ id })
				)
			)
			.otherwise(() =>
				this.store.dispatch(
					new EmployeesActions.DeclineSignupRequest({ id })
				)
			);
	}

	public doAction() {
		const { action, id } = this.data;
		this.mapActionToRequest(action, id).subscribe(_ => {
			this.dialogRef.close({
				event: action,
				id
			});
		});
	}
}
