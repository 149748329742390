import { protectedResources } from '../msal/msal.config';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	AddedEmployeeViewModel,
	Agent,
	Employee,
	EmployeeSignupRequest,
	EmployeeViewModel,
	GetEmployeeSignupRequests,
	GetEmployeesRequest,
	MvnoMetadata
} from '../models/employee';
import { Observable } from 'rxjs';
import { CarrierCode } from '../models/carrier-code';
import { EmployeeApiMethods } from '../utils/api/employee-api-methods';
import { SignupRequestsApiMethods } from '@core/utils/api/signup-requests-api-methods';
import { ImportEmployeeResponse } from '@core/models/import';
import { ApiResponse, PaginatedApiResponse } from '@core/interfaces/api-response.type';

@Injectable({
	providedIn: 'root'
})
export class EmployeesService {
	url = protectedResources.api.endpoint;

	constructor(private http: HttpClient) {}

	public getCarrierCodes(): Observable<ApiResponse<CarrierCode[]>> {
		return this.http.get<ApiResponse<CarrierCode[]>>(EmployeeApiMethods.getCarrierCodes());
	}

	public getMvnoMetadata(): Observable<ApiResponse<MvnoMetadata[]>> {
		return this.http.get<ApiResponse<MvnoMetadata[]>>(EmployeeApiMethods.getMvnoMetadata());
	}

	public list(payload: GetEmployeesRequest): Observable<PaginatedApiResponse<Employee[]>> {
		return this.http.post<PaginatedApiResponse<Employee[]>>(EmployeeApiMethods.list(), payload);
	}

	public add(
		employeeViewModel: EmployeeViewModel
	): Observable<ApiResponse<AddedEmployeeViewModel>> {
		return this.http.post<ApiResponse<AddedEmployeeViewModel>>(
			EmployeeApiMethods.add(),
			employeeViewModel
		);
	}

	public update(employeeViewModel: EmployeeViewModel): Observable<ApiResponse<Employee>> {
		return this.http.post<ApiResponse<Employee>>(
			EmployeeApiMethods.update(),
			employeeViewModel
		);
	}

	public invite(
		employeeViewModel: EmployeeViewModel
	): Observable<ApiResponse<EmployeeSignupRequest>> {
		return this.http.post<ApiResponse<EmployeeSignupRequest>>(
			EmployeeApiMethods.invite(),
			employeeViewModel
		);
	}

	public getSignupRequests(payload: GetEmployeeSignupRequests) {
		return this.http.post<PaginatedApiResponse<EmployeeSignupRequest[]>>(
			SignupRequestsApiMethods.list(),
			payload
		);
	}

	public approveSignupRequest(payload: {
		id: string;
	}): Observable<ApiResponse<EmployeeSignupRequest | string>> {
		return this.http.post<ApiResponse<EmployeeSignupRequest | string>>(
			SignupRequestsApiMethods.approve(),
			payload
		);
	}

	public declineSignupRequest(payload: {
		id: string;
	}): Observable<ApiResponse<EmployeeSignupRequest | string>> {
		return this.http.post<ApiResponse<EmployeeSignupRequest | string>>(
			SignupRequestsApiMethods.decline(),
			payload
		);
	}

	public agentsList(searchQuery: string): Observable<ApiResponse<Agent[]>> {
		return this.http.post<ApiResponse<Agent[]>>(EmployeeApiMethods.agentsList(), {
			search: searchQuery
		});
	}

	public importDataFile(file: File) {
		const formData = new FormData();
		formData.append('file', file, file.name);
		return this.http.post<ApiResponse<ImportEmployeeResponse>>(
			EmployeeApiMethods.importDataFile(),
			formData
		);
	}

	public getImportedEmployeePasswordsFile(): Observable<any> {
		return this.http.get(EmployeeApiMethods.importDataFilePasswords(), {
			headers: {
				ContextType: 'application/json'
			},
			responseType: 'blob'
		});
	}

	public toBase64(blob: any): void {
		const reader = new FileReader();
		reader.readAsDataURL(blob);
		reader.onloadend = () => {
			const base64data = reader.result;
		};
	}

	public agentsListByClecId(associatedClecId: number): Observable<ApiResponse<Agent[]>> {
		return this.http.post<ApiResponse<Agent[]>>(EmployeeApiMethods.agentsListByClecId(), {
			associatedClecId
		});
	}
}
