import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { CoreService } from 'src/app/core/services/core.service';

@Component({
	selector: 'app-branding',
	standalone: true,
	imports: [NgIf],
	template: `
		<div class="branding">
			<a href="/">
				<img
					src="./assets/images/logos/maxsip-logo.png"
					class="align-middle m-2"
					alt="logo"
				/>
			</a>
		</div>
	`
})
export class BrandingComponent {
	options = this.settings.getOptions();

	constructor(private settings: CoreService) {}
}
