import { OrderStatusGeneral } from '@core/api-client/data-contracts';
import { isNotNullish } from './utils';

export class StatusUtils {
	private static statusesForReprocess: OrderStatusGeneral[] = [
		OrderStatusGeneral.Error,
		OrderStatusGeneral.Rejected
	];

	public static OrderStatusIsReprocessable(status?: OrderStatusGeneral): boolean {
		if (!isNotNullish(status)) return false;

		return this.statusesForReprocess.includes(status);
	}
}
