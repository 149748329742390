import { Inject, Injectable } from '@angular/core';
import { MsalService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import {
	AccountInfo,
	AuthenticationResult,
	InteractionType,
	PopupRequest,
	RedirectRequest
} from '@azure/msal-browser';
import { Store } from '@ngxs/store';
import { StateClear } from 'ngxs-reset-plugin';

import * as Sentry from '@sentry/angular-ivy';
import { AuditLogsService } from '@modules/audit-logs/services/audit-logs.service';
import { defer, switchMap, take } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class MsalSignInService {
	constructor(
		@Inject(MSAL_GUARD_CONFIG)
		private msalGuardConfig: MsalGuardConfiguration,
		private authService: MsalService,
		private auditLogsService: AuditLogsService,
		private store: Store
	) {}

	public get isLoggedIn(): boolean {
		return this.authService.instance.getAllAccounts().length > 0;
	}

	public get getActiveAccount(): AccountInfo | null {
		return this.authService.instance.getActiveAccount();
	}

	public async signIn() {
		if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
			this.popupSignIn();
			return;
		}

		await this.interactiveSignIn();
	}

	public async signOut() {
		const activeAccount =
			this.authService.instance.getActiveAccount() ||
			this.authService.instance.getAllAccounts()[0];

		this.store.dispatch(new StateClear());

		const logoutRequest =
			this.msalGuardConfig.interactionType === InteractionType.Popup
				? this.popupSignOut(activeAccount)
				: this.interactiveSignOut(activeAccount);

		this.auditLogsService
			.logoutEvent({ data: activeAccount.localAccountId })
			.pipe(switchMap(() => logoutRequest))
			.subscribe();
	}

	private popupSignIn() {
		if (this.msalGuardConfig.authRequest) {
			this.authService
				.loginPopup({
					...this.msalGuardConfig.authRequest
				} as PopupRequest)
				.subscribe((response: AuthenticationResult) => {
					this.authService.instance.setActiveAccount(response.account);
				});
			return;
		}

		this.authService.loginPopup().subscribe((response: AuthenticationResult) => {
			this.authService.instance.setActiveAccount(response.account);
		});
	}

	private async interactiveSignIn() {
		if (this.msalGuardConfig.authRequest) {
			await this.authService.loginRedirect({
				...this.msalGuardConfig.authRequest
			} as RedirectRequest);
			return;
		}
		await this.authService.loginRedirect();
	}

	private interactiveSignOut(account: AccountInfo) {
		return defer(() => {
			Sentry.setUser(null);
			return this.authService.logout({ account });
		});
	}

	private popupSignOut(account: AccountInfo) {
		return defer(() => {
			Sentry.setUser(null);
			return this.authService.logoutPopup({ account });
		});
	}
}
