import * as moment from 'moment';
import { Leaves, Paths } from '@shared/components/grid/grid.component.type';

export declare const String: StringExtensions;

interface StringExtensions extends StringConstructor {
	empty: '';
}

String.empty = '';

export function safeString(value?: string | null): string {
	return (value ?? String.empty).trim();
}

export function optionalToNullable<T>(value?: T | null): T | null {
	return value ?? null;
}

export function isNullOrUndefined<T>(obj: T | null | undefined): obj is null | undefined {
	return typeof obj === 'undefined' || obj === null;
}

export function isNotNullish<T>(obj: T | null | undefined): obj is T {
	return !isNullOrUndefined(obj);
}

export const stringEmpty = '';

export const newlineSymbol = '\n';

export function isStringNullOrEmpty(str: string | null | undefined): str is null | undefined | '' {
	return isNullOrUndefined(str) || str === stringEmpty;
}

// TODO: Find better name
export function isStringNotNullOrEmpty(str: string | '' | null | undefined): str is string {
	return !isStringNullOrEmpty(str);
}

export function isObjectNullishOrEmptyString<T>(value: T | null | undefined) {
	return typeof value === 'string' ? isStringNullOrEmpty(value) : isNullOrUndefined(value);
}

export function isAnyStringNullOrEmpty(...strings: Array<string | null | undefined>): boolean {
	return strings.some(isStringNullOrEmpty);
}

export function isAllStringNullOrEmpty(...strings: Array<string | null | undefined>): boolean {
	return strings.every(isStringNullOrEmpty);
}

export function includesCaseInsensitive(base: string, search: string): boolean {
	return base.toLowerCase().includes(search.toLowerCase());
}

export function coalesce(item1: string | null | undefined, item2: string): string {
	return !isStringNullOrEmpty(item1) ? item1! : item2;
}

export function isAllPropertiesUndefinedOrEmpty<T>(obj: T | null | undefined): boolean {
	if (isNullOrUndefined(obj)) {
		return true;
	}

	return Object.values(obj as object).every(
		value =>
			isNullOrUndefined(value) ||
			value === stringEmpty ||
			isArrayNullOrEmpty(value as unknown[])
	);
}

export function isArrayNullOrEmpty<T>(array: Array<T> | null | undefined): boolean {
	return isNullOrUndefined(array) || array.length === 0;
}

export function isArrayNotNullOrEmpty<T>(array: Array<T> | null | undefined): boolean {
	return !isArrayNullOrEmpty(array);
}

export function isAllArraysNullOrEmpty<T>(...arrays: Array<Array<T> | null | undefined>): boolean {
	return arrays.every(isArrayNullOrEmpty);
}

export function applyIf(condition: boolean, action: () => void): void {
	if (condition) {
		action();
	}
}

export function stringCompare(str1?: string | null, str2?: string | null) {
	if (isStringNullOrEmpty(str1) && isStringNullOrEmpty(str2)) return true;
	if (str1 && str2) return str1.trim().toLowerCase() === str2.trim().toLowerCase();
	return false;
}

export function skipTimezone(d: Date | null | string): string | null {
	return isNotNullish(d) ? moment(d).utcOffset(0, true).format() : null;
}

export function base64ToUrl(base64: string, type: string): string {
	return `data:${type};base64,${base64}`;
}

export function getValueByPath<T>(obj: T, path: Paths<T> | Leaves<T>): any {
	const parts = path.split('.');
	if (isNullOrUndefined(obj)) {
		return null;
	}

	if (parts.length == 1) {
		return obj[parts[0] as never];
	}

	return getValueByPath(obj[parts[0] as never], parts.slice(1).join('.') as never);
}
