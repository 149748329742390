<div class="blank-layout-container justify-content-center">
	<div class="row">
		<div class="col-12 text-center">
			<img
				src="/assets/images/backgrounds/maitenance.png"
				alt="error-bg"
			/>
			<h2 class="auth-title f-w-400">Access Denied</h2>
			<h6 class="f-s-16 f-w-400 m-b-30">You don’t have permission to access or your account is blocked. Please contact support and check back later!</h6>
			<a
				mat-flat-button
				color="primary"
				(click)="logout()"
				>Logout</a
			>
		</div>
		<div class="col-12 text-center m-t-16">
			<a
				mat-button
				color="primary"
				(click)="checkAccess()"
				>Check now</a
			>
		</div>
	</div>
</div>
