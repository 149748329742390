import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subscription, take, tap } from 'rxjs';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { CoreService } from 'src/app/core/services/core.service';
import { AppSettings } from 'src/app/app.config';
import { filter } from 'rxjs/operators';
import { ActivatedRoute, Data, NavigationEnd, Router, RouterModule } from '@angular/router';
import { MaterialModule } from 'src/app/material.module';
import { CommonModule } from '@angular/common';
import { TablerIconsModule } from 'angular-tabler-icons';
import { HeaderComponent } from '../header/header.component';
import { uiConstants } from 'src/app/shared/constants/ui.constants';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { AppNavItemComponent } from '../sidebar/nav-item/nav-item.component';
import { NavItemsMap } from '../sidebar/sidebar-data';
import { NgxPermissionsService } from 'ngx-permissions';
import { NavItem } from '@shared/components/sidebar/nav-item/nav-item';
import { Store } from '@ngxs/store';
import { CommonActions } from '@store/common/common.actions';
import { DeviceViewMode } from '@store/common/common.types';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SideBarDataService } from '../sidebar/sidebar-data.service';
import { isStringNotNullOrEmpty } from '@core/utils/utils';
import { MonitoringUtils } from '@core/utils/monitoring-utils';
import { ModuleName } from '@shared/constants/module-name';

const MOBILE_VIEW = `screen and (max-width: ${uiConstants.mobile_view_max_width}px)`;
const TABLET_VIEW = `screen and (min-width: ${uiConstants.tablet_view_min_width}px) and (max-width: ${uiConstants.tablet_view_max_width}px)`;
const MONITOR_VIEW = `screen and (min-width: ${uiConstants.monitor_view_min_width}px)`;

@Component({
	selector: 'app-main',
	standalone: true,
	imports: [
		RouterModule,
		AppNavItemComponent,
		MaterialModule,
		CommonModule,
		SidebarComponent,
		NgScrollbarModule,
		TablerIconsModule,
		HeaderComponent
	],
	templateUrl: './main.component.html',
	styleUrls: [],
	encapsulation: ViewEncapsulation.None
})
export class MainComponent implements OnInit, OnDestroy {
	@ViewChild('leftsidenav')
	public sidenav: MatSidenav;

	resView = false;
	@ViewChild('content', { static: true }) content!: MatSidenavContent;

	options = this.settings.getOptions();
	private layoutChangesSubscription = Subscription.EMPTY;
	private isMobileScreen = false;

	private readonly navigationItems: NavItemsMap;

	get isOver(): boolean {
		return this.isMobileScreen;
	}

	get isTablet(): boolean {
		return this.resView;
	}

	constructor(
		private store: Store,
		private router: Router,
		private settings: CoreService,
		private breakpointObserver: BreakpointObserver,
		private ngxPermissionsService: NgxPermissionsService,
		private sidebarDataService: SideBarDataService,
		private route: ActivatedRoute
	) {
		this.layoutChangesSubscription = this.breakpointObserver
			.observe([MOBILE_VIEW, TABLET_VIEW, MONITOR_VIEW])
			.subscribe(state => {
				// SidenavOpened must be reset true when layout changes
				this.options.sidenavOpened = true;
				this.isMobileScreen = state.breakpoints[MOBILE_VIEW];
				if (!this.options.sidenavCollapsed) {
					this.options.sidenavCollapsed = state.breakpoints[TABLET_VIEW];
				}
				this.resView = state.breakpoints[TABLET_VIEW];
				this.setupViewMode();
			});

		this.receiveOptions(this.options);

		this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(e => {
			this.content.scrollTo({ top: 0 });
		});

		this.navigationItems = this.sidebarDataService.getSideBarItems();
	}

	public ngOnInit(): void {
		this.route.data
			.pipe(
				take(1),
				filter(({ moduleName }: Data | { moduleName: ModuleName }) =>
					isStringNotNullOrEmpty(moduleName)
				),
				tap(({ moduleName }: Data | { moduleName: ModuleName }) => {
					MonitoringUtils.initMonitoringTools(moduleName);
				})
			)
			.subscribe();
	}

	public ngOnDestroy(): void {
		this.layoutChangesSubscription.unsubscribe();
	}

	private setupViewMode(): void {
		let viewMode = this.isTablet ? DeviceViewMode.Tablet : DeviceViewMode.Desktop;
		if (this.isMobileScreen) {
			viewMode = DeviceViewMode.Mobile;
		}

		this.store.dispatch(new CommonActions.UpdateDeviceViewMode(viewMode));
	}

	public toggleCollapsed(): void {
		this.options.sidenavCollapsed = !this.options.sidenavCollapsed;
		this.resetCollapsedState();
	}

	public resetCollapsedState(timer = 400): void {
		setTimeout(() => this.settings.setOptions(this.options), timer);
	}

	public onSidenavOpenedChange(isOpened: boolean): void {
		this.options.sidenavOpened = isOpened;
		this.settings.setOptions(this.options);
	}

	public receiveOptions(options: AppSettings): void {
		this.options = options;
	}

	public getNavigationItems(): NavItem[] {
		const permissions = Object.values(this.ngxPermissionsService.getPermissions());
		if (permissions.length > 0) {
			const role = permissions[0]?.name;
			return Object.values(this.navigationItems).filter(
				({ eligibleRoles }) =>
					!eligibleRoles || eligibleRoles.findIndex(i => i === role) > -1
			);
		}

		return [];
	}
}
