<mat-toolbar class="topbar">
	<button
		mat-icon-button
		(click)="toggleCollapsed.emit()"
		*ngIf="showToggle"
		class="d-flex justify-content-center"
	>
		<i-tabler
			name="menu-2"
			class="icon-20 d-flex"
		></i-tabler>
	</button>

	<!-- Mobile Menu -->
	<button
		mat-icon-button
		(click)="toggleMobileNav.emit()"
		*ngIf="!showToggle"
		class="d-flex justify-content-center"
	>
		<i-tabler
			name="menu-2"
			class="icon-20 d-flex"
		></i-tabler>
	</button>

	<div class="d-none d-lg-flex">
		<!-- --------------------------------------------------------------- -->
		<!--  Links -->
		<!-- --------------------------------------------------------------- -->
		<a
			mat-button
			*ngxPermissionsOnly="exportAvailableForRoles"
			[routerLink]="['/customers/new']"
			>New Customer</a
		>
	</div>

	<div class="d-none d-lg-flex"></div>

	<span class="flex-1-auto"></span>
	<span class="text-primary rounded-pill p-x-2 p-y-4 f-s-12 f-w-500">{{ enrollmentId$ | async }}</span>
	<span class="text-primary rounded-pill p-x-2 p-y-4 f-s-12 f-w-500">{{ currentUser.name }}</span>
	<span class="text-primary rounded-pill p-x-2 p-y-4 f-s-12 f-w-500">({{ permissions$ | async }})</span>
	<!-- Notification Dropdown -->
	<button
		mat-icon-button
		[matMenuTriggerFor]="notificationmenu"
		aria-label="Notifications"
	>
		<i-tabler
			class="d-flex"
			name="bell"
			matBadgeColor="primary"
			[matBadge]="signUpRequestsCount$ | async"
			[matBadgeHidden]="(signUpRequestsCount$ | async) === 0"
		></i-tabler>
	</button>
	<mat-menu
		#notificationmenu="matMenu"
		class="topbar-dd cardWithShadow"
	>
		<div class="d-flex align-items-center p-x-32 p-y-16">
			<h6 class="f-s-16 f-w-600 m-0">Notifications</h6>
			<span class="m-l-auto">
				<span class="bg-primary text-white p-x-8 p-y-4 f-w-500 rounded f-s-12">0 new</span>
			</span>
		</div>
		<!--		<button-->
		<!--			mat-menu-item-->
		<!--			class="p-x-32 p-y-16"-->
		<!--			*ngFor="let notification of notifications"-->
		<!--		>-->
		<!--			<div class="d-flex align-items-center">-->
		<!--				<img-->
		<!--					[src]="notification.img"-->
		<!--					class="rounded-circle"-->
		<!--					width="48"-->
		<!--				/>-->
		<!--				<div class="m-l-16">-->
		<!--					<h5 class="f-s-14 f-w-600 m-0 mat-subtitle-1">-->
		<!--						{{ notification.title }}-->
		<!--					</h5>-->
		<!--					<span>{{ notification.subtitle }}</span>-->
		<!--				</div>-->
		<!--			</div>-->
		<!--		</button>-->
		<!--		<div class="p-y-12 p-x-32">-->
		<!--			<button-->
		<!--				mat-stroked-button-->
		<!--				color="primary"-->
		<!--				class="w-100"-->
		<!--			>-->
		<!--				See all notifications-->
		<!--			</button>-->
		<!--		</div>-->
	</mat-menu>

	<!-- profile Dropdown -->

	<button
		mat-icon-button
		[matMenuTriggerFor]="profilemenu"
		aria-label="Notifications"
	>
		<i-tabler
			name="user-circle"
			class="d-flex m-r-8 fill-icon"
		></i-tabler>
	</button>
	<mat-menu
		#profilemenu="matMenu"
		class="topbar-dd cardWithShadow"
	>
		<ng-scrollbar
			class="position-relative"
			style="height: 300px"
		>
			<div class="p-x-32 p-y-16">
				<h6 class="f-s-16 f-w-600 m-0">Employee Profile</h6>

				<div class="d-flex align-items-center p-b-24 b-b-1 m-t-16">
					<i-tabler
						name="user-circle"
						class="d-flex m-r-8 fill-icon"
					></i-tabler>
					<div class="m-l-16">
						<h6 class="f-s-14 f-w-600 m-0">{{ currentUser.name }}</h6>
						<span class="f-s-14 d-block m-b-4">{{ permissions$ | async }}</span>
						<span class="d-flex align-items-center">
							<i-tabler
								name="mail"
								class="icon-15 m-r-4"
							></i-tabler>
							{{ currentUser.email }}
						</span>
						<span class="text-primary d-flex align-items-center">
							{{ carrierCodesDescription$ | async }}
						</span>
						<span class="text-primary rounded-pill p-x-8 p-y-4 f-s-12 f-w-500"> </span>
					</div>
				</div>
			</div>

			<div
				class="p-y-12 p-x-32"
				*ngIf="msalService.instance.getAllAccounts().length !== 0"
			>
				<a
					(click)="msalSignInService.signOut()"
					mat-stroked-button
					color="primary"
					class="w-100"
					>Logout</a
				>
			</div>
		</ng-scrollbar>
	</mat-menu>
</mat-toolbar>
