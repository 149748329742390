import { ApiMethods } from './api-methods';

export class TribalApiMethods {
	private static controller: string = 'tribal';

	private static wrapped(action: string): string {
		return ApiMethods.prefixed(this.controller, action);
	}

	public static validateTribal(): string {
		return this.wrapped('validate');
	}
}
