import { Customer } from '@core/models/customer.type';
import { PaymentMethod } from '@modules/customer/components/payment-form/payment-form.types';
import { LifelineEnrollmentType } from '@modules/customer/constants/lifeline-enrollment-type.enum';
import { UsaStates } from '@shared/constants/usa-states.enum';

export class CustomerUtils {
	static compareCustomerPaymentInfo(customerOne: Customer, customerTwo: Customer): boolean {
		return (
			customerOne.addressInfo.zip === customerTwo.addressInfo.zip &&
			customerOne.planId === customerTwo.planId &&
			customerOne.deviceType === customerTwo.deviceType
		);
	}

	static customerPaymentInfoComparer(
		prev: CustomerPaymentInfo,
		curr: CustomerPaymentInfo
	): boolean {
		if (prev.customer == null) return true;
		return (
			CustomerUtils.compareCustomerPaymentInfo(prev.customer, curr.customer!) &&
			prev.paymentMethod === curr.paymentMethod
		);
	}

	static isTexasLifelineEnrollment(customer: Customer) {
		return (
			customer?.addressInfo.state === UsaStates.Texas &&
			customer.lifelineEnrollmentType !== LifelineEnrollmentType.Acp
		);
	}
}

type CustomerPaymentInfo = {
	customer: Customer | null;
	paymentMethod: PaymentMethod;
};
