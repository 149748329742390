import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { DialogActions } from './dialog.actions';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@shared/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogInfo } from '@shared/components/dialogs/confirmation-dialog/confirmationDialogInfo.type';
import { DialogButton } from '@shared/constants/dialog-button.enum';
import { DialogHeader, dialogMinViewWidth } from '@shared/components/dialogs/dialog-settings.types';

import ShowErrorDialog = DialogActions.ShowErrorDialog;
import ShowWarningDialog = DialogActions.ShowWarningDialog;

export interface DialogModel {
	messages: string[];
}

@State<DialogModel>({
	name: 'dialog',
	defaults: {
		messages: []
	}
})
@Injectable()
export class DialogState {
	constructor(public dialog: MatDialog) {}

	@Action(ShowErrorDialog)
	showErrorDialog(ctx: StateContext<DialogModel>, { text }: ShowErrorDialog) {
		const state = ctx.getState();

		ctx.patchState({
			messages: [...state.messages, text]
		});

		return this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogInfo>(
			ConfirmationDialogComponent,
			{
				width: dialogMinViewWidth,
				data: {
					confirmationText: text,
					title: DialogHeader.Error,
					dialogButton: DialogButton.Ok
				}
			}
		);
	}

	@Action(ShowWarningDialog)
	showWarningDialog(ctx: StateContext<DialogModel>, { text }: ShowErrorDialog) {
		const state = ctx.getState();

		ctx.patchState({
			messages: [...state.messages, text]
		});

		return this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogInfo>(
			ConfirmationDialogComponent,
			{
				data: {
					confirmationText: text,
					title: DialogHeader.Warning,
					dialogButton: DialogButton.Ok
				}
			}
		);
	}

	@Action(DialogActions.ShowInfo)
	ShowInfo(ctx: StateContext<DialogModel>, { text }: DialogActions.ShowInfo) {
		const state = ctx.getState();

		ctx.patchState({
			messages: [...state.messages, text]
		});

		return this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogInfo>(
			ConfirmationDialogComponent,
			{
				data: {
					confirmationText: text,
					title: DialogHeader.Info,
					dialogButton: DialogButton.Ok
				}
			}
		);
	}
}
