import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/app/core/interfaces/api-response.type';
import { DocumentType } from 'src/app/core/models/document-type.type';
import { ApiMethods } from 'src/app/core/utils/api/api-methods';

@Injectable({
	providedIn: 'root'
})
export class DocumentTypeService {
	constructor(private http: HttpClient) {}

	public getDocumentTypes(): Observable<ApiResponse<DocumentType[]>> {
		return this.http.get<ApiResponse<DocumentType[]>>(
			ApiMethods.getDocumentTypes()
		);
	}
}
