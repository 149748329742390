<h5
	mat-dialog-title
	class="mat-subtitle-1"
>
	{{ data.action }} Request
</h5>
<div
	mat-dialog-content
	class="mat-subtitle-2 lh-16 m-24"
>
	Would you like to <strong>{{ data.action | lowercase }}</strong> the self-signup request?
</div>
<div
	mat-dialog-actions
	class="p-24 p-t-0"
>
	<button
		mat-flat-button
		(click)="doAction()"
		color="primary"
		cdkFocusInitial
		[disabled]="!!(actionIsExecuting$ | async)"
	>
		Yes
		<mat-icon *ngIf="actionIsExecuting$ | async">
			<mat-spinner
				color="accent"
				diameter="20"
			>
			</mat-spinner>
		</mat-icon>
	</button>
	<button
		mat-stroked-button
		color="warn"
		mat-dialog-close
	>
		No
	</button>
</div>
