import { Injectable } from '@angular/core';
import { protectedResources } from '../msal/msal.config';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
	InvitationViewModel,
	SelfSignupInvite,
	SelfSignupInviteViewModel
} from '../models/invitations';
import { InvitationsApiMethods } from '../utils/api/invitations-api-methods';
import { EmployeeInvitationEmailLetter } from '@modules/employees/pages/employees/employee-credentials-dialog/employee-credentials.type';
import { ApiResponse } from '@core/interfaces/api-response.type';

@Injectable({
	providedIn: 'root'
})
export class InvitationsService {
	url = protectedResources.api.endpoint;

	constructor(private http: HttpClient) {}

	public selfSignup(
		inviteViewModel: SelfSignupInviteViewModel
	): Observable<ApiResponse<InvitationViewModel>> {
		return this.http.post<ApiResponse<InvitationViewModel>>(
			InvitationsApiMethods.selfSignup(),
			inviteViewModel
		);
	}

	public getInvitations(): Observable<ApiResponse<InvitationViewModel[]>> {
		return this.http.get<ApiResponse<InvitationViewModel[]>>(InvitationsApiMethods.list());
	}

	public changeAvailability(payload: {
		id: string;
		enabled: boolean;
	}): Observable<ApiResponse<InvitationViewModel>> {
		return this.http.post<ApiResponse<InvitationViewModel>>(
			InvitationsApiMethods.availability(),
			payload
		);
	}

	public sendInvitationEmail(
		inviteViewModel: EmployeeInvitationEmailLetter
	): Observable<ApiResponse<InvitationViewModel>> {
		return this.http.post<ApiResponse<InvitationViewModel>>(
			InvitationsApiMethods.sendEmail(),
			inviteViewModel
		);
	}
}
