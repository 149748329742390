/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

import {
	LogLevel,
	Configuration,
	BrowserCacheLocation
} from '@azure/msal-browser';
import { environment } from '../../../environments/environment';

const isIE =
	window.navigator.userAgent.indexOf('MSIE ') > -1 ||
	window.navigator.userAgent.indexOf('Trident/') > -1;

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
	auth: {
		clientId: '33576ea9-c23b-44a0-b467-b1b03d6184d9', // This is the ONLY mandatory field that you need to supply.
		authority:
			'https://login.microsoftonline.com/2fd4b2f6-340f-40ec-aab7-a59da57f6fb9', // Defaults to "https://login.microsoftonline.com/common"
		redirectUri: '/', // Points to window.location.origin by default. You must register this URI on Azure portal/App Registration.
		postLogoutRedirectUri: '/', // Points to window.location.origin by default.
		clientCapabilities: ['CP1'] // This lets the resource server know that this client can handle claim challenges.
	},
	cache: {
		cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
		storeAuthStateInCookie: isIE // Set this to "true" if you are having issues on IE11 or Edge. Remove this line to use Angular Universal
	},
	system: {
		/**
		 * Below you can configure MSAL.js logs. For more information, visit:
		 * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
		 */
		loggerOptions: {
			loggerCallback(logLevel: LogLevel, message: string) {},
			logLevel: LogLevel.Verbose,
			piiLoggingEnabled: false
		}
	}
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
	api: {
		endpoint: `${environment.endpoint}/api/v1`,
		scopes: ['']
	}
};

/**
 * Scopes you employee-credentials-dialog here will be prompted for user consent during sign-in.
 * By default, MSAL.js will employee-credentials-dialog OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
	scopes: [...protectedResources.api.scopes]
};
