import { Injectable, inject } from '@angular/core';
import { AddressValidationResult } from '@core/interfaces/address-validation-result.type';
import { GeoService } from '@core/services/geo.service';
import { PaymentMethod } from '@modules/customer/components/payment-form/payment-form.types';
import { CustomerService } from '@modules/customer/services/customer.service';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { PaymentMethodsActions } from './payment-methods.actions';
import { CustomerState } from '../customer.state';
import { EMPTY, catchError, tap } from 'rxjs';

export interface PaymentMethodsStateModel {
	paymentMethods: PaymentMethod[];
}

@State<PaymentMethodsStateModel>({
	name: 'paymentMethods',
	defaults: {
		paymentMethods: []
	}
})
@Injectable()
export class PaymentMethodsState {
	private readonly customerService = inject(CustomerService);
	private readonly store = inject(Store);

	@Selector()
	static paymentMethods(state: PaymentMethodsStateModel): PaymentMethod[] {
		return state.paymentMethods;
	}

	@Action(PaymentMethodsActions.Get)
	get({ patchState }: StateContext<PaymentMethodsStateModel>, {}: PaymentMethodsActions.Get) {
		const customerId = this.store.selectSnapshot(CustomerState.currentCustomer)?.id;

		return this.customerService.getPaymentMethods(customerId!).pipe(
			tap(({ data, succeeded }) => {
				if (succeeded) {
					patchState({
						paymentMethods: data
					});
					return;
				}

				patchState({
					paymentMethods: []
				});
			}),
			catchError(() => {
				patchState({
					paymentMethods: []
				});

				return EMPTY;
			})
		);
	}
}
