import { ApiMethods } from './api-methods';

export class SignupRequestsApiMethods {
	private static controller: string = 'signupRequests';

	private static wrapped(action: string): string {
		return ApiMethods.prefixed(this.controller, action);
	}

	public static list(): string {
		return this.wrapped('list');
	}
	public static approve = () => this.wrapped('approve');
	public static decline = () => this.wrapped('decline');
}
