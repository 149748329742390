import { MakePaymentRequest } from '@core/interfaces/middleware/make-payment-request.type';
import { LifelineEnrollmentType } from '@modules/customer/constants/lifeline-enrollment-type.enum';
import { PaymentMethod } from '@modules/customer/components/payment-form/payment-form.types';
import { TransferExceptionCode } from '@modules/customer/constants/transfer-exception-code';
import { CreateLifelineStatus } from '@core/interfaces/create-lifeline-status';
import { UploadProofCommand } from '@core/api-client/data-contracts';

export namespace MiddlewareActions {
	export class CheckServiceAvailability {
		static readonly type = '[Middleware] Check Service Availability';
		constructor(public zip: string) {}
	}

	export class CheckEligibility {
		static readonly type = '[Middleware] Check Eligibility';
		constructor(public customerId: string) {}
	}

	export class EligibilityStatusCheck {
		static readonly type = '[Middleware] Eligibility Status Check';
		constructor(public customerId: string) {}
	}

	export class NladVerify {
		static readonly type = '[Middleware] Nlad Verify';
		constructor(public customerId: string) {}
	}

	export class TransferAcp {
		static readonly type = '[Middleware] Transfer ACP';
		constructor(
			public customerId: string,
			readonly transferExceptionCode: TransferExceptionCode | null,
			readonly enrollmentType: LifelineEnrollmentType
		) {}
	}

	export class GetPaymentDetails {
		static readonly type = '[Middleware] Get Payment Details';
		constructor(
			public paymentMethod: PaymentMethod,
			public customerId: string
		) {}
	}

	export class MakePayment {
		static readonly type = '[Middleware] Make Payment';
		constructor(public makePaymentRequest: MakePaymentRequest) {}
	}

	export class CreateLifelineCustomer {
		static readonly type = '[Middleware] Create Lifeline Customer';
		constructor(public customerId: string) {}
	}

	export class GetOrderStatusDetails {
		static readonly type = '[Middleware] Get Order Status Details';
		constructor(public customerId: string) {}
	}

	export class UploadProof {
		static readonly type = '[Middleware] Upload Proof';
		constructor(public uploadProofRequest: UploadProofCommand) {}
	}

	export class UpdateCreateLifelineStatus {
		static readonly type = '[Middleware] Update Create Lifeline Status';
		constructor(public status: CreateLifelineStatus | null) {}
	}

	export class SetProofsUploaded {
		static readonly type = '[Middleware] Set Proofs Uploaded';
		constructor(public uploaded: boolean | null) {}
	}
}
