import { Injectable } from '@angular/core';
import {
	MissingTranslationHandler,
	TranslateStore,
	TranslateCompiler,
	TranslateLoader,
	TranslateParser,
	TranslateService as NgxTranslateService
} from '@ngx-translate/core';

@Injectable()
export class TranslateService extends NgxTranslateService {
	constructor(
		store: TranslateStore,
		currentLoader: TranslateLoader,
		compiler: TranslateCompiler,
		parser: TranslateParser,
		missingTranslationHandler: MissingTranslationHandler
	) {
		super(
			store,
			currentLoader,
			compiler,
			parser,
			missingTranslationHandler,
			true,
			false,
			true,
			'en'
		);
	}
}
