export enum CreateLifelineStatus {
	Rejected = 0,
	Accepted = 1,
	Pending = 2
}

export type CreateLifelineStatusMap = {
	[key in CreateLifelineStatus]: string;
};
export const createLifelineStatusKeys: CreateLifelineStatusMap = {
	[CreateLifelineStatus.Rejected]: 'OrderStatusGeneral.Rejected',
	[CreateLifelineStatus.Accepted]: 'OrderStatusGeneral.Approved',
	[CreateLifelineStatus.Pending]: 'OrderStatusGeneral.Pending'
};
