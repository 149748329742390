export const environment = {
	production: true,
	clientId: '3420e726-b20b-47c8-a3c1-9811727b5245', // USE SPA AZURE AD CLIENTID
	authority:
		'https://maxsipteluat.b2clogin.com/maxsipteluat.onmicrosoft.com/B2C_1A_AAD_LINK_SIGNUPORSIGNIN_MAXSIP',
	authorityDomain: 'maxsipteluat.b2clogin.com',
	redirectUri: 'https://uat.maxsipapps.com',
	endpoint: 'https://uat.maxsipapps.com/api',
	endpointHealthCheck: 'https://uat.maxsipapps.com/api/health-ui',
	endpointScopes: ['https://maxsipteluat.onmicrosoft.com/api/All'],
	inviteBaseUrl: 'https://maxsipteluat.b2clogin.com/maxsipteluat.onmicrosoft.com',
	inviteClientId: '96f3b9e0-850d-4508-a221-7e0126bcb6cb', // AZURE AD B2C OIDC CLIENT ID
	geoCoding: {
		mapboxToken: 'pk.eyJ1Ijoic2FwbWF4c2lwdGVsIiwiYSI6ImNsdjQ5N2I4ZDA1aXIybG13OWx4bXgzeWkifQ.CO3rn-OjM7EZa-3QM6wWlA',
		apiUrl: 'https://api.mapbox.com/search/geocode/v6/forward'
	},
	version: 'maxsip-staging@1.0.64',
	stage: 'staging',
	logRocketAppId: 'maxsip/sap-uat',
	sessionRecordingMode: '',
	sentryDsn:
		'https://c714be368e3ec7b08390388c828afc12@o4506319083208704.ingest.sentry.io/4506319946842112',
	scandit: {
		//Trial Key Expires: 2023-12-27 18:27
		licenseKey: 'AcwUBx9zOx+fAw/pQhAg/7w/zNFaMBqI10vQkiNdJ9K9V00Xsh1Zp3B1uR/CcZaUPCfbBD1pFgzIOTA2z054Sk4uzf/sfWV6XS8e0vhhmboJKlJZCFH522R4en41VkLLKhZTFs4svtghBoG0h0iFTptwS1ahRGkxQjJYyI1tWpl+Qd0VqwIGp5NiJ2+eSb75HUzVBfhNFFcmXqGU2EnmIYB5nqgcT8NsSkgnM2tQxSYkLUoYUn2QSjZzwNSULS6S1EsFRJVnFQKxULanUH89Y3RyqKEjY0cL43eChrxk1PyVBTsO4W3dzpx59cJWSF6y1W14Q1pzNfQcZ1h/5BfncEVIGLcNc8au+lYTADxIfDyeU2+CtWZxR5prmgVWT10gMkLoWZ9hQJ0wZjlCtDX5kVM4JWo3W57ZFC+do9seiPkNUCJCN1lo7tVABgubd56dpTxY3oBAcsNXSH1hg1FSi/Ntli0enH3FXUu2gk7VozeJaxOK/HAwNYCl9NZyEGsVfVa48TjCCt3J/LjIKpjtwS7smCp1r9F87yzlZpPIOUdcKHNr+WMbRGKncToVUoJMnEjfNuj4H6BuLGeV9ZmjEON5rQpYyDxBD7o+7kDrPSEvdx1T//V/uaElPxBisJVFzlD8iO912p+lNn0/2iOj17o9Qvz5c3uzzNbjcnYV4twtFz0qSB4mzmuz3TrzvRA7Hw2e69zcCYB3yVNV+mH3Ad/qf7os+8ULQe4fuoM47BAaoFUfUfz6iCb8lsWWwj28wdgdtUo40xi50gn3hoy0nyOb8Umo/U/e2r8hJ2r+AFBuJHKuIMvcHccvVXave3zorl7EsMhSmWcwU4lrOmiRGBkHy0We8ssNcwqt7B73C3PU5SbeXktVJuPjf7Prbfq4bBjZ0qI7qb4i1IBOKEz1VlVQTNKouQUVcWduYxE7aCK4v7SC4HPGTk+2sTjuVQFULyNj8lOeE2Z3VY4Z0PvGlU+eiHFs8OLVizCXZcKY5RGYuh0amA4eOIoguwh3JpDUbiZ8kVzbdSsU7bnkCV1y3nqQAOf1NaDgoqsXCCiIU/a4MRBosrnPHbwf51gYE95xblaglZ0SPOLQGuAxX+veb2bUyk2RZfcANv6+EMPmIZ26SBDTyTmzrvZ4KWTH3n9Kz3ww',
		libraryLocation:
			'https://cdn.jsdelivr.net/npm/scandit-web-datacapture-barcode@6.x/build/engine/',
		idLibraryLocation:
			'https://cdn.jsdelivr.net/npm/scandit-web-datacapture-id@6.x/build/engine/'
	},
	datadog: {
		applicationId: 'e52a282d-33f2-4bdd-9f3e-5a3727f568bd',
		clientToken: 'pub262a9d7c554e985a8ad38b358b17a21a',
		site: 'datadoghq.com',
		service: 'maxsip-sap',
		env: 'staging',
		version: '1.0.64',
		sessionSampleRate: 100,
		sessionReplaySampleRate: 10,
		trackUserInteractions: true,
		trackResources: true,
		trackLongTasks: true,
		defaultPrivacyLevel: 'mask-user-input'
	},
	acpContactPhone: '+18773842575',
	genericWebEnrollmentPath: 'generic',
	liveChatKey: '',
	featureFlags: {
		retail: toBoolean('true')
	}
};

function toBoolean(value: string) {
	return value === 'true';
}
