import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppSettings, defaults } from '../../app.config';

@Injectable({
	providedIn: 'root'
})
export class CoreService {
	get notify(): Observable<Record<string, any>> {
		return this.notify$.asObservable();
	}

	private notify$ = new BehaviorSubject<Record<string, any>>({});

	getOptions(): AppSettings {
		return this.options;
	}

	setOptions(options: AppSettings): void {
		if (
			!options.sidenavCollapsed &&
			!options.sidenavOpened &&
			!this.options.sidenavCollapsed &&
			!this.options.sidenavOpened
		) {
			options.sidenavCollapsed = true;
			options.sidenavOpened = true;
		}

		this.options = Object.assign(defaults, options);
		this.notify$.next(this.options);
	}

	private options = defaults;
}
