export enum AbsoluteRoutes {
	Default = '/',
	NotFound = '/not-found',
	Dashboard = '/dashboard',
	Error = '/login/error',
	Login = '/login',
	NewCustomer = '/customers/new',
	MasterZip = '/zips',
	EditCustomer = '/customers/edit/:id',
	OrdersList = '/customers/list',
	Employees = '/agents',
	Sandbox = '/sandbox',
	Invitations = '/invitations',
	WebEnrollment = '/web/links',
	WebEnrollmentLink = '/enroll/:id',
	WebEnrollmentList = '/enroll/list',
	SignupRequests = '/signups',
	Devices = 'devices',
	Retail = 'retail',
	NewRetailEnrollment = 'retail/new',
	NewOptimumEnrollment = 'optimum/new',
	OptimumEnrollmentList = 'optimum/list',
	HealthCheck = '/healthcheck',
	AuditLogs = '/audit-logs'
}

export enum RelativeRoutes {
	Default = '',
	NotFound = 'not-found',
	Dashboard = 'dashboard',
	Error = 'error',
	Login = 'login',
	Customers = 'customers',
	NewCustomer = 'new',
	EditCustomer = 'edit/:id',
	OrdersList = 'list',
	MsalRedirect = 'auth',
	Employees = 'agents',
	Masterzip = 'zips',
	Sandbox = 'sandbox',
	Invitations = 'invitations',
	SignupRequests = 'signups',
	OptimumAffiliateEnrollment = 'optimum-enroll',
	AffiliateLinks = 'web',
	WebEnrollmentLink = 'enroll',
	LifelineEnrollment = 'lifeline',
	Devices = 'devices',
	Retail = 'retail',
	Optimum = 'optimum',
	HealthCheck = 'healthcheck',
	AuditLogs = 'audit-logs'
}
