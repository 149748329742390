import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MaterialModule } from 'src/app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogButton } from '@shared/constants/dialog-button.enum';
import { CommonModule } from '@angular/common';
import { ConfirmationDialogInfo } from './confirmationDialogInfo.type';

@Component({
	selector: 'app-confirmation-dialog',
	templateUrl: './confirmation-dialog.component.html',
	standalone: true,
	imports: [MaterialModule, TranslateModule, CommonModule],
	encapsulation: ViewEncapsulation.None
})
export class ConfirmationDialogComponent {
	DialogButton = DialogButton;

	constructor(
		public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogInfo
	) {}

	public closeDialog(): void {
		this.dialogRef.close();
	}
}
