export enum CustomerOnboardingStatus {
	Created = 1,
	CheckedEligibility = 3,
	CompletedNv = 5,
	NladVerified = 7,
	TransferAcp = 8,
	CreatedLifeline = 9,
	Paid = 15,
	Handover = 20
}
