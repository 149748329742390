import { Directive, ElementRef, HostListener, OnDestroy, Renderer2 } from '@angular/core';
import * as vanillaTextMask from 'vanilla-text-mask/dist/vanillaTextMask.js';

@Directive({
	selector: '[appMaskDate]',
	standalone: true
})
export class MaskDateDirective implements OnDestroy {
	mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]; // mm/dd/yyyy
	maskedInputController;

	constructor(
		private renderer: Renderer2,
		private element: ElementRef
	) {
		this.maskedInputController = vanillaTextMask.maskInput({
			inputElement: this.element.nativeElement,
			mask: this.mask
		});
	}

	ngOnDestroy() {
		this.maskedInputController.destroy();
	}

	@HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
		// can be any string copied by user
		const dateStr = event?.clipboardData?.getData('text/plain');

		if (dateStr) {
			const date = new Date(dateStr);
			// determine if date is valid
			if (!isNaN(date.getTime())) {
				const localeDate = date.toLocaleDateString('en-US', {
					year: 'numeric',
					month: '2-digit',
					day: '2-digit'
				});
				this.renderer.setProperty(this.element.nativeElement, 'value', localeDate);
			}
		}
	}
}
