import { Component, DestroyRef, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { EmployeesActions } from '@store/employees/employees.actions';

import { Select, Store } from '@ngxs/store';
import { AccountState } from '@store/account/account.state';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PermissionUtils } from '@core/utils/permission-utils';
import { isArrayNullOrEmpty } from '@core/utils/utils';
import { environment } from '@environment/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
	title = 'Maxsip';
	isIframe = false;
	readonly liveChatKey = environment.liveChatKey;

	@Select(AccountState.permissions)
	permissions$: Observable<string[]>;

	constructor(
		private store: Store,
		private authService: MsalService,
		private route: ActivatedRoute,
		private msalBroadcastService: MsalBroadcastService,
		private readonly destroyRef: DestroyRef
	) {}

	public ngOnInit(): void {
		this.isIframe = window !== window.parent && !window.opener;

		this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

		/**
		 * You can subscribe to MSAL events as shown below. For more info,
		 * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
		 */
		this.msalBroadcastService.msalSubject$
			.pipe(
				filter(
					(msg: EventMessage) =>
						msg.eventType === EventType.ACCOUNT_ADDED ||
						msg.eventType === EventType.ACCOUNT_REMOVED
				)
			)
			.subscribe(_ => {
				if (this.authService.instance.getAllAccounts().length === 0) {
					window.location.pathname = '/';
				}
			});

		this.msalBroadcastService.inProgress$
			.pipe(
				filter((status: InteractionStatus) => status === InteractionStatus.None),
				takeUntilDestroyed(this.destroyRef)
			)
			.subscribe(() => {
				this.checkAndSetActiveAccount();
			});

		this.permissions$
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				filter(permissions => !!permissions)
			)
			.subscribe(permissions => {
				this.managePermissions(permissions);
			});
	}

	private managePermissions(permissions: string[]): void {
		if (isArrayNullOrEmpty(permissions)) {
			return;
		}

		this.store.dispatch([new EmployeesActions.GetMvnoMetadata()]);
		if (PermissionUtils.canAccessClecCodes(permissions)) {
			this.store.dispatch(new EmployeesActions.GetCarrierCodes());
		}
	}

	public checkAndSetActiveAccount() {
		let activeAccount = this.authService.instance.getActiveAccount();
		if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
			let accounts = this.authService.instance.getAllAccounts();
			// employee-credentials-dialog your code for handling multiple accounts here
			this.authService.instance.setActiveAccount(accounts[0]);
		}
	}
}
