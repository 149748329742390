import {
	DropdownOption,
	FilterType,
	GridFilter
} from '@shared/components/grid/grid.component.type';
import { isNullOrUndefined, stringEmpty } from '@core/utils/utils';

export const SearchFilter = {
	isAlwaysVisible: true,
	type: FilterType.Text,
	clearable: true,
	placeholder: 'Search',
	key: 'search',
	label: 'Search'
};

export function getSearchFilter<T>(): GridFilter<T> {
	return SearchFilter as GridFilter<T>;
}

export function getBooleanFilterOptions(withEmpty: boolean = true): DropdownOption[] {
	const base = [
		{ label: 'Yes', id: true },
		{ label: 'No', id: false }
	];
	return withEmpty ? [{ label: stringEmpty, id: null }, ...base] : base;
}

export function getBooleanFormattedValue(value: boolean | null): string {
	if (isNullOrUndefined(value)) {
		return stringEmpty;
	}
	return value ? 'Yes' : 'No';
}
