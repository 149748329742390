import { FilterType, GridColumn, GridFilter } from '@shared/components/grid/grid.component.type';
import { Employee, EmployeesFiltration } from '@core/models/employee';
import { getSearchFilter } from '@shared/components/grid-filters/grid-filters.component.type';
import { isStringNotNullOrEmpty, stringEmpty } from '@core/utils/utils';
import { match } from 'ts-pattern';

export enum Role {
	Default = 'Default',
	SuperAdmin = 'SuperAdmin',
	Admin = 'Admin',
	ClecManager = 'ClecManager',
	InternalSalesAgent = 'InternalSalesAgent',
	ExternalSalesAgent = 'ExternalSalesAgent',
	SalesAgentSupport = 'SalesAgentSupport',
	CallCenterOperator = 'CallCenterOperator'
}

export enum ActivationStatus {
	Pending = 0,
	Approved = 1,
	Blocked = 2
}

export enum SignupRequestStatus {
	Declined,
	Pending,
	Approved
}

export enum Mvno {
	Maxsip = 0,
	Terracom = 1
}

interface MappedStringObject {
	[key: number]: string;
}

export const mapRoleToLabel = [
	{ id: Role.Admin, label: 'Admin' },
	{ id: Role.ClecManager, label: 'CLEC Manager' },
	{ id: Role.ExternalSalesAgent, label: 'External Sales Agent' },
	{ id: Role.InternalSalesAgent, label: 'Internal Sales Agent' },
	{ id: Role.CallCenterOperator, label: 'Call Center Operator' },
	{ id: Role.SalesAgentSupport, label: 'Sales Agent Support' }
];

export const mapAccountStatusToLabel: MappedStringObject = {
	[ActivationStatus.Approved]: 'Approved',
	[ActivationStatus.Blocked]: 'Blocked',
	[ActivationStatus.Pending]: 'Pending'
};

export const mapSignupRequestStatusToLabel: MappedStringObject = {
	[SignupRequestStatus.Declined]: 'Declined',
	[SignupRequestStatus.Pending]: 'Pending Approval',
	[SignupRequestStatus.Approved]: 'Approved'
};

export const EmployeesListColumns: GridColumn<Employee>[] = [
	{
		field: 'userName',
		header: 'Name',
		formatter: ({ displayName, userName }) =>
			isStringNotNullOrEmpty(displayName) ? displayName : userName
	},
	{
		field: 'role',
		header: 'Role',
		formatter: element => mapRoleToLabel.find(r => r.id === element.role)?.label
	},
	{
		field: 'clecIdCodes',
		header: 'CLEC ID'
	},
	{
		field: 'radId',
		header: 'RAD ID'
	},
	{
		field: 'email',
		header: 'Email'
	},

	{
		field: 'phone',
		header: 'Phone'
	},
	{
		field: 'activationStatus',
		header: 'Status',
		className: element =>
			match(element.activationStatus)
				.with(ActivationStatus.Approved, () => 'text-primary')
				.with(ActivationStatus.Blocked, () => 'text-error')
				.with(ActivationStatus.Pending, () => 'text-warning')
				.otherwise(() => stringEmpty),
		formatter: element => mapAccountStatusToLabel[element.activationStatus]
	}
];

export const EmployeeFilters: GridFilter<EmployeesFiltration>[] = [
	getSearchFilter<EmployeesFiltration>(),
	{
		key: 'role',
		type: FilterType.Dropdown,
		label: 'Role',
		emptyOption: ' ',
		options: mapRoleToLabel
	},
	{
		key: 'status',
		type: FilterType.Dropdown,
		label: 'Status',
		emptyOption: ' ',
		options: Object.keys(mapAccountStatusToLabel).map(status => ({
			id: +status,
			label: mapAccountStatusToLabel[+status]
		}))
	},
	{
		key: 'clecId',
		type: FilterType.Dropdown,
		label: 'CLEC ID',
		emptyOption: ' ',
		searchable: true,
		options: []
	}
];
