import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from '@environment/environment';
import { enableProdMode } from '@angular/core';

import * as Sentry from '@sentry/browser';
import { captureConsoleIntegration, dedupeIntegration } from '@sentry/integrations';

if (environment.production) {
	enableProdMode();
	Sentry.init({
		environment: environment.stage,
		release: `${environment.stage}@${environment.version}`,
		dsn: environment.sentryDsn,
		integrations: [
			Sentry.replayIntegration(),
			Sentry.browserTracingIntegration(),
			dedupeIntegration(),
			captureConsoleIntegration({ levels: ['error'] })
		],
		tracePropagationTargets: ['localhost', environment.endpoint],
		tracesSampleRate: 1.0, // Capture 100% of the transactions
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}
platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch(err => console.error(err));
