<div class="px-3 md:px-5 flex flex-col gap-y-8 py-8">
	<div class="flex gap-x-2">
		<i-tabler
			class="list-numbers"
			name="users"
		></i-tabler>
		<h4 class="font-semibold text-xl">Signup Requests</h4>
	</div>

	<mat-card class="cardWithShadow mt-0 overflow-hidden">
		<app-grid
			[isLoading]="isGetListExecuting$ | async"
			[data]="employeeSignupRequests$ | async"
			[columns]="columns"
			[actionsTemplate]="actions"
			[pageIndex]="pageIndex"
			[pageSize]="pageSize"
			[length]="totalEmployeesCount$ | async"
			(pageChanged)="pageChanged($event)"
		>
		</app-grid>
		<ng-template
			#actions
			let-element
		>
			<div
				class="gap-x-2.5"
				*ngIf="checkIfActionsPermitted(element)"
			>
				<button
					matTooltip="Approve"
					(click)="openActionDialog(EmployeeRequestAction.Approve, element.id)"
				>
					<i-tabler
						name="check"
						class="icon-18"
					></i-tabler>
				</button>
				<a
					matTooltip="Decline"
					(click)="openActionDialog(EmployeeRequestAction.Decline, element.id)"
				>
					<i-tabler
						name="x"
						class="icon-18"
					></i-tabler>
				</a>
			</div>
		</ng-template>
	</mat-card>
</div>
